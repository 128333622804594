import { Button } from "@/components/design-system/button";
import { useEventListener } from "@/contexts/EventsContext";
import MarconipyApi from "@/utils/marconipyApi";
import { Tool, WorkflowMessage, WorkflowState } from "@/utils/types";
import WebsocketService from "@/utils/websocket";
import Cookies from "js-cookie";
import { usePostHog } from "posthog-js/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RiLoader2Fill, RiSendPlane2Fill } from "react-icons/ri";
import Lottie from "react-lottie-player";
import { useLocation } from "react-router";
import typingIndicator from "./../lottie/typing.json";
import "./ChatComponent.scss";
import ContentShareButtons from "./ContentShareButtons";
import { Dialog, DialogBody } from "./design-system/dialog";
import { MessageComponent } from "./MessageComponent";
import QuickReplyBubbles from "./QuickReplyBubbles";
import SkeletonElementPreset from "./SkeletonElementPreset";
import MarkdownRenderer from "./styled/MarkdownRenderer";
import Tooltip from "./styled/Tooltip";

const ADVANCED_MODE_COOKIE = "tailortask_advanced_mode";

let globalWebSocket: WebsocketService | null = null;

interface ChatProps {
  workflow_uuid: string;
  workflow_run_uuid?: string;
  workflowIcon?: string;
  initialState?: WorkflowState;
  onWorkflowRefresh?: () => void;
  onNewWorkflowState?: (state: any) => void;
  onImportantMessage?: (message: any) => void;
  onQuickReplyAction?: (action: any) => void;
  shiftToBottom?: boolean; // in case we have a topbar, we shift the view
  showQuickReplyBubbles?: boolean;
}

const useAutoFocusTextarea = (sending: boolean, loading: boolean) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!sending && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [sending, loading]);

  return textareaRef;
};

const ChatComponent: React.FC<ChatProps> = ({
  workflow_uuid,
  workflow_run_uuid,
  workflowIcon,
  initialState,
  onWorkflowRefresh,
  onImportantMessage,
  onQuickReplyAction,
  shiftToBottom,
  showQuickReplyBubbles = true,
}) => {
  const [messages, setMessages] = useState<WorkflowMessage[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [
    isLoadingMessagesForTheFirstTime,
    setIsLoadingMessagesForTheFirstTime,
  ] = useState(true);
  const [inputMessage, setInputMessage] = useState("");
  const [sending, setSending] = useState(false);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const messageListRef = useRef<null | HTMLDivElement>(null);
  const [placeholder, setPlaceholder] = useState("Type a message...");
  const textareaRef = useAutoFocusTextarea(
    sending,
    isLoadingMessagesForTheFirstTime,
  );
  const [advancedMode, setAdvancedMode] = useState(false);
  const posthog = usePostHog();
  const [textareaHeight, setTextareaHeight] = useState<number>(40);
  const [workflowState, setWorkflowState] = useState<WorkflowState | null>(
    initialState ?? null,
  );
  const [tools, setTools] = useState<Tool[]>([]);
  const [previewContent, setPreviewContent] = useState<string | null>(null);
  const [artefactUUID, setArtefactUUID] = useState<string | null>(null);
  const websocketRef = useRef<any | null>(null);
  const location = useLocation();

  useEffect(() => {
    setWorkflowState(initialState ?? null);
  }, [initialState, workflow_uuid]);

  const loadMessages = useCallback(async () => {
    if (isConnected) {
      let messagesObj = null;
      if (workflow_run_uuid) {
        messagesObj = (await MarconipyApi.getWorkflowRunMessages(
          workflow_run_uuid,
        )) as any as {
          messages: WorkflowMessage[];
        };
      } else {
        messagesObj = (await MarconipyApi.getMessages(
          workflow_uuid,
        )) as any as {
          messages: WorkflowMessage[];
        };
      }
      if (messagesObj) {
        setMessages(messagesObj.messages);
      }
      setIsLoadingMessagesForTheFirstTime(false);
    }
  }, [isConnected, workflow_run_uuid, workflow_uuid]);

  useEffect(() => {
    const loadAllTools = async () => {
      const ftools = (await MarconipyApi.getAllTools(workflow_uuid)) as any as {
        tools: Tool[];
      };
      setTools(ftools.tools);
    };
    loadAllTools();
  }, [workflow_uuid]);

  useEffect(() => {
    const savedMode = Cookies.get(ADVANCED_MODE_COOKIE);
    if (savedMode !== undefined) {
      const isAdvanced = savedMode == "advanced";
      setAdvancedMode(isAdvanced);
    }
  }, [workflow_uuid]);

  useEffect(() => {
    const handleConnection = () => {
      setIsConnected(true);
    };

    const handleDisconnection = () => {
      setIsConnected(false);
    };

    const handleMessage = (data: any) => {
      if (data.type === "chat_message") {
        const newmessage = data.message as any as WorkflowMessage;
        setMessages((prevMessages) => [...prevMessages, newmessage]);
      } else if (data.type === "workflow_update" && onWorkflowRefresh) {
        onWorkflowRefresh();
      } else if (data.type === "workflow_state_update") {
        setWorkflowState(data.message);
      } else if (data.type === "workflow_name_update" && onImportantMessage) {
        onImportantMessage(data);
      } else if (data.type === "open_setting_step" && onImportantMessage) {
        onImportantMessage(data);
      }
    };

    if (!globalWebSocket) {
      globalWebSocket = new WebsocketService();
      globalWebSocket.connect(workflow_uuid);
    }

    websocketRef.current = globalWebSocket;

    websocketRef.current.on("connect", handleConnection);
    websocketRef.current.on("disconnect", handleDisconnection);
    websocketRef.current.on("message", handleMessage);

    return () => {
      if (websocketRef.current) {
        websocketRef.current.off("connect", handleConnection);
        websocketRef.current.off("disconnect", handleDisconnection);
        websocketRef.current.off("message", handleMessage);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow_uuid]);

  useEffect(() => {
    return () => {
      if (globalWebSocket) {
        globalWebSocket.disconnect();
        globalWebSocket = null;
      }
    };
  }, [location]);

  const scrollToEndOfChat = async () => {
    await new Promise((r) => setTimeout(r, 200));
    if (messagesEndRef.current) {
      const scrollOptions: ScrollIntoViewOptions = {
        behavior: "smooth",
        block: "end",
      };
      messagesEndRef.current?.scrollIntoView(scrollOptions);
    }
  };

  const onLocalQuickReplyAction = (action: any) => {
    //check if the action is something that needs to be handled in the chat component or escalated to the parent
    if (action == "give_feedback") {
      setInputMessage("I have some feedback: ");
      textareaRef.current?.focus();
    } else {
      onQuickReplyAction && onQuickReplyAction(action);
    }
  };

  useEffect(() => {
    scrollToEndOfChat();
  }, [messages]);

  useEffect(() => {
    const updateSending = () => {
      if (!workflowState) {
        return;
      }
      switch (workflowState.name) {
        case "unstarted":
          setSending(false);
          setPlaceholder(
            "I'm a content marketer, and I need to come up with ideas...",
          );
          break;
        case "pending":
          setSending(true);
          setPlaceholder("Tailor is thinking...");
          break;
        case "running":
          setSending(true);
          setPlaceholder("Tailor is working...");
          break;
        case "waiting_for_input":
          setSending(false);
          setPlaceholder("Tailor is waiting for your input...");
          break;
        case "waiting_for_ui_input":
          setSending(true);
          setPlaceholder("👉 Complete the setup on the right panel");
          break;
        case "tool_in_progress":
          setSending(true);
          setPlaceholder("Tailor is executing a tool...");
          break;
        case "generating_response":
          setSending(true);
          setPlaceholder("Tailor is thinking...");
          break;
        case "sending_to_outputs":
          setSending(true);
          setPlaceholder("Tailor is sending the results...");
          break;
        case "completed":
          setSending(false);
          setPlaceholder("Give feedback on the results");
          break;
        case "run_waiting_for_input":
          setSending(false);
          setPlaceholder("Tailor is waiting for your input...");
          break;
        case "needs_customer_support":
          setSending(false);
          setPlaceholder(
            "This agent has some issues, contact our customer support at info@tailortask.ai",
          );
          break;
        default:
          setSending(false);
          break;
      }
    };

    updateSending();
  }, [workflowState]);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputMessage(e.target.value);
    // Adjust textarea height
    if (textareaRef.current) {
      let newH = textareaRef.current.scrollHeight;
      if (newH > 200) {
        newH = 200;
      }
      if (e.target.value == "") {
        setTextareaHeight(40);
      } else {
        setTextareaHeight(newH);
      }
    } else {
      setTextareaHeight(40);
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaHeight}px`;
    }
    if (messageListRef.current) {
      messageListRef.current.style.height = "auto";
      const shift = shiftToBottom ? 105 : 35;
      messageListRef.current.style.height = `calc(100vh - ${textareaHeight + shift}px)`;
    }
  }, [textareaHeight, setTextareaHeight, textareaRef, shiftToBottom]);

  useEffect(() => {
    if (isConnected) {
      loadMessages();
    }
  }, [isConnected, loadMessages, messages.length]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  const handleSendMessage = async (event: React.FormEvent) => {
    event.preventDefault();
    posthog.capture("chat_message_sent");
    completeSendMessage(inputMessage);
  };
  const handleQuickReply = (message: string) => {
    setInputMessage(message);
    posthog.capture("quick_reply_sent");
    completeSendMessage(message);
  };

  const completeSendMessage = async (messagecontent: string) => {
    setSending(true);
    if (messagecontent.trim() && isConnected && websocketRef.current) {
      websocketRef.current.send(messagecontent);
      setInputMessage("");
      setTimeout(() => {
        setTextareaHeight(40);
        scrollToEndOfChat();
        // setSending(false);
      }, 200);
    } else {
      console.log("Message is empty or not connected");
    }
  };

  useEventListener("VIEW_MODE_CHANGED", (payload) => {
    setAdvancedMode(payload.advanced);
  });

  const closePreviewDialog = () => {
    setPreviewContent(null);
    setArtefactUUID(null);
  };

  const isToolUseMessage = (message: WorkflowMessage) => {
    if (message.role !== "assistant") return false;
    if (!Array.isArray(message.content)) return false;
    return message.content.some((item) => item.type === "tool_use");
  };
  const isToolResultMessage = (message: WorkflowMessage) => {
    if (!Array.isArray(message.content)) return false;
    return message.content.some((item) => item.type === "tool_result");
  };
  const isBriefMessage = (message: WorkflowMessage) => {
    if (!Array.isArray(message.content)) return false;
    return message.content.some((item) => item.text?.startsWith("BRIEF:"));
  };

  const renderMessages = () => {
    // let filteredMessages = messages;

    let filteroutmessagesWithIndex = [];

    if (!advancedMode) {
      //traverse the messages and hide the tool messages that are followed by another tool message
      for (let i = 0; i < messages.length; i++) {
        let affectedIndex = i - 1;
        let previous_message = messages[affectedIndex];
        if (isToolUseMessage(messages[i])) {
          while (
            previous_message &&
            isToolResultMessage(previous_message) &&
            messages[affectedIndex - 1]
          ) {
            affectedIndex = affectedIndex - 1;
            previous_message = messages[affectedIndex];
          }
          if (previous_message && isToolUseMessage(previous_message)) {
            if (messages[i].workflow_run) {
              if (previous_message.workflow_run == messages[i].workflow_run) {
                filteroutmessagesWithIndex.push(affectedIndex);
              }
            } else {
              filteroutmessagesWithIndex.push(affectedIndex);
            }
          }
        } else if (isBriefMessage(messages[i]) && i < messages.length - 1) {
          filteroutmessagesWithIndex.push(i);
        }
      }
    }
    return messages.map((message, index) => {
      const previousMessage = index > 0 ? messages[index - 1] : null;
      const isLastMessage = index === messages.length - 1;
      const showDivider =
        (message.workflow_run && previousMessage && previousMessage.workflow) ||
        (message.workflow_run &&
          previousMessage &&
          previousMessage.workflow_run &&
          previousMessage.workflow_run !== message.workflow_run);
      const showDividerEnd =
        (message.workflow && previousMessage && previousMessage.workflow_run) ||
        (message.workflow &&
          previousMessage &&
          previousMessage.workflow &&
          previousMessage.workflow !== message.workflow);

      if (message.hide_from_ui) {
        return null;
      }

      return (
        <React.Fragment key={message.uuid}>
          {(showDivider || showDividerEnd) && (
            <div className="workflow-run-divider flex items-center my-4">
              <div className="flex-grow border-t border-gray"></div>
              <div className="flex-shrink mx-4 text-sm text-black dark:text-white">
                {showDivider && (
                  <Tooltip content="We are running your workflow">
                    <span className="hover:opacity-100 hover:cursor-default opacity-70">
                      Running your workflow
                    </span>
                  </Tooltip>
                )}
                {showDividerEnd && (
                  <Tooltip content="The run of your workflow ended">
                    <span className="hover:opacity-100 hover:cursor-default opacity-70">
                      Workflow run ended
                    </span>
                  </Tooltip>
                )}
              </div>
              <div className="flex-grow border-t border-gray"></div>
            </div>
          )}
          {!filteroutmessagesWithIndex.includes(index) && (
            <MessageComponent
              message={message}
              index={index}
              workflowUUID={workflow_uuid}
              workflowIcon={workflowIcon}
              isLastMessage={isLastMessage}
              isWorkflowRun={message.workflow_run ? true : false}
              refreshAllMessages={loadMessages}
              advancedMode={advancedMode}
              onQuickReplyAction={onLocalQuickReplyAction}
              onOpenDocument={(
                content: string,
                artefactUUID: string | null,
              ) => {
                setPreviewContent(content);
                setArtefactUUID(artefactUUID ?? null);
              }}
              tools={tools}
            />
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      {/* <div ref={chatContainerRef} className="relative"> */}
      <div
        className="dark:bg-black-dark overflow-y-auto px-[12px]"
        ref={messageListRef}
      >
        <div className="min-h-[10px]" />
        {renderMessages()}
        {messages.length == 0 && isLoadingMessagesForTheFirstTime && (
          <SkeletonElementPreset variant="chat-messages" />
        )}
        {sending && workflowState?.name != "waiting_for_ui_input" && (
          <div className="flex ml-10 message group justify-start dark:invert">
            <Lottie
              loop
              animationData={typingIndicator}
              play
              style={{ width: 50, height: 50 }}
            />
          </div>
        )}
        <div ref={messagesEndRef} className="min-h-[30px]" />
      </div>
      <div className="p-4 dark:bg-black">
        {messages.length < 2 &&
          !sending &&
          !isLoadingMessagesForTheFirstTime &&
          showQuickReplyBubbles && (
            <div className="quick-replies-container flex flex-wrap gap-1">
              <QuickReplyBubbles onSendMessage={handleQuickReply} />
            </div>
          )}
        <form
          onSubmit={(e) => {
            handleSendMessage(e);
          }}
          className="flex gap-2"
          aria-disabled={sending}
        >
          <textarea
            ref={textareaRef}
            value={inputMessage}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className="bg-white flex-1 border border-gray rounded-l-lg p-2 resize-none overflow-hidden dark:bg-black-dark"
            placeholder={placeholder}
            rows={1}
            style={{ minHeight: "40px", maxHeight: "200px" }}
            disabled={sending || isLoadingMessagesForTheFirstTime}
          />
          <Button type="submit" disabled={sending || inputMessage.length === 0}>
            {sending ? (
              <RiLoader2Fill className="animate-spin" />
            ) : (
              <RiSendPlane2Fill />
            )}
          </Button>
        </form>
      </div>
      {/* </div> */}
      <Dialog open={!!previewContent} onClose={closePreviewDialog} size="5xl">
        {artefactUUID && previewContent && (
          <ContentShareButtons
            artefactUUID={artefactUUID}
            content={previewContent}
          />
        )}
        <DialogBody>
          <div className="text-wrap dark:text-white">
            {previewContent && <MarkdownRenderer text={previewContent} />}
          </div>
        </DialogBody>
      </Dialog>
    </React.Fragment>
  );
};

export default ChatComponent;

interface ActionVerb {
  present: string;
  past: string;
}

export class NameConventions {
  private static knownConventions: { [key: string]: string } = {
    sourcesets: "Sources",
    settings: "Set up",
    tools: "Actions",
    test: "Dry run",
    // Add more known conventions here
  };

  private static actionVerbs: { [key: string]: ActionVerb } = {
    reading_brief: {
      present: "Reading brief...",
      past: "Brief read",
    },
    monitor_content_from_social: {
      present: "Downloading content from social...",
      past: "Social content downloaded",
    },
    add_scheduled_trigger: {
      present: "Adding scheduled trigger...",
      past: "Scheduled trigger added",
    },
    ask_user_to_set_up_actions: {
      present: "Asking user to set up actions...",
      past: "User asked to set up actions",
    },
    confirm_test: {
      present: "Confirming dry run...",
      past: "Dry run confirmed",
    },
    edit_brief: { present: "Editing brief...", past: "Brief edited" },
    new_document: {
      present: "Creating new document...",
      past: "New document created",
    },
    pause_task: { present: "Pausing task...", past: "Task paused" },
    read_document: { present: "Reading document...", past: "Document read" },
    read_url: { present: "Opening URL...", past: "URL opened" },
    run_test_workflow: {
      present: "Starting dry run...",
      past: "Dry run started",
    },
    select_actions: {
      present: "Selecting actions...",
      past: "Actions selected",
    },
    stop_task: { present: "Stopping task...", past: "Task stopped" },
    suggest_sources: {
      present: "Suggesting sources...",
      past: "Sources suggested",
    },
    summarize: { present: "Summarizing...", past: "Summarized" },
    task_completed: { present: "Finalizing task...", past: "Task completed" },
    web_search: {
      present: "Searching the web...",
      past: "Web search completed",
    },
    read_spreadsheet: {
      present: "Reading from spreadsheet...",
      past: "Spreadsheet read",
    },
    write_to_spreadsheet: {
      present: "Writing to spreadsheet...",
      past: "Spreadsheet written to",
    },
    get_past_execution_results: {
      present: "Getting previous results...",
      past: "Previous results retrieved",
    },
    use_browser_with_goal: {
      present: "Using browser...",
      past: "Browser used",
    },
    run_seo_analysis: {
      present: "Performing SEO analysis...",
      past: "SEO analysis completed",
    },
  };

  static fromActionToVerb(action: string, tense: "present" | "past"): string {
    return NameConventions.actionVerbs[action]?.[tense] || action;
  }

  /**
   * Converts technical names to human readable names
   * @param name The technical name to convert
   * @returns The human readable name
   */
  static toHuman(name: string): string {
    if (typeof name != "string") {
      return name;
    }
    return (
      NameConventions.knownConventions[name] ||
      NameConventions.toHumanReadable(name)
    );
  }

  static replaceKnownConventionsFromString(text: string): string {
    return text.replace(/(\w+)/g, (match) => NameConventions.toHuman(match));
  }

  private static toHumanReadable(name: string): string {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }
}

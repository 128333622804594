import { Topic, Workflow } from "@/utils/types";
import { useEffect, useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import { Badge } from "../design-system/badge";
import Tooltip from "./Tooltip";
import SecondaryPanel from "./panels/SecondaryPanel";
import EditTopicView from "@/screens/partials/EditTopicView";

type TopicLabelProps = {
  topic: Topic;
  workflow?: Workflow;
  tool_key?: string;
  showSourcesCounter?: boolean;
  onRemove: () => Promise<void>;
  onRefresh?: () => void;
};

export default function TopicLabel({
  topic,
  workflow,
  tool_key,
  showSourcesCounter,
  onRemove,
  onRefresh,
}: TopicLabelProps) {
  const [showModal, setShowModal] = useState(false);
  const [suggestedSourcesetsCount, setSuggestedSourcesetsCount] = useState(0);

  const handleRemove = async () => {
    onRemove();
  };

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    const selectToolInputTopic = () => {
      if (
        !workflow ||
        !workflow.artefact ||
        !workflow.artefact.inputs ||
        !workflow.artefact.inputs.sourceset
      ) {
        return;
      }
      const inputs = workflow?.artefact.inputs;
      const sourcesets_from_topic = inputs.sourceset?.filter(
        (input) => input.metadata.suggested_from_topic_uuid === topic.uuid,
      );
      if (sourcesets_from_topic) {
        setSuggestedSourcesetsCount(sourcesets_from_topic.length);
      }
    };

    selectToolInputTopic();
  }, [topic.uuid, workflow]);

  return (
    <>
      <Tooltip
        content={`${suggestedSourcesetsCount} sources suggested by this topic`}
      >
        <Badge
          onClick={() => {
            openModal();
          }}
          className="cursor-pointer"
        >
          <span className="text-xs uppercase">{topic.name}</span>
          <RiCloseFill />
        </Badge>
      </Tooltip>
      {showSourcesCounter && (
        <span className="ml-2 text-xs text-gray-600 dark:text-gray-400 opacity-50 hover:opacity-100 items-align-end">
          +{suggestedSourcesetsCount} sources
        </span>
      )}

      <SecondaryPanel
        open={showModal}
        onClose={closeModal}
        title={`${topic.name} - Details`}
      >
        {workflow && tool_key && (
          <EditTopicView
            topic={topic}
            tool_key={tool_key}
            workflow={workflow}
            onRemove={handleRemove}
            onRefresh={onRefresh}
            showDeleteButton={false}
            saveButtonAction={closeModal}
          />
        )}
      </SecondaryPanel>
    </>
  );
}

// import { Task, Message } from "@/utils/types";
import ContentShareButtons from "@/components/ContentShareButtons";
import { Button } from "@/components/design-system/button";
import {
  Dialog,
  DialogBody,
  DialogTitle,
} from "@/components/design-system/dialog";
import { Divider } from "@/components/design-system/divider";
import { PlanStatusComponent } from "@/components/PlanStatusComponent";
import ConfirmationButton from "@/components/styled/ConfirmationButton";
import MarkdownRenderer from "@/components/styled/MarkdownRenderer";
import Tooltip from "@/components/styled/Tooltip";
import { useWorkflows } from "@/contexts/WorkflowsContext";
import MarconipyApi from "@/utils/marconipyApi";
import { Workflow, WorkflowRun } from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { RiPlayFill, RiQuestionFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { SourceItemsPartialView } from "./SourceItemsPartialView";
import { useTeams } from "@/contexts/TeamContext";

export default function TestStep({
  workflow,
  onTestConfirmed,
  onWorkflowReload,
}: {
  workflow: Workflow;
  onTestConfirmed: () => void;
  onWorkflowReload: () => void;
}) {
  const [completedContent, setCompletedContent] = useState<string | null>(null);
  const [sourceItemsUUIDs, setSourceItemsUUIDs] = useState<string[]>([]);
  const [artefactUUID, setArtefactUUID] = useState<string>("");
  const [openResultModal, setOpenResultModal] = useState<boolean>(false);
  const [runWorkflowJustClicked, setRunWorkflowJustClicked] = useState(false);
  const { workflowWasRefreshed } = useWorkflows();
  const { showUpgradeDialog } = useTeams();
  const posthog = usePostHog();
  const workflowIsRunning = workflow
    ? [
        "running",
        "run_waiting_for_input",
        "tool_in_progress",
        "generating_response",
        "sending_to_outputs",
      ].includes(workflow.state["name"])
    : false;
  const pendingState =
    workflow &&
    workflow.state &&
    (workflow.state.name == "pending" || workflow.state.name == "running");

  const startWorkflowRun = async () => {
    if (!workflow) {
      return;
    }
    posthog.capture("workflowEdit: start workflow", {
      source: "workflowview",
      dry_run: true,
    });
    try {
      (await MarconipyApi.newWorkflowRun(workflow.uuid)) as any as {
        workflow_uuid: string;
      };
    } catch (e: any) {
      //check if you got a 402 error and in case show a toast
      if (e.response && e.response.status == 402) {
        posthog.capture("workflowEdit: billing limit reached", {
          source: "workflowview",
        });
        showUpgradeDialog();
      } else {
        posthog.capture("workflowEdit: error starting workflow", {
          source: "workflowview",
        });
        toast.error("Error starting dry run: " + e.message);
      }
    }
  };

  const runWorkflowJustClickedTimeoutStart = () => {
    setRunWorkflowJustClicked(true);
    startWorkflowRun();
    onWorkflowReload();
    setTimeout(() => {
      setRunWorkflowJustClicked(false);
    }, 2000);
  };

  useEffect(() => {
    const grabLatestResult = async () => {
      if (!workflow) {
        return;
      }
      const workflow_runs_obj = (await MarconipyApi.getWorkflowRuns(
        workflow.uuid,
        true,
      )) as any as { workflow_runs: WorkflowRun[] };
      //order the objects per updated_at
      workflow_runs_obj.workflow_runs.sort((a: WorkflowRun, b: WorkflowRun) => {
        return (
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        );
      });
      if (!workflow_runs_obj || workflow_runs_obj.workflow_runs.length == 0) {
        return;
      }
      const latest_workflow_run = workflow_runs_obj.workflow_runs[0];
      if (
        latest_workflow_run &&
        latest_workflow_run.artefact &&
        latest_workflow_run.artefact.content &&
        latest_workflow_run.artefact.content.result
      ) {
        if (typeof latest_workflow_run.artefact.content.result == "string") {
          setCompletedContent(latest_workflow_run.artefact.content.result);
        } else if (
          typeof latest_workflow_run.artefact.content.result == "object" &&
          latest_workflow_run.artefact.content.result.document
        ) {
          setCompletedContent(
            latest_workflow_run.artefact.content["result"]["document"],
          );
        }
        setArtefactUUID(latest_workflow_run.artefact.uuid);
      }

      if (
        latest_workflow_run &&
        latest_workflow_run.artefact &&
        latest_workflow_run.artefact.content &&
        latest_workflow_run.artefact.content
      ) {
        for (const key in latest_workflow_run.artefact.content) {
          if (key.includes("sourceitems")) {
            setSourceItemsUUIDs(latest_workflow_run.artefact.content[key]);
            break;
          } else {
            for (const key2 in latest_workflow_run.artefact.content[key]) {
              if (key2.includes("sourceitems")) {
                setSourceItemsUUIDs(
                  latest_workflow_run.artefact.content[key][key2],
                );
                break;
              }
            }
          }
        }
      }
    };
    if (workflowWasRefreshed) {
      grabLatestResult();
    }
  }, [workflow, workflowWasRefreshed]);

  return (
    <>
      <div className="mt-2">
        {/* <SubchapterTitle title="Dry run result" />
        <br/> */}
        <div className="max50vh overflow-y-auto markdown-content">
          {!completedContent && (
            <div className="flex gap-4">
              <p className="text-gray-500 dark:text-white">
                {workflowIsRunning ? (
                  <>
                    Your workflow is running. The result will be displayed here
                  </>
                ) : (
                  <>
                    Try a dry run with the current setup. The result will be
                    displayed here
                  </>
                )}
              </p>
              <Tooltip content="You can test this workflow without running it with all of your integrations connected">
                <Button
                  variant="secondary"
                  onClick={() =>
                    window.open(
                      "https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42?v=6053a8590d7a425f8e7ff3096f39e516&pvs=4",
                    )
                  }
                >
                  <RiQuestionFill />
                </Button>
              </Tooltip>
            </div>
          )}
          {completedContent && (
            <div
              className="relative max-h-80 text-ellipsis overflow-hidden border border-gray rounded-lg p-4 cursor-pointer"
              onClick={() => setOpenResultModal(true)}
            >
              <div className="absolute inset-x-0 top-0 h-full bg-gradient-to-b from-transparent via-transparent to-white dark:to-black pointer-events-none"></div>
              <MarkdownRenderer text={completedContent} />
            </div>
          )}
        </div>
      </div>
      <br />
      <Divider />
      <br />
      <div className="flex sm:flex-col justify-start gap-4 items-start">
        <ConfirmationButton
          workflow={workflow}
          tooltip="Confirm that the dry run is working as expected. You can always change this workflow later"
          type="test"
          onClick={onTestConfirmed}
          disabled={!completedContent}
          tooltip_disabled="Run the workflow to see the dry run result"
        />
        <div className="flex gap-2">
          <Button
            variant="secondary"
            className="flex items-center gap-2"
            onClick={() => runWorkflowJustClickedTimeoutStart()}
            disabled={runWorkflowJustClicked}
          >
            <RiPlayFill />
            {pendingState ? "Resume" : "Start dry run"}
          </Button>
          <PlanStatusComponent
            workflow_uuid={workflow.uuid}
            team_uuid={workflow.team}
            hide_full_runs={true}
          />
        </div>
      </div>
      <Dialog
        open={openResultModal}
        onClose={() => setOpenResultModal(false)}
        size="3xl"
      >
        {completedContent && (
          <ContentShareButtons
            artefactUUID={artefactUUID}
            content={completedContent}
          />
        )}
        <DialogTitle>Dry Run Result</DialogTitle>
        <DialogBody>
          <div className="markdown-content overflow-y-auto dark:text-white w-xl">
            {completedContent && <MarkdownRenderer text={completedContent} />}
            {sourceItemsUUIDs.length > 0 && (
              <div className="mt-8">
                <SourceItemsPartialView sourceItemsUUIDs={sourceItemsUUIDs} />
              </div>
            )}
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
}

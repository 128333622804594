// import { Task, Message } from "@/utils/types";
import { Button } from "@/components/design-system/button";
import { Divider } from "@/components/design-system/divider";
import AdvancedSettingsOpener from "@/components/styled/AdvancedSettingsOpener";
import Tooltip from "@/components/styled/Tooltip";
import SubchapterTitle from "@/components/SubchapterTitle";
import { Workflow } from "@/utils/types";
import { RiQuestionFill } from "react-icons/ri";
import OutputChannels from "./OutputChannels";
import OutputTemplates from "./OutputTemplates";
export default function OutputStep({
  workflow,
  switchToNextPendingStep,
}: {
  workflow: Workflow;
  switchToNextPendingStep: (justConfirmed: string) => void;
}) {
  return (
    <div className="flex flex-col gap-4 h-full overflow-x-hidden">
      <OutputChannels
        workflow={workflow}
        onOutputConfirmed={switchToNextPendingStep}
      />
      <Divider />
      <AdvancedSettingsOpener label="Advanced settings">
        <>
          <div className="w-full flex items-center justify-between m-4">
            <SubchapterTitle title="Result format" />
            <Tooltip content="Choose how to get the result of your task">
              <Button
                variant="secondary"
                onClick={() =>
                  window.open(
                    "https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42?v=6053a8590d7a425f8e7ff3096f39e516&pvs=4",
                  )
                }
              >
                <RiQuestionFill />
              </Button>
            </Tooltip>
          </div>
          <OutputTemplates workflow={workflow} />
        </>
      </AdvancedSettingsOpener>
    </div>
  );
}

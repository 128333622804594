import { Button } from "@/components/design-system/button";
import MarconipyApi from "@/utils/marconipyApi";
import { SlackChannelAPIResponse, Trigger, Workflow } from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import React, { useCallback, useEffect, useState } from "react";
import { RiCheckFill, RiDeleteBinFill, RiLoader2Fill } from "react-icons/ri";
import { toast } from "react-toastify";
import ConnectSlackButton from "./ConnectSlackButton";
import { Label } from "./design-system/fieldset";
import { Input } from "./design-system/input";
import { Switch, SwitchField } from "./design-system/switch";
import Tooltip from "./styled/Tooltip";

type TriggerSlack = {
  workflow: Workflow;
  trigger_uuid?: string;
  onRefresh?: () => void;
};

export function TriggerSlack({
  workflow,
  trigger_uuid,
  onRefresh,
}: TriggerSlack) {
  const posthog = usePostHog();
  const [isConnected, setIsConnected] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [triggerPhrase, setTriggerPhrase] = useState(workflow.name);
  const [availableSlackChannels, setAvailableSlackChannels] = useState([
    { name: "#general", id: "1" },
    { name: "#loading", id: "2" },
  ] as SlackChannelAPIResponse[]);
  const [anychangesMade, setAnyChangesMade] = useState(false);
  const [selectedSlackChannel, setSelectedSlackChannel] = useState("all");

  const loadTrigger = useCallback(async () => {
    if (trigger_uuid) {
      const fchannels = (await MarconipyApi.workflowTriggers(
        workflow.uuid,
      )) as any as { triggers: Trigger[] };
      const fchannel = fchannels.triggers.find((c) => c.uuid === trigger_uuid);
      if (!fchannel) return;
      setIsActive(fchannel.active);
      if (fchannel.metadata && fchannel.metadata["channel_id"]) {
        setSelectedSlackChannel(fchannel.metadata["channel_id"]);
      }
      if (fchannel.metadata && fchannel.metadata["trigger_phrase"]) {
        setTriggerPhrase(fchannel.metadata["trigger_phrase"]);
      }
    }
  }, [trigger_uuid, workflow.uuid]);

  const handleTriggerPhraseChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTriggerPhrase(event.target.value);
    setAnyChangesMade(true);
  };
  const handleToggle = () => {
    if (isActive) {
      posthog.capture("workflowEdit: toggle trigger off", {
        source: "workflow trigger",
        type: "slack",
      });
    } else {
      posthog.capture("workflowEdit: toggle trigger on", {
        source: "workflow trigger",
        type: "slack",
      });
    }
    setIsActive(!isActive);
    handleSave(!isActive);
  };

  const handleSelectedSlackChannel = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    e.preventDefault();
    setSelectedSlackChannel(e.target.value);
    setAnyChangesMade(true);
  };

  const handleSave = async (force_active?: boolean) => {
    let active = isActive;
    if (force_active === true || force_active === false) {
      active = force_active;
    }
    if (!trigger_uuid) {
      const res = (await MarconipyApi.createWorkflowTrigger(workflow.uuid, {
        trigger_type: "slack",
        active,
      })) as any as { trigger: Trigger };
      trigger_uuid = res.trigger.uuid;
    }
    if (active) {
      posthog.capture("workflowEdit: edit slack trigger", {
        source: "workflow triggers",
        type: "slack",
      });
    } else {
      posthog.capture("workflowEdit: deactivate slack trigger", {
        source: "workflow triggers",
        type: "slack",
      });
    }
    // if (selectedSlackChannel && selectedSlackChannel != '') {
    //   await MarconipyApi.editOutputChannel(workflow.uuid, output_channel_uuid, { active: active, channel_type: 'slack', metadata: { channel_id: selectedSlackChannel } });
    // } else {
    //   await MarconipyApi.editOutputChannel(workflow.uuid, output_channel_uuid, { active: active, channel_type: 'slack' });
    // }
    await MarconipyApi.editWorkflowTrigger(workflow.uuid, trigger_uuid, {
      active: active,
      trigger_type: "slack",
      metadata: {
        channel_id: selectedSlackChannel,
        trigger_phrase: triggerPhrase,
      },
    });

    setAnyChangesMade(false);
    onRefresh && onRefresh();
    if (active && !force_active) {
      toast.success(`Trigger saved`);
    }
  };

  const connectionStatusCallback = useCallback(
    async (connected: boolean) => {
      setIsConnected(connected);
      if (connected) {
        const fchannels = (await MarconipyApi.getSlackChannels(
          workflow.uuid,
        )) as any as { channels: SlackChannelAPIResponse[] };
        setAvailableSlackChannels(fchannels.channels);
      }
    },
    [workflow.uuid],
  );

  const deleteTrigger = async () => {
    if (!trigger_uuid) return;
    await MarconipyApi.deleteWorkflowTrigger(workflow.uuid, trigger_uuid);
    onRefresh && onRefresh();
  };

  useEffect(() => {
    loadTrigger();
  }, [workflow.uuid, trigger_uuid, loadTrigger]);

  return (
    <div className="">
      <div className="flex gap-4 my-4 items-start">
        <SwitchField>
          <Label className="font-semibold">💬 Trigger with Slack message</Label>
          <Switch onChange={() => handleToggle()} checked={isActive} />
        </SwitchField>
        {trigger_uuid && isActive && (
          <div className="ml-4">
            <Tooltip content="Delete Slack trigger">
              <Button variant="secondary" onClick={deleteTrigger}>
                <RiDeleteBinFill />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="mt-4 flex items-center text-sm mb-2">
        {isActive ? (
          <>
            <RiCheckFill className="text-tertiary mr-1" />
            Trigger is active
          </>
        ) : (
          <>
            <RiLoader2Fill className="opacity-50 mr-1" />
            Trigger is inactive
          </>
        )}
      </div>

      {isActive && (
        <div className="mb-6">
          <ConnectSlackButton
            variant="secondary"
            callbackWithConnectionStatus={connectionStatusCallback}
          />
        </div>
      )}
      {isConnected && (
        <>
          <div className="mt-4">
            <div className="flex items-center mb-2">
              <Tooltip content="If you say this phrase to your TailorTask slack bot, it will trigger this workflow">
                <p className="text-sm">Trigger when you say:</p>
              </Tooltip>
            </div>
            <div className="flex items-center">
              <Input
                type="text"
                id="triggerPhrase"
                value={triggerPhrase}
                onChange={handleTriggerPhraseChange}
                placeholder="Enter trigger phrase"
              />
            </div>
          </div>
          <div className="mt-4">
            <p className="text-sm  mb-2">Channels</p>
            <select
              onChange={handleSelectedSlackChannel}
              className="w-full p-2 border rounded dark:bg-black-dark"
              disabled={availableSlackChannels.length === 0}
              value={selectedSlackChannel}
            >
              <option value="all">Trigger on any channel</option>
              {availableSlackChannels.map((channel, index) => (
                <option key={index} value={channel.id}>
                  #{channel.name}
                </option>
              ))}
              {availableSlackChannels.length === 0 && (
                <option value="0">No channels available</option>
              )}
            </select>
          </div>
        </>
      )}
      {anychangesMade && (
        <div className="mt-4">
          <Button
            variant="secondary"
            onClick={() => {
              handleSave();
            }}
          >
            Save changes
          </Button>
        </div>
      )}
    </div>
  );
}

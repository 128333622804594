// import { Task, Message } from "@/utils/types";
import { Button } from "@/components/design-system/button";
import { Description } from "@/components/design-system/fieldset";
import { Switch, SwitchField } from "@/components/design-system/switch";
import { ManualTriggerComponent } from "@/components/ManualTriggerComponent";
import Label from "@/components/styled/Label";
import { Workflow } from "@/utils/types";
import { RiArrowRightFill, RiRocket2Fill } from "react-icons/ri";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router";
import successAnimation from "../../lottie/success.json";
export default function LaunchStep({
  workflow,
  showSuccessAnimationToggle,
  onWorkflowActivate,
}: {
  workflow: Workflow;
  showSuccessAnimationToggle: boolean;
  onWorkflowActivate: () => void;
}) {
  const navigate = useNavigate();
  return (
    <>
      {!workflow.active &&
        !showSuccessAnimationToggle &&
        workflow.setup_completed && (
          <>
            <p className="text-sm">Everything is ready!</p>
            <p className="text-sm">
              Activate your agent to have it run according to the triggers
            </p>
            <Button
              variant="primary"
              className="mt-4"
              onClick={onWorkflowActivate}
            >
              <RiRocket2Fill />
              Activate Agent
            </Button>
          </>
        )}
      {!workflow.active &&
        !showSuccessAnimationToggle &&
        !workflow.setup_completed && (
          <>
            <p className="text-sm">
              Complete the steps above to activate your agent.
            </p>
            <p className="text-sm">
              Once an agent is active, it will run according to the triggers
            </p>
            <Button
              variant="primary"
              disabled={true}
              className="mt-4"
              onClick={onWorkflowActivate}
            >
              <RiRocket2Fill />
              Activate Agent
            </Button>
          </>
        )}
      {workflow.active && !showSuccessAnimationToggle && (
        <SwitchField>
          <Label>{workflow.active ? "Agent active" : "Agent inactive"}</Label>
          <Description>
            {!workflow.setup_completed
              ? "Complete all the steps before onboarding your agent on Slack or MS Teams"
              : workflow.active
                ? "Your agent is active and will run according to the Triggers"
                : "Your agent is inactive and will not run if triggered"}
          </Description>
          <Switch
            name="activate_workflow"
            checked={workflow.active}
            onChange={onWorkflowActivate}
            disabled={!workflow.setup_completed && !workflow.active}
          />
        </SwitchField>
      )}
      {showSuccessAnimationToggle && (
        <Lottie
          loop={false}
          animationData={successAnimation}
          play
          style={{ width: 250, height: 250 }}
        />
      )}
      {workflow.setup_completed &&
        workflow.active &&
        !showSuccessAnimationToggle && (
          <div className="flex flex-col gap-4 mt-4">
            <ManualTriggerComponent workflow={workflow} />
            <span>
              <Button
                onClick={() => {
                  navigate(`/agents/${workflow.uuid}`);
                }}
                variant="secondary"
              >
                <RiArrowRightFill />
                Exit Setup
              </Button>
            </span>
          </div>
        )}
    </>
  );
}

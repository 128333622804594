import axios, { AxiosResponse } from "axios";
import { posthog } from "posthog-js";
import { Topic, User } from "./types";

interface ApiError {
  json: object;
  noConnection?: boolean;
}

class MarconipyApi {
  static updateRequired = false;
  static token = "";
  static user: User | null = null;
  static baseUrl = (() => {
    switch (import.meta.env.MODE) {
      case "production":
        return "https://bkn.tailortask.ai";
      default:
        return "http://localhost:8000";
      // return "https://bkn.tailortask.ai";
    }
  })();

  static headers: any = {
    "Content-Type": "application/json",
    Accept: "application/json, text/plain, */*",
  };

  static client = axios.create({
    baseURL: MarconipyApi.baseUrl,
    headers: MarconipyApi.headers,
  });

  static refreshClient() {
    MarconipyApi.client = axios.create({
      baseURL: MarconipyApi.baseUrl,
      headers: MarconipyApi.headers,
    });
  }

  constructor() {}

  static noConnectionError(): ApiError {
    return { json: { server: "down" }, noConnection: true };
  }

  static checkToInvalidateToken(error: any) {
    if (error && error.response && error.response.status === 401) {
      MarconipyApi.setToken("");
      MarconipyApi.saveToken();
      MarconipyApi.logout();
      return;
    }
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.detail
    ) {
      //create an ApiError object and throw it with the detail data
      throw { json: { detail: error.response.data.detail } };
    }
    if (error && error.code == "ERR_NETWORK") {
      throw error;
    }
  }

  static setToken(token: string) {
    MarconipyApi.token = token;

    if (token !== "") {
      MarconipyApi.headers = {
        Authorization: `Token ${MarconipyApi.token}`,
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*",
      };
    } else {
      MarconipyApi.headers = {
        "Content-Type": "application/json",
        Accept: "application/json, text/plain, */*",
      };
    }
    MarconipyApi.saveToken();
    MarconipyApi.refreshClient();
  }

  static saveToken() {
    // Add your token saving implementation here
    localStorage.setItem("token", MarconipyApi.token);
  }

  static loadToken() {
    // Add your token loading implementation here
    const token = localStorage.getItem("token");
    if (token) {
      MarconipyApi.setToken(token);
    }
  }

  static discardToken() {
    // Add your token discarding implementation here
    localStorage.removeItem("token");
    MarconipyApi.setToken("");
  }

  static isAuth() {
    if (MarconipyApi.token === "" || MarconipyApi.token === undefined) {
      this.loadToken();
      if (MarconipyApi.token === "" || MarconipyApi.token === undefined) {
        return false;
      }
    }
    return true;
  }

  static async signup(
    email: string,
    password1: string,
    password2: string,
    first_name: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    const authObj = {
      username: email,
      email,
      password1,
      password2,
      first_name: first_name,
    };

    const response = await MarconipyApi.client.post(
      "/dj-rest-auth/registration/",
      authObj,
    );
    return response;
  }

  static async signupSocial(
    provider: string,
    code: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    const authObj = {
      code: code,
    };

    try {
      const response = await MarconipyApi.client.post(
        "/dj-rest-auth/" + provider + "/",
        authObj,
      );
      MarconipyApi.setToken(response.data.token);
      return response;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  static async googleDriveAuth(
    code: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    const authObj = {
      code: code,
    };

    try {
      const response = await MarconipyApi.client.post(
        "/dj-rest-auth/google/drive/",
        authObj,
      );
      MarconipyApi.setToken(response.data.token);
      return response;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  }

  static async googleSheetsAuthStatus(): Promise<
    AxiosResponse<any> | ApiError
  > {
    try {
      const response = await MarconipyApi.client.get("/spreadsheets/status/");
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      console.log("error logging in");
      throw error;
    }
  }

  static async addGoogleSpreadsheet(
    spreadsheet_id: string,
    name: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post("/spreadsheets/", {
        spreadsheet_id: spreadsheet_id,
        name: name,
        provider: "google_sheets",
      });
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      console.log("error logging in");
      throw error;
    }
  }

  static async login(
    email: string,
    password: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    const authObj = {
      username: email,
      email,
      password,
    };

    try {
      const response = await MarconipyApi.client.post(
        "/dj-rest-auth/login/",
        authObj,
      );
      MarconipyApi.setToken(response.data.token);
      return response;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      console.log("error logging in");
      throw error;
    }
  }

  static async logout(): Promise<AxiosResponse<any> | ApiError> {
    const response = await MarconipyApi.client.post("/dj-rest-auth/logout/");
    MarconipyApi.setToken("");
    posthog?.capture("logout");
    posthog.reset();
    return response;
  }

  static async resetPassword(
    email: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    const authObj = {
      email,
    };
    const response = await MarconipyApi.client.post(
      "/auth/send-code-email/",
      authObj,
    );
    return response;
  }

  static async confirmCode(
    email: string,
    code: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    const authObj = {
      email,
      code,
    };

    try {
      const response = await MarconipyApi.client.post(
        "/auth/send-code-email/conf/",
        authObj,
      );
      return response;
    } catch (error) {
      console.log("error logging in");
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async sendNewPassword(
    email: string,
    password1: string,
    password2: string,
    code: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    const authObj = {
      email,
      password1,
      password2,
      code,
    };

    try {
      const response = await MarconipyApi.client.post(
        "/auth/send-code-email/code/",
        authObj,
      );
      return response;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getSocialProviderLoginURL(
    provider: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    const response = await MarconipyApi.client.get(`/auth/${provider}/`);
    return response.data;
  }

  static async getSlackConnectionStatus(): Promise<any | ApiError | null> {
    try {
      const response = await MarconipyApi.client.get(`/slack/status/`);
      if (response.status > 299) {
        console.log("error getting slack connection status");
        console.warn(response.data);
        return {} as any;
      }
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getSlackAuthUrl(): Promise<any | ApiError | null> {
    try {
      const response = await MarconipyApi.client.get(`/slack/install/`);
      if (response.status > 299) {
        console.log("error getting slack auth url");
        console.warn(response.data);
        return {} as any;
      }
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async postSlackOauthCallback(
    code: any,
    state: any,
  ): Promise<any | ApiError | null> {
    try {
      const response = await MarconipyApi.client.post(`/slack/oauth/`, {
        code: code,
        state: state,
      });
      if (response.status > 299) {
        console.log("error posting slack oauth callback");
        console.warn(response.data);
        return {} as any;
      }
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getNotificationSettings(): Promise<
    AxiosResponse<any> | ApiError
  > {
    try {
      const response = await MarconipyApi.client.get(
        `users/@me/notification-settings/`,
      );
      return response.data;
    } catch (error) {
      console.error("Error getting notifications:", error);
      throw error;
    }
  }

  static async changeNotificationSettings(
    vars: any = {},
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      let params = vars;
      const response = await MarconipyApi.client.patch(
        `users/@me/notification-settings/update/`,
        params,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async putProfile({
    first_name = "",
    email = "",
    last_name = "",
    name = "",
    onboarded = false,
  }: {
    first_name?: string;
    email?: string;
    last_name?: string;
    name?: string;
    onboarded?: boolean;
  }): Promise<AxiosResponse<any> | ApiError> {
    let authObj: Record<string, any> = {};
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    authObj["timezone"] = tz;
    if (first_name !== "") {
      authObj["first_name"] = first_name;
    }
    if (email !== "") {
      authObj["email"] = email;
    }
    if (last_name !== "") {
      authObj["last_name"] = last_name;
    }
    if (name !== "") {
      authObj["name"] = name;
    }
    if (onboarded) {
      authObj["onboarded"] = onboarded;
    }

    try {
      const response = await MarconipyApi.client.patch(
        "/dj-rest-auth/user/",
        authObj,
      );
      return response;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  // static async putSignedUpWithCode(
  //   code: string
  // ): Promise<AxiosResponse<any> | ApiError> {
  //   try {
  //     const response = await MarconipyApi.client.post(`/invitecode`, {
  //       invite_code: code,
  //     });
  //     return response.data;
  //   } catch (error) {
  //     MarconipyApi.checkToInvalidateToken(error);
  //     throw error;
  //   }
  // }

  static async getUserStatus(): Promise<User | null> {
    try {
      const response = await MarconipyApi.client.get(`users/@me/status/`);
      if (response.status > 299) {
        return null;
      }
      MarconipyApi.user = response.data as User;
      return MarconipyApi.user;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getTeam(team_uuid: string): Promise<User | null> {
    try {
      const response = await MarconipyApi.client.get(`/teams/${team_uuid}/`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getDefaultTeam(): Promise<any | null> {
    try {
      const response = await MarconipyApi.client.get(`/teams/default/`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async changeDefaultTeam(team_uuid: string): Promise<User | null> {
    //changes the default team to the one specified
    try {
      const response = await MarconipyApi.client.post(
        `/teams/${team_uuid}/default/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getTeamMembers(team_uuid: string): Promise<User | null> {
    try {
      const response = await MarconipyApi.client.get(
        `/teams/${team_uuid}/members/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async updateTeamName(teamUuid: string, newName: string) {
    try {
      const response = await MarconipyApi.client.patch(`/teams/${teamUuid}/`, {
        name: newName,
      });
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async createTeam(): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(`/teams/`, {});
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async deleteTeam(
    team_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.delete(`/teams/${team_uuid}/`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async removeTeamMember(
    team_uuid: string,
    member_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.delete(
        `/teams/${team_uuid}/members/${member_uuid}/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async leaveTeam(
    team_uuid: string,
    member_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.delete(
        `/teams/${team_uuid}/members/${member_uuid}/leave`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async confirmEmail(
    key: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/dj-rest-auth/registration/verify-email/`,
        { key: key },
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async resendConfirmationEmail(
    email: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/dj-rest-auth/registration/resend-email/`,
        { email: email },
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getTeams(): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(`/teams/`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getOrganization(
    organization_id: string | null,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/organizations/${organization_id}/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getStripePaymentLinkForOrganization(
    organization_id: string,
    yearly: boolean = false,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/stripe/payment-link/${organization_id}?yearly=${yearly}`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getStripeCustomerPortalSession(
    organization_id: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/stripe/customer-portal-session/${organization_id}`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async planLimitPerWorkflow(
    workflow_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/workflows/${workflow_uuid}/plan/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async oauth(
    clientId: string,
    redirectUri: string,
    scope: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post("/oauth/app-details/", {
        client_id: clientId,
        redirect_uri: redirectUri,
        scope: scope,
      });
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async authorizeOAuth(
    clientId: string,
    redirectUri: string,
    scope: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post("/oauth/authorize/", {
        client_id: clientId,
        redirect_uri: redirectUri,
        scope: scope,
      });
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async exchangeCodeForToken(
    code: string,
    clientId: string,
    clientSecret: string,
    redirectUri: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post("/oauth/token/", {
        code: code,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri,
        grant_type: "authorization_code",
      });
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async listTeamInvites(
    team_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/teams/${team_uuid}/invites/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async inviteTeamMember(
    team_uuid: string,
    email: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/teams/${team_uuid}/invites/`,
        {
          email: email,
        },
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getInvite(
    invite_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/invites/${invite_uuid}/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  // __          ______  _____  _  ________ _      ______          _______
  // \ \        / / __ \|  __ \| |/ /  ____| |    / __ \ \        / / ____|
  //  \ \  /\  / / |  | | |__) | ' /| |__  | |   | |  | \ \  /\  / / (___
  //   \ \/  \/ /| |  | |  _  /|  < |  __| | |   | |  | |\ \/  \/ / \___ \
  //    \  /\  / | |__| | | \ \| . \| |    | |___| |__| | \  /\  /  ____) |
  //     \/  \/   \____/|_|  \_\_|\_\_|    |______\____/   \/  \/  |_____/

  static async getWorkflows(): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(`/workflows/`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async getWorkflowsByTeam({
    teamUUID: teamUUID,
  }: {
    teamUUID: string;
  }): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/teams/${teamUUID}/workflows/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getWorkflow(
    workflow_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    if (!workflow_uuid) {
      throw Error("No workflow uuid provided");
    }
    try {
      const response = await MarconipyApi.client.get(
        `/workflows/${workflow_uuid}/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async createWorkflow(
    name?: string,
    team_uuid?: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      let obj: any = {};
      if (name) {
        obj["name"] = name;
      }
      if (team_uuid) {
        obj["team_uuid"] = team_uuid;
      }
      const response = await MarconipyApi.client.post(`/workflows/`, obj);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async editWorkflow(
    workflow_uuid: string,
    params: any,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.patch(
        `/workflows/${workflow_uuid}/`,
        params,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async deleteWorkflow(
    workflow_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.delete(
        `/workflows/${workflow_uuid}/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async restoreWorkflow(
    workflow_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.patch(
        `/workflows/${workflow_uuid}/`,
        {
          deleted: false,
        },
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async updateWorkflowContext(
    workflow_uuid: string,
    context: any,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.patch(
        `/workflows/${workflow_uuid}/`,
        { context: context },
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async addInput(
    workflow_uuid: string,
    tool_key: string,
    type: string,
    uuid: string,
    metadata: {
      suggest_sourcesets_types?: string[];
    },
  ): Promise<AxiosResponse<any> | ApiError> {
    let obj = {
      type: type,
      tool_key: tool_key,
      uuid: uuid,
      metadata,
    };
    try {
      const response = await MarconipyApi.client.post(
        `/workflows/${workflow_uuid}/inputs/`,
        obj,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async removeInput(
    workflow_uuid: string,
    uuid: string,
    tool_key: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.delete(
        `/workflows/${workflow_uuid}/inputs/${tool_key}/${uuid}/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async searchSourceSet(
    query: string,
    force: boolean = false,
    limit: number = 5,
  ): Promise<AxiosResponse<any> | ApiError> {
    //   // try {
    //   //   const response = await MarconipyApi.client.post(`/sourcesets/search/`, {
    //   //     query: query,
    //   //     limit: limit,
    //   //   });
    //   //   return response.data;
    //   // } catch (error) {
    //   //   MarconipyApi.checkToInvalidateToken(error);
    //   //   throw error;
    //   // }
    throw new Error("Not implemented");
  }

  static async getSourceSet(
    uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(`/sourcesets/${uuid}/`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async batchGetSourceSets(
    uuids: string[],
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/sourcesets/bulk-retrieve?uuids=${uuids.join(",")}`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getSourceItem(
    uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(`/sourceitems/${uuid}/`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async batchGetSourceItems(
    uuids: string[],
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/sourceitems/bulk-retrieve?uuids=${uuids.join(",")}`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async searchSourceSetAutocomplete(
    query: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/sourcesets/search/autocomplete/`,
        { query: query },
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async sourceSetSearchByLink(
    url: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/sourcesets/search/by_link/`,
        { url: url },
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async uploadSourcesetsCSV(
    workflow_uuid: string,
    tool_key: string,
    formData: FormData,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/workflows/${workflow_uuid}/upload_csv/?tool_key=` + tool_key,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getTopic(uuid: string): Promise<AxiosResponse<any> | ApiError> {
    try {
      let url = `/topics/${uuid}/`;
      const response = await MarconipyApi.client.get(url);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getTopicSourceSetsByWorkflow(
    uuid: string,
    workflow_uuid: string | null = null,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      let url = `workflows/${workflow_uuid}/topics/${uuid}/sourcesets`;
      const response = await MarconipyApi.client.get(url);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async batchGetTopics(
    uuids: string[],
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/topics/bulk-retrieve?uuids=${uuids.join(",")}`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async searchTopic(query: string): Promise<Topic | ApiError> {
    try {
      const response = await MarconipyApi.client.post(`/topics/search/`, {
        query: query,
      });
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async searchTopicAutocomplete(
    query: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/topics/search/autocomplete/`,
        { query: query },
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async workflowTriggers(
    workflow_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/workflows/${workflow_uuid}/triggers/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async createWorkflowTrigger(
    workflow_uuid: string,
    data: {
      trigger_type: string;
      active: boolean;
      schedule_type?: string;
      execution_time?: string;
    },
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/workflows/${workflow_uuid}/triggers/`,
        data,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async editWorkflowTrigger(
    workflow_uuid: string,
    trigger_uuid: string,
    params: {
      trigger_type: string;
      active: boolean;
      schedule_type?: string;
      execution_time?: string;
      day_of_week?: number;
      day_of_month?: number;
      metadata: {
        trigger_phrase?: string;
        channel_id?: string;
      };
    },
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.patch(
        `/workflows/${workflow_uuid}/triggers/${trigger_uuid}/`,
        params,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static deleteWorkflowTrigger(
    workflow_uuid: string,
    trigger_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      return MarconipyApi.client.delete(
        `/workflows/${workflow_uuid}/triggers/${trigger_uuid}/`,
      );
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async outputTemplates(
    workflow_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/workflows/${workflow_uuid}/templates/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async createOutputTemplate(
    workflow_uuid: string,
    template: any,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/workflows/${workflow_uuid}/templates/`,
        { template: template },
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async editOutputTemplate(
    workflow_uuid: string,
    template_uuid: string,
    template: any,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.patch(
        `/workflows/${workflow_uuid}/templates/${template_uuid}/`,
        { template: template },
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async deleteOutputTemplate(
    workflow_uuid: string,
    template_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.delete(
        `/workflows/${workflow_uuid}/templates/${template_uuid}/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async outputChannels(
    workflow_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/workflows/${workflow_uuid}/channels/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async createOutputChannel(
    workflow_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/workflows/${workflow_uuid}/channels/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async editOutputChannel(
    workflow_uuid: string,
    channel_uuid: string,
    obj: {
      channel_type: string;
      active: boolean;
      template_uuid?: string;
      metadata?: any;
    },
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.patch(
        `/workflows/${workflow_uuid}/channels/${channel_uuid}/`,
        obj,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async deleteOutputChannel(
    workflow_uuid: string,
    channel_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.delete(
        `/workflows/${workflow_uuid}/channels/${channel_uuid}/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getSlackChannels(
    workflow_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      //workflows/<slug:uuid>/output-channels/slack/channels
      const response = await MarconipyApi.client.get(
        `/workflows/${workflow_uuid}/channels/slack/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async newWorkflowRun(
    workflow_uuid: string,
    dry_run: boolean = true,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/workflows/${workflow_uuid}/runs/`,
        {
          dry_run: dry_run,
        },
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getWorkflowRuns(
    workflow_uuid: string,
    completed: boolean = false,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      let url = `/workflows/${workflow_uuid}/runs/`;
      if (completed) {
        url = url + "?completed=true";
      }
      const response = await MarconipyApi.client.get(url);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getAllWorkflowRuns(): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(`/runs/`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getWorkflowRun(
    workflow_run_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/runs/${workflow_run_uuid}/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getWorkflowRunMessages(
    workflow_run_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/runs/${workflow_run_uuid}/messages/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getTools(
    workflow_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/workflows/${workflow_uuid}/tools/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
  static async getAllTools(
    workflow_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/workflows/${workflow_uuid}/tools/?all=true`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getMessages(
    workflow_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/workflows/${workflow_uuid}/messages/`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getMessagesByWorkflowRun(
    workflow_uuid: string,
    workflow_run_uuid: string,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(
        `/workflows/${workflow_uuid}/messages/?workflow_run_uuid=${workflow_run_uuid}`,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async editMessage(
    workflow_uuid: string,
    message_uuid: string,
    params: any,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.patch(
        `/workflows/${workflow_uuid}/messages/${message_uuid}/`,
        params,
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getBackgroundJob(
    workflowUUID: string,
    toolUseID: string,
  ): Promise<AxiosResponse<any> | ApiError | null> {
    try {
      const response = await MarconipyApi.client.get(
        `/workflows/${workflowUUID}/jobs/${toolUseID}/`,
      );
      if (response.status > 299) {
        return null;
      }
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getShareableResult(
    workflowRunUUID: string,
  ): Promise<AxiosResponse<any> | ApiError | null> {
    try {
      const response = await MarconipyApi.client.get(
        `/results/${workflowRunUUID}/`,
      );
      if (response.status > 299) {
        return null;
      }
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async getWorkflowTemplates(): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.get(`/workflows/templates/`);
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }

  static async cloneWorkflow(
    workflow_uuid: string,
    metadata: any,
  ): Promise<AxiosResponse<any> | ApiError> {
    try {
      const response = await MarconipyApi.client.post(
        `/workflows/${workflow_uuid}/clone/`,
        { metadata },
      );
      return response.data;
    } catch (error) {
      MarconipyApi.checkToInvalidateToken(error);
      throw error;
    }
  }
}

export default MarconipyApi;

import { Button } from "@/components/design-system/button";
import MarconipyApi from "@/utils/marconipyApi";
import {
  OutputChannel,
  SlackChannelAPIResponse,
  Workflow,
} from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import React, { useCallback, useEffect, useState } from "react";
import {
  RiCheckboxBlankCircleFill,
  RiCheckFill,
  RiDeleteBinFill,
  RiLoader2Fill,
} from "react-icons/ri";
import { toast } from "react-toastify";
import ConnectSlackButton from "./ConnectSlackButton";
import { Label } from "./design-system/fieldset";
import { Select } from "./design-system/select";
import { Switch, SwitchField } from "./design-system/switch";
import Tooltip from "./styled/Tooltip";

type OutputChannelSlackProps = {
  workflow: Workflow;
  output_channel_uuid?: string;
  onRefresh?: () => void;
};

export function OutputChannelSlack({
  workflow,
  output_channel_uuid,
  onRefresh,
}: OutputChannelSlackProps) {
  const posthog = usePostHog();
  const [isConnected, setIsConnected] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [anychangesMade, setAnyChangesMade] = useState(false);
  const [channel_uuid, setChannelUuid] = useState(output_channel_uuid);
  const [availableSlackChannels, setAvailableSlackChannels] = useState([
    { name: "#general", id: "1" },
    { name: "#loading", id: "2" },
  ] as SlackChannelAPIResponse[]);
  const [selectedSlackChannel, setSelectedSlackChannel] = useState("");

  const handleToggle = () => {
    setIsActive(!isActive);
    handleSave(!isActive);
  };

  const deleteOutputChannel = async () => {
    if (!output_channel_uuid) return;
    posthog.capture("workflowEdit: delete output channel", {
      source: "workflow output channels",
      type: "slack",
    });
    await MarconipyApi.deleteOutputChannel(workflow.uuid, output_channel_uuid);
    onRefresh && onRefresh();
  };

  const handleSave = async (force_active?: boolean) => {
    let active = isActive;
    if (force_active !== undefined) {
      active = force_active;
    }
    if (!output_channel_uuid) {
      const res = (await MarconipyApi.createOutputChannel(
        workflow.uuid,
      )) as any as { output_channel: OutputChannel };
      output_channel_uuid = res.output_channel.uuid;
      setChannelUuid(output_channel_uuid);
    }
    if (active) {
      posthog.capture("workflowEdit: activate output channel", {
        source: "workflow output channels",
        type: "slack",
      });
    } else {
      posthog.capture("workflowEdit: deactivate output channel", {
        source: "workflow output channels",
        type: "slack",
      });
    }
    if (selectedSlackChannel && selectedSlackChannel != "") {
      await MarconipyApi.editOutputChannel(workflow.uuid, output_channel_uuid, {
        active: active,
        channel_type: "slack",
        metadata: { channel_id: selectedSlackChannel },
      });
    } else {
      await MarconipyApi.editOutputChannel(workflow.uuid, output_channel_uuid, {
        active: active,
        channel_type: "slack",
      });
    }
    setAnyChangesMade(false);
    if (active && !force_active) {
      toast.success(`Saved`);
    }
    onRefresh && onRefresh();
  };

  useEffect(() => {
    const loadOutputChannel = async () => {
      if (output_channel_uuid) {
        const fchannels = (await MarconipyApi.outputChannels(
          workflow.uuid,
        )) as any as { output_channels: OutputChannel[] };
        const fchannel = fchannels.output_channels.find(
          (c) => c.uuid === output_channel_uuid,
        );
        if (!fchannel) return;
        setIsActive(fchannel.active);
        if (fchannel.metadata && fchannel.metadata["channel_id"]) {
          setSelectedSlackChannel(fchannel.metadata["channel_id"]);
        }
      }
      setIsLoading(false);
    };

    loadOutputChannel();
  }, [workflow.uuid, output_channel_uuid]);

  const connectionStatusCallback = useCallback(
    async (connected: boolean) => {
      setIsConnected(connected);
      if (!connected) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
        const fchannels = (await MarconipyApi.getSlackChannels(
          workflow.uuid,
        )) as any as { channels: SlackChannelAPIResponse[] };
        setAvailableSlackChannels(fchannels.channels);
        setIsLoading(false);
      }
    },
    [workflow.uuid],
  );

  const handleSelectedSlackChannel = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    e.preventDefault();
    setSelectedSlackChannel(e.target.value);
    setAnyChangesMade(true);
  };

  return (
    <div className="g">
      <div className="flex gap-4 my-4 items-start">
        <SwitchField>
          <Label className="font-semibold">💬 Get a Slack</Label>
          {/* <Description>
            <span className="text-sm mb-2">
              You will get your result via Slack. Run this task to try it out.
            </span>
          </Description> */}
          <Switch onChange={() => handleToggle()} checked={isActive} />
        </SwitchField>
        {/* <label htmlFor="triggerToggle" className="ml-4 font-bold"></label> */}
        {channel_uuid && isActive && (
          <div className="ml-4">
            <Tooltip content="Delete Slack result settings">
              <Button variant="secondary" onClick={deleteOutputChannel}>
                <RiDeleteBinFill />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="mt-4 flex items-center text-sm  mb-2">
        {isActive ? (
          <>
            {isConnected && (
              <>
                <RiCheckFill className="text-green-500 mr-1" />
                Channel is active
              </>
            )}
            {!isConnected && (
              <>
                <RiCheckboxBlankCircleFill className="text-gray-400 mr-1" />
                Connect Slack to activate
              </>
            )}
          </>
        ) : (
          <>
            <RiLoader2Fill className="text-gray-400 mr-1" />
            Channel is inactive
          </>
        )}
      </div>
      {isActive && (
        <div className="mb-6">
          <ConnectSlackButton
            variant="secondary"
            callbackWithConnectionStatus={connectionStatusCallback}
          />
        </div>
      )}
      {isLoading && (
        <div className="mb-6">
          <RiLoader2Fill className="animate-spin" />
        </div>
      )}
      {isActive && isConnected && (
        <div className="mt-4">
          <p className="text-sm  mb-2">Post on channel</p>
          <Select
            onChange={handleSelectedSlackChannel}
            disabled={availableSlackChannels.length === 0}
            value={selectedSlackChannel}
          >
            <option value="">Select a channel</option>
            {availableSlackChannels.map((channel, index) => (
              <option key={index} value={channel.id}>
                #{channel.name}
              </option>
            ))}
            {availableSlackChannels.length === 0 && (
              <option value="0">No channels available</option>
            )}
          </Select>
        </div>
      )}
      {anychangesMade && (
        <div className="mt-4">
          <Button
            onClick={() => {
              handleSave();
            }}
          >
            Save changes
          </Button>
        </div>
      )}
    </div>
  );
}

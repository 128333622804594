import * as Sentry from "@sentry/react";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { loader as confirmemailviewLoader } from "@/screens/ConfirmEmail";
import Home, { loader as homeLoader } from "@/screens/Home";
import { loader as loginLoader } from "@/screens/Login";
import { loader as logoutLoader } from "@/screens/Logout";
import { loader as passwordForgottenLoader } from "@/screens/PasswordForgotten";
import { loader as signupLoader } from "@/screens/Signup";
import { loader as loginSocialLoader } from "@/screens/SocialLogin";

import { PostHogProvider } from "posthog-js/react";
import { loader as accountLoader } from "./screens/AccountView";
// import { loader as redirectorLoader } from "./screens/Redirector";
import { Loading } from "@/components/Loading";
import { BusyBadgerErrorBoundary } from "./contexts/BusyBadgerErrorBoundary";
import { loader as teamsViewLoader } from "./contexts/TeamContext";
import { loader as agentLoader } from "./screens/AgentView";
import BannedView, { loader as bannedViewLoader } from "./screens/BannedView";
import { loader as newAgentLoader } from "./screens/NewAgentView";
import { loader as slackCallbackLoader } from "./screens/SlackCallback";
import { loader as upgradePlanThankYouLoader } from "./screens/UpgradePlanThankYou";
import {
  redirectPlanLoader,
  loader as upgradePlanLoader,
} from "./screens/UpgradePlanView";
// import TermsOfUse from "./screens/static/TermsOfUse";
import RootStatic from "./screens/static/RootStatic";
// import PrivacyPolicy from "./screens/static/PrivacyPolicy";
// import OurPrinciples from "./screens/static/OurPrinciples";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PanelProvider } from "./components/styled/panels/PanelContext";
import { AuthProvider } from "./contexts/AuthenticationContext";
import { EventProvider } from "./contexts/EventsContext";
import useMobile from "./hooks/useMobile";
import { loader as googleDriveAuthLoader } from "./screens/GoogleDriveAuth";
import MobileFallbackView from "./screens/MobileFallbackView";
import OAuthScreen, {
  loader as oauthScreenLoader,
} from "./screens/OAuthScreen";
import { loader as shareLoader } from "./screens/ShareView";

const Root = React.lazy(() => import("@/screens/Root"));
const NavigationParentView = React.lazy(
  () => import("@/screens/NavigationParentView"),
);
const ConfirmEmailView = React.lazy(() => import("@/screens/ConfirmEmail"));
const Login = React.lazy(() => import("@/screens/Login"));
const SocialLogin = React.lazy(() => import("@/screens/SocialLogin"));
const GoogleDriveAuth = React.lazy(() => import("@/screens/GoogleDriveAuth"));
const Logout = React.lazy(() => import("@/screens/Logout"));
const Signup = React.lazy(() => import("@/screens/Signup"));
const PasswordForgotten = React.lazy(
  () => import("@/screens/PasswordForgotten"),
);

const AccountView = React.lazy(() => import("@/screens/AccountView"));
const SlackCallback = React.lazy(() => import("@/screens/SlackCallback"));
// const WaitlistView = React.lazy(() => import("@/screens/WaitlistView"));
const NotFoundView = React.lazy(() => import("@/screens/NotFoundPage"));
const TeamView = React.lazy(() => import("@/screens/TeamView"));
const UpgradePlanView = React.lazy(() => import("@/screens/UpgradePlanView"));
const UpgradePlanThankYou = React.lazy(
  () => import("@/screens/UpgradePlanThankYou"),
);
const AgentView = React.lazy(() => import("@/screens/AgentView"));
const NewAgentView = React.lazy(() => import("@/screens/NewAgentView"));
const NotAvailableInYourCountry = React.lazy(
  () => import("@/screens/static/NotAvailableInYourCountry"),
);
const ShareView = React.lazy(() => import("@/screens/ShareView"));

const withSuspense = (component: any) => {
  return <React.Suspense fallback={<Loading />}>{component}</React.Suspense>;
};

const overriddenFeatureFlags =
  localStorage.getItem("override-feature-flags") ?? "{}";
export const overriddenFeatureFlagsObject = JSON.parse(overriddenFeatureFlags);

const isAgent =
  navigator.userAgent.includes("Cypress") ||
  navigator.userAgent.includes("CACHEAUTOAGENT");
const canTrack = !isAgent;

// const documentationUrl =
//   "https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42?v=6053a8590d7a425f8e7ff3096f39e516&pvs=4";

const posthogoptions = {
  api_host: import.meta.env.VITE_REACT_APP_PROXY_POSTHOG_HOST,
  ui_host: import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST,
  debug: window.location.hostname.includes("localhost"),
  bootstrap: {
    featureFlags: {
      ...overriddenFeatureFlagsObject,
      "under-maintenance": Boolean(
        import.meta.env.VITE_REACT_APP_UNDER_MAINTENANCE,
      ),
    },
  },
  autocapture: canTrack,
  capture_pageview: canTrack,
  capture_pageleave: canTrack,
  disable_persistence: !canTrack,
  disable_session_recording: !canTrack,
  opt_out_capturing_by_default: !canTrack,
  opt_out_persistence_by_default: !canTrack,
};

let router = createBrowserRouter([
  {
    path: "*",
    element: withSuspense(<NotFoundView />),
  },
  {
    path: "/",
    element: <RootStatic />,
    children: [
      {
        path: "/",
        element: <Home />,
        loader: homeLoader,
      },
      {
        path: "/country-unavailable",
        element: <NotAvailableInYourCountry />,
      },
      {
        path: "/share/:workflowrun_uuid",
        element: withSuspense(<ShareView />),
        loader: shareLoader,
      },
    ],
  },
  {
    path: "/",
    element: withSuspense(<Root />),
    errorElement: <BusyBadgerErrorBoundary />,
    children: [
      {
        path: "/",
        element: withSuspense(<NavigationParentView />),
        errorElement: <BusyBadgerErrorBoundary />,
        children: [
          {
            path: "/",
            element: withSuspense(<Home />),
            loader: homeLoader,
          },
          {
            path: "/confirm-email/:key",
            element: withSuspense(<ConfirmEmailView />),
            loader: confirmemailviewLoader,
          },
          {
            path: "/login",
            element: withSuspense(<Login />),
            loader: loginLoader,
          },
          {
            path: "/logout",
            element: withSuspense(<Logout />),
            loader: logoutLoader,
          },
          {
            path: "/signup",
            element: withSuspense(<Signup />),
            loader: signupLoader,
          },
          {
            path: "/auth/google/drive/callback/",
            element: withSuspense(<GoogleDriveAuth />),
            loader: googleDriveAuthLoader,
          },
          {
            path: "/accounts/:provider/login/callback/",
            element: withSuspense(<SocialLogin />),
            loader: loginSocialLoader,
          },
          {
            path: "/forgot-password",
            element: withSuspense(<PasswordForgotten />),
            loader: passwordForgottenLoader,
          },
          {
            path: "/o/authorize",
            element: withSuspense(<OAuthScreen />),
            loader: oauthScreenLoader,
          },
          {
            path: "/settings",
            element: withSuspense(<AccountView />),
            loader: accountLoader,
          },
          {
            path: "/banned",
            element: withSuspense(<BannedView />),
            loader: bannedViewLoader,
          },
          {
            path: "/slack/callback",
            element: withSuspense(<SlackCallback />),
            loader: slackCallbackLoader,
          },
          {
            path: "/agents",
            element: withSuspense(<AgentView />),
            loader: homeLoader,
          },
          {
            path: "/workflows", // for legacy
            element: withSuspense(<AgentView />),
            loader: homeLoader,
          },
          {
            path: "/agents/new",
            element: withSuspense(<NewAgentView />),
            loader: newAgentLoader,
          },
          {
            path: "/agents/:workflow_uuid",
            element: withSuspense(<AgentView />),
            loader: agentLoader,
          },
          {
            path: "/workflow/:workflow_uuid", // for legacy
            element: withSuspense(<AgentView />),
            loader: homeLoader,
          },
          {
            path: "/team",
            element: withSuspense(<TeamView />),
            loader: teamsViewLoader,
          },
          {
            path: "/upgrade",
            element: withSuspense(<Loading />),
            loader: redirectPlanLoader,
          },
          {
            path: "/teams/:team_id/upgrade",
            element: withSuspense(<UpgradePlanView />),
            loader: upgradePlanLoader,
          },
          {
            path: "/upgrade-success",
            element: withSuspense(<UpgradePlanThankYou />),
            loader: upgradePlanThankYouLoader,
          },
        ],
      },
    ],
  },
]);

Sentry.init({
  dsn: "https://75afcc84cd2eaab239ac8f7b5570df28@o4504827057537024.ingest.sentry.io/4506003090046976",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.MODE == "production",
});

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

export default function App() {
  const isMobile = useMobile();

  if (
    isMobile &&
    window.location.pathname !== "/country-unavailable" &&
    !window.location.pathname.includes("/signup") &&
    !window.location.pathname.includes("/login")
  ) {
    return <MobileFallbackView />;
  }
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <PostHogProvider
        apiKey={import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY}
        options={posthogoptions}
      >
        <React.Suspense fallback={<Loading />}>
          <AuthProvider>
            <EventProvider>
              <ToastContainer />
              <PanelProvider>
                <RouterProvider router={router} />
              </PanelProvider>
            </EventProvider>
          </AuthProvider>
        </React.Suspense>
      </PostHogProvider>
    </Sentry.ErrorBoundary>
  );
}

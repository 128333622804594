import { Loading } from "@/components/Loading";
import WorkflowResultExplorer from "@/components/WorkflowResultExplorer";
import MarconipyApi from "@/utils/marconipyApi";
import { Tool, Workflow, WorkflowMessage, WorkflowRun } from "@/utils/types";
import { useEffect, useState } from "react";

type WorkflowRunProps = {
  workflow_run: WorkflowRun;
};

const WorkflowRunMessagesPartialView = ({ workflow_run }: WorkflowRunProps) => {
  const [workflow, setWorkflow] = useState<Workflow | null>(null);
  const [workflowRun, setWorkflowRun] = useState<WorkflowRun | null>(null);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<WorkflowMessage[]>([]);
  const [tools, setTools] = useState<Tool[]>([]);

  useEffect(() => {
    const loadAllTools = async () => {
      if (!workflow) return;
      const ftools = (await MarconipyApi.getAllTools(workflow.uuid)) as any as {
        tools: Tool[];
      };
      setTools(ftools.tools);
    };
    loadAllTools();
  }, [workflow]);

  useEffect(() => {
    const getWorkflowAndMessages = async () => {
      const fworkflow = (await MarconipyApi.getWorkflow(
        workflow_run.workflow.uuid,
      )) as any as { workflow: Workflow };
      setWorkflow(fworkflow.workflow);
      const fworkflowrun = (await MarconipyApi.getWorkflowRun(
        workflow_run.uuid,
      )) as any as { workflow_run: WorkflowRun };
      const messagesObj = (await MarconipyApi.getWorkflowRunMessages(
        workflow_run.uuid,
      )) as any as { messages: WorkflowMessage[] };
      setMessages(messagesObj.messages);
      setWorkflowRun(fworkflowrun.workflow_run);
      setLoading(false);
    };
    getWorkflowAndMessages();
  }, [workflow_run.uuid, workflow_run.workflow.uuid]);

  if (!workflowRun) return <Loading />;

  return (
    <div className="mb-8 max100vh">
      {loading && (
        <div className="flex justify-center">
          <Loading />
        </div>
      )}
      {!loading && (
        <WorkflowResultExplorer
          messages={messages}
          tools={tools}
          artefact={workflowRun?.artefact}
        />
      )}
    </div>
  );
};

export default WorkflowRunMessagesPartialView;

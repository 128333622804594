type MessageHandler = (data: any) => void;

class WebSocketService {
  private socket: WebSocket | null = null;
  private messageHandlers: MessageHandler[] = [];
  private reconnectInterval: number = 5000; // 5 seconds
  private reconnectTimer: number | null = null;
  private workflowId: string | null = null;

  static baseUrl = (() => {
    switch (import.meta.env.MODE) {
      case "production":
        return "https://ws.tailortask.ai";
      default:
        return "http://localhost:8001";
      // return "https://bkn.tailortask.ai";
    }
  })();

  connect(workflowId: string) {
    this.workflowId = workflowId;
    return this.connectSocket();
  }

  private connectSocket() {
    const url = new URL(WebSocketService.baseUrl);
    const wsProtocol = url.protocol === "https:" ? "wss:" : "ws:";
    const wsUrl = `${wsProtocol}//${url.host}/ws/chat/${this.workflowId}/`;

    console.log(
      `Connecting to WebSocket ${wsUrl} for workflow ${this.workflowId}`,
    );

    this.socket = new WebSocket(wsUrl);

    this.socket.onopen = () => {
      console.log("WebSocket connected");
      this.emit("connect");
      this.clearReconnectTimer();
    };

    this.socket.onclose = () => {
      console.log("WebSocket disconnected");
      this.emit("disconnect");
      if (this.workflowId) {
        this.scheduleReconnect();
      }
    };

    this.socket.onmessage = (event) => {
      console.log("WebSocket message received");
      const data = JSON.parse(event.data);
      this.emit("message", data);
    };

    return this;
  }

  private scheduleReconnect() {
    if (!this.reconnectTimer) {
      const capturedWorkflowId = this.workflowId;
      this.reconnectTimer = window.setTimeout(() => {
        this.reconnectTimer = null;
        if (capturedWorkflowId != null) {
          console.log("Attempting to reconnect...");
          this.connectSocket();
        }
      }, this.reconnectInterval);
    }
  }

  private clearReconnectTimer() {
    if (this.reconnectTimer) {
      window.clearTimeout(this.reconnectTimer);
      this.reconnectTimer = null;
    }
  }

  disconnect() {
    this.clearReconnectTimer();
    this.workflowId = null;
    if (this.socket) {
      this.socket.close();
    }
  }

  send(message: string) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ type: "new_message", message }));
    } else {
      console.error("WebSocket is not connected.");
    }
  }

  on(event: string, callback: MessageHandler) {
    this.messageHandlers.push((data) => {
      if (data.type === event || event === "message") {
        callback(data);
      }
    });
  }

  off(event: string, callback: MessageHandler) {
    this.messageHandlers = this.messageHandlers.filter(
      (handler) => handler !== callback,
    );
  }

  private emit(event: string, data?: any) {
    const message = { type: event, ...data };
    this.messageHandlers.forEach((handler) => handler(message));
  }
}

export default WebSocketService;

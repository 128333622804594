import React from "react";
import ReactDOM from "react-dom";

interface MainPanelProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: "sm" | "md" | "lg";
}

const MainPanel: React.FC<MainPanelProps> = ({
  open: isOpen,
  onClose,
  children,
  size = "md",
}) => {
  if (!isOpen) return null;

  const sizeClasses = {
    sm: "sm:w-4/6 md:w-[300px] lg:w-[400px]",
    md: "sm:w-5/6 md:w-[400px] lg:w-[500px]",
    lg: "sm:w-5/6 md:w-[500px] lg:w-[600px]",
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-20">
      {/* Backdrop */}
      <div
        className="absolute inset-0 bg-black opacity-50 transition-opacity duration-300 ease-in-out"
        onClick={onClose}
      />

      {/* Main Panel */}
      <div className={`absolute inset-y-0 left-0 w-full ${sizeClasses[size]} bg-white dark:bg-zinc-800 shadow-lg transform transition-transform duration-300 ease-in-out translate-x-0`}>
        <div className="flex flex-col h-full">
          <div className="h-full flex-grow overflow-auto">{children}</div>
        </div>
      </div>
    </div>,
    document.body,
  );
};

export default MainPanel;

import MarconipyApi from "@/utils/marconipyApi";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import { redirect, useNavigate } from "react-router-dom";
import successAnimation from "./../lottie/icons8-firework.json";

export default function UpgradePlanThankYou() {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    posthog.capture("upgrade_plan_success");

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        const newProgress = oldProgress + 1;
        if (newProgress === 100) {
          clearInterval(timer);
          navigate("/team");
        }
        return newProgress;
      });
    }, 30);

    return () => clearInterval(timer);
  }, [navigate, posthog]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg text-center">
        <div className="mb-4 text-primary">
          <Lottie
            loop
            animationData={successAnimation}
            play
            style={{ width: 150, height: 150, margin: "0 auto" }}
          />
        </div>
        <h1 className="text-3xl font-bold text-green-600 mb-4 flex items-center justify-center">
          Payment Successful!
        </h1>
        <p className="text-gray-600 mb-6">
          Thank you! You will be redirected to your team page shortly.
        </p>
        <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
          <div
            className="bg-green-600 h-2.5 rounded-full transition-all duration-300 ease-out"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
}

export async function loader({ params }: { params: any }) {
  try {
    //check if the user is already logged in
    const loggedIn = MarconipyApi.isAuth();
    if (!loggedIn) {
      console.log("User is not logged in");
      return redirect("/login");
    }
    return null;
  } catch (e: any) {
    console.log("error, check if waitlist");
    console.log(e.response.data);
    if (e.json && e.json.detail && e.json.detail.includes("waitlist")) {
      return redirect("/waitlist");
    }
    if (e.response && e.response.data && e.response.data.includes("Banned")) {
      return redirect("/banned");
    }
    throw e;
  }
}

import { Button } from "@/components/design-system/button";
import MarconipyApi from "@/utils/marconipyApi";
import { OutputChannel, Workflow } from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import {
  RiAddLargeFill,
  RiCheckFill,
  RiDeleteBinFill,
  RiLoader2Fill,
} from "react-icons/ri";
import { Label } from "./design-system/fieldset";
import { Switch, SwitchField } from "./design-system/switch";
import Tooltip from "./styled/Tooltip";

type OutputChannelZapierProps = {
  workflow: Workflow;
  output_channel_uuid?: string;
  onRefresh?: () => void;
};

export function OutputChannelZapier({
  workflow,
  output_channel_uuid,
  onRefresh,
}: OutputChannelZapierProps) {
  const posthog = usePostHog();
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [anychangesMade, setAnyChangesMade] = useState(false);
  const [channel_uuid, setChannelUuid] = useState(output_channel_uuid);

  const handleToggle = () => {
    setIsActive(!isActive);
    handleSave(!isActive);
  };

  const loadOutputChannel = async () => {
    if (output_channel_uuid) {
      const fchannels = (await MarconipyApi.outputChannels(
        workflow.uuid,
      )) as any as { output_channels: OutputChannel[] };
      const fchannel = fchannels.output_channels.find(
        (c) => c.uuid === output_channel_uuid,
      );
      if (!fchannel) return;
      setIsActive(fchannel.active);
    }
    setIsLoading(false);
  };

  const deleteOutputChannel = async () => {
    if (!output_channel_uuid) return;
    posthog.capture("workflowEdit: delete output channel", {
      source: "workflow output channels",
      type: "zapier",
    });
    await MarconipyApi.deleteOutputChannel(workflow.uuid, output_channel_uuid);
    onRefresh && onRefresh();
  };

  const handleSave = async (force_active?: boolean) => {
    let active = isActive;
    if (force_active !== undefined) {
      active = force_active;
    }
    if (active) {
      posthog.capture("workflowEdit: activate output channel", {
        source: "workflow output channels",
        type: "zapier",
      });
    } else {
      posthog.capture("workflowEdit: deactivate output channel", {
        source: "workflow output channels",
        type: "zapier",
      });
    }
    if (!output_channel_uuid) {
      const res = (await MarconipyApi.createOutputChannel(
        workflow.uuid,
      )) as any as { output_channel: OutputChannel };
      output_channel_uuid = res.output_channel.uuid;
      setChannelUuid(output_channel_uuid);
    }
    await MarconipyApi.editOutputChannel(workflow.uuid, output_channel_uuid, {
      active: active,
      channel_type: "zapier",
    });
    setAnyChangesMade(false);
    onRefresh && onRefresh();
  };

  useEffect(() => {
    loadOutputChannel();
  }, [workflow.uuid, output_channel_uuid]);

  return (
    <div className="">
      <div className="flex gap-4 my-4 items-start">
        <SwitchField>
          <Label className="font-semibold flex flex-row gap-1">
            <img
              src="/zapier_transparent.png"
              className="w-8 mr-2"
              alt="Zapier logo"
            />{" "}
            Get a Zapier
          </Label>
          {/* <Description>
            <span className="text-sm mb-2">
              You will get your result through Zapier. Run this task to try it
              out.
            </span>
          </Description> */}
          <Switch onChange={() => handleToggle()} checked={isActive} />
        </SwitchField>
        {/* <label htmlFor="triggerToggle" className="ml-4 font-bold"></label> */}
        {channel_uuid && isActive && (
          <div className="ml-4">
            <Tooltip content="Delete Zapier result setting">
              <Button variant="secondary" onClick={deleteOutputChannel}>
                <RiDeleteBinFill />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="mt-4 flex items-center text-sm mb-2">
        {isActive ? (
          <>
            <RiCheckFill className="mr-1" />
            Channel is active
          </>
        ) : (
          <>
            <RiLoader2Fill className="mr-1" />
            Channel is inactive
          </>
        )}
      </div>

      {isActive && (
        <div className="mt-4 flex flex-col items-start flex-grow">
          <p className="mb-2">
            To use this channel in Zapier, you can create a Zap that will
            trigger every time this workflow completes.{` `}
          </p>
          <Button
            href="https://zapier.com/developer/public-invite/209344/fa1a531349174d543557214a6e6bf28b/"
            variant="secondary"
            target="_blank"
          >
            <RiAddLargeFill />
            Create a Zap with TailorTask
          </Button>
        </div>
      )}
    </div>
  );
}

import { Button } from "@/components/design-system/button";
import { Divider } from "@/components/design-system/divider";
import SourceItemRowItem from "@/components/SourceItemRowItem";
import { IconsUtils } from "@/utils/Icons";
import MarconipyApi from "@/utils/marconipyApi";
import { TextFrontend } from "@/utils/Text";
import { SourceItem, SourceSet } from "@/utils/types";
import React, { useEffect, useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import { toast } from "react-toastify";

interface SourceSetInformationProps {
  sourceset: SourceSet;
  showAddRemoveButton?: boolean;
  added?: boolean;
  functionToAddButton: (uuid: string) => void;
  functionToRemoveButton: (uuid: string) => void;
  onRefresh?: () => void;
}

export const SourceSetInformation: React.FC<SourceSetInformationProps> = ({
  sourceset,
  showAddRemoveButton = false,
  added = false,
  functionToAddButton,
  functionToRemoveButton,
  onRefresh,
}) => {
  const [currentSourceSet, setCurrentSourceSet] = useState(sourceset);
  const [isRemoved, setIsRemoved] = useState(false);

  useEffect(() => {
    const retrieveInfo = async () => {
      try {
        const data = (await MarconipyApi.getSourceSet(
          currentSourceSet.uuid,
        )) as any as Record<string, SourceSet>;
        setCurrentSourceSet(data.sourceset);
      } catch (error) {
        console.error("Error getting sourceset:", error);
      }
    };
    retrieveInfo();
  }, [currentSourceSet.uuid]);

  const [removeCTA, setRemoveCTA] = useState("Remove");
  const removeAction = () => {
    setRemoveCTA("Removing...");
    functionToRemoveButton(currentSourceSet.uuid);
    setRemoveCTA("Removed");
    setIsRemoved(true);
    toast.warn(`${currentSourceSet.name} removed`, {
      position: "bottom-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    onRefresh && onRefresh();
  };

  const Icon = IconsUtils.fromSourceSetTypeToIcon(sourceset.type);
  return (
    <div className="text-left flex flex-col gap-4 dark:text-white">
      {currentSourceSet.description !== "default" && (
        <p>{currentSourceSet.description}</p>
      )}
      <div className="flex gap-2">
        <div className="truncate">
          <Icon />
        </div>
        <small>{TextFrontend.humanUrl(currentSourceSet.rss_url)}</small>
      </div>
      {showAddRemoveButton && (
        <div className="ml-auto">
          {added ? (
            <Button
              variant="primary"
              onClick={removeAction}
              disabled={isRemoved}
            >
              <RiCloseFill />
              {removeCTA}
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={() => functionToAddButton(currentSourceSet.uuid)}
            >
              Add Publication
            </Button>
          )}
        </div>
      )}
      {currentSourceSet.sourceitems &&
        currentSourceSet.sourceitems?.length > 0 && (
          <>
            <Divider />
            <div className="flex flex-col gap-4">
              <p className="font-semibold font-sans">Recently published</p>
              <div className="flex flex-col gap-2">
                {currentSourceSet.sourceitems?.map(
                  (item: SourceItem, index) => (
                    <span key={index}>
                      {index < 10 && (
                        <SourceItemRowItem key={index} sourceitem={item} />
                      )}
                      {index == 10 && (
                        <>
                          <p className="text-sm opacity-80">
                            {(
                              currentSourceSet.sourceitems?.length ?? 10 - 10
                            ).toString()}{" "}
                            more
                          </p>
                        </>
                      )}
                    </span>
                  ),
                )}
              </div>
            </div>
          </>
        )}
    </div>
  );
};

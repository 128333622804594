import { Button } from "@/components/design-system/button";
import { Divider } from "@/components/design-system/divider";
import SearchResult from "@/components/SearchResult";
import EditTopicView from "@/screens/partials/EditTopicView";
import MarconipyApi from "@/utils/marconipyApi";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import {
  RiBook2Fill,
  RiBookLine,
  RiCloseFill,
  RiFileWarningLine,
  RiLoader2Fill,
} from "react-icons/ri";
import { toast } from "react-toastify";
import { Topic, Workflow } from "utils/types";

type SelectSourceSetsWizardProps = {
  buttonAction?: () => void;
  workflow: Workflow;
  tool_key: string;
  onRefresh?: () => void;
};

const SelectSourceSetsWizard = ({
  buttonAction,
  workflow,
  tool_key,
  onRefresh,
}: SelectSourceSetsWizardProps) => {
  const posthog = usePostHog();
  const [query, setQuery] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Topic[]>([]);
  const [searchInputIsFocused, setSearchInputIsFocused] =
    useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [showFindingSuggestionsModal, setShowFindingSuggestionsModal] =
    useState<boolean>(false);
  const [suggestionsLoading, setSuggestionsLoading] = useState<string>("");
  const [justaddedtopic, setJustaddedtopic] = useState<Topic | null>(null);
  const [refreshMosaic, setRefreshMosaic] = useState<boolean>(true);
  const [isSearchingNewTopic, setIsSearchingNewTopic] =
    useState<boolean>(false);

  useEffect(() => {
    setRefreshMosaic(false);
  }, [refreshMosaic]);

  useEffect(() => {
    setSearchInputIsFocused(true);
  }, [workflow.uuid]);

  const addTopic = async function (topic: Topic) {
    setQuery("");
    setSuggestions([]);
    setSearchInputIsFocused(false);
    setJustaddedtopic(topic);
    try {
      await MarconipyApi.addInput(
        workflow.uuid,
        tool_key,
        "topic",
        topic.uuid,
        {},
      );
      toast.success(`${topic.name} added!`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      posthog.capture("workflowEdit: added topic", {
        source: "select source sets partial view",
        mode_action: "topic",
      });
      onRefresh && (await onRefresh());
      setSuggestionsLoading("loading");
      setJustaddedtopic(topic);
      setRefreshMosaic(true);
    } catch (error) {
      console.error("Error adding topic:", error);
      toast.error("An error occurred while adding the topic.");
    }
  };
  const removeTopic = async function () {
    setQuery("");
    setSuggestions([]);
    setSearchInputIsFocused(false);
    setJustaddedtopic(null);
    setRefreshMosaic(true);
    onRefresh && (await onRefresh());
  };

  const searchAutocomplete = async function (squery: string) {
    setJustaddedtopic(null);
    setShowFindingSuggestionsModal(false);
    setIsSearching(true);
    setQuery(squery);
    if (squery.length < 3) {
      setSuggestions([]);
      setIsSearching(false);
      return;
    }
    let results = (await MarconipyApi.searchTopicAutocomplete(
      squery,
    )) as any as { topics: Topic[] };
    setSuggestions(results.topics);
    setIsSearching(false);
  };

  const handleAddNewTopic = async () => {
    setIsSearchingNewTopic(true);
    try {
      const newTopic = (await MarconipyApi.searchTopic(query)) as any as {
        topic: Topic;
      };
      if (newTopic) {
        addTopic(newTopic.topic);
      } else {
        toast.error("Unable to add the topic. Please try again.");
      }
    } catch (error) {
      console.error("Error adding new topic:", error);
      toast.error("An error occurred while adding the topic.");
    } finally {
      setIsSearchingNewTopic(false);
    }
  };

  const searchWasFocused = async function () {
    setSearchInputIsFocused(true);
  };
  const searchWasoutOfFocus = async function () {
    setSuggestions([]);
    setSearchInputIsFocused(false);
    setQuery("");
  };

  const handleSourceSetSubmit = async function (e: any) {
    e.preventDefault();
    // await searchAndAddSourceSet({ name: query, uuid: "temp" } as SourceSet);
    // setQuery("");
  };

  useEffect(() => {
    if (!showFindingSuggestionsModal) {
      setSuggestionsLoading("");
    }
  }, [showFindingSuggestionsModal]);

  return (
    <div className="text-black dark:text-white">
      {suggestionsLoading != "done" && (
        <div className="flex flex-col gap-4">
          <p className="mt-2 dark:text-white">
            TailorTask will suggest you social accounts, blogs, or newspapers
            based on a keyword.
          </p>
          <form onSubmit={handleSourceSetSubmit}>
            <div className="flex relative mt-4">
              <DebounceInput
                type="text"
                value={query}
                placeholder="🔎 Farm equipment..."
                onChange={(e) => searchAutocomplete(e.target.value)}
                onFocus={searchWasFocused}
                autoFocus={true}
                className="border border-gray rounded-md py-1 px-2 w-full dark:bg-black dark:text-white"
              />
              {searchInputIsFocused && (
                <span className="absolute flex p-2 items-center inset-y-0 right-0 cursor-pointer hover:text-primary">
                  <RiCloseFill onClick={searchWasoutOfFocus} className="" />
                </span>
              )}
            </div>
          </form>
          <div className="max-h-32 overflow-y-auto">
            {(suggestions.length > 0 || query?.length > 0) && (
              <div className="flex flex-col my-2 dark:text-white">
                {suggestions?.map((topic) => {
                  return (
                    <SearchResult
                      key={topic.uuid}
                      value={topic}
                      icon={<RiBook2Fill />}
                      callback={addTopic}
                    />
                  );
                })}
                {query?.length > 2 &&
                  !isSearching &&
                  !isSearchingNewTopic &&
                  suggestions.length == 0 && (
                    <SearchResult
                      key={query + "new"}
                      value={{
                        name: query,
                        uuid: "temp",
                      }}
                      icon={<RiBookLine />}
                      callback={handleAddNewTopic}
                    />
                  )}
              </div>
            )}
            {searchInputIsFocused &&
              suggestions.length == 0 &&
              !isSearchingNewTopic &&
              query == "" && (
                <div className="flex flex-col align-center text-center mt-8">
                  <h2 className="font-sans text-base">Add a keyword...</h2>
                </div>
              )}
          </div>
          {/* TODO:  we should show the following also if none of the suggestions exactly matches the user's query */}
        </div>
      )}
      <div className={showFindingSuggestionsModal ? "" : "hidden"}>
        {suggestionsLoading == "" && (
          <>
            <br />
            <Divider />
            <br />
            <h2 className="mt-2">Finding content suggestions...</h2>
          </>
        )}
        {suggestionsLoading == "loading" && (
          <>
            <h2 className="mt-2">Loading...</h2>
            <div className="p-4 flex">
              <RiLoader2Fill className="animate-spin text-xl mr-4" />
              <p className="opacity-70">
                Finding content suggestions for {query} ...
              </p>
            </div>
          </>
        )}
        {suggestionsLoading == "done" && (
          <>
            <h2 className="mt-2">Done! This topic was added:</h2>
            <br />
            <br />
            {justaddedtopic && (
              <>
                <Divider />
                <div className="flex my-2">
                  <div className="flex flex-col">
                    <p className="ml-2 dark:text-white">
                      {justaddedtopic?.name}
                    </p>
                  </div>
                </div>
                <Divider />
              </>
            )}
            <div className="flex gap-2 mt-4 text-left">
              <Button
                onClick={function () {
                  setSuggestionsLoading("");
                  setShowFindingSuggestionsModal(false);
                }}
                variant="primary"
              >
                Add more
              </Button>
              <Button
                onClick={function () {
                  setSuggestionsLoading("");
                  buttonAction && buttonAction();
                }}
                variant="secondary"
              >
                Close
              </Button>
            </div>
          </>
        )}
        {suggestionsLoading == "noresults" && (
          <div className="flex flex-col gap-2 text-left">
            <div className="flex items-center gap-2">
              <RiFileWarningLine className="icon" />
              <p>
                {` `}No sources were found for <strong>{query}</strong>
                <br />
              </p>
            </div>
            <p>
              You can{" "}
              <a
                href="#"
                onClick={function () {
                  setSuggestionsLoading("");
                }}
              >
                try with a different topic
              </a>{" "}
              or{" "}
              <a
                href="mailto:info@tailortask.ai"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                get in touch with our team
              </a>
            </p>
          </div>
        )}
        <br />
      </div>
      {justaddedtopic && (
        <div className="flex flex-col gap-2 my-4">
          <p className="font-bold">{justaddedtopic.name} added!</p>
          <EditTopicView
            topic={justaddedtopic}
            workflow={workflow}
            tool_key={tool_key}
            onRemove={removeTopic}
            onRefresh={onRefresh}
          />
        </div>
      )}
      {/* <>
        <SourceSetMosaic
          workflow={workflow}
          showSourcesets={false}
          tool_key={tool_key}
          onRefresh={mosaicRefresh}
          refreshWhen={refreshMosaic}
          showSuggestedSourcesets={false}
          maxSourcesets={5}
        />
      </> */}
    </div>
  );
};

export default SelectSourceSetsWizard;

import { Button } from "@/components/design-system/button";
import { Divider } from "@/components/design-system/divider";
import { useWorkflows } from "@/contexts/WorkflowsContext";
import MarconipyApi from "@/utils/marconipyApi";
import { Organization, Plan, PlanTypeDetails } from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import {
  RiLoader2Fill,
  RiRocket2Fill,
  RiRocketLine,
  RiRunFill,
  RiTestTubeFill,
} from "react-icons/ri";
import { Navigate } from "react-router";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "./design-system/dialog";
import Label from "./styled/Label";
import Tooltip from "./styled/Tooltip";

type PlanStatusComponentProps = {
  workflow_uuid: string;
  team_uuid: string;
  hide_dry_runs?: boolean;
  hide_full_runs?: boolean;
  extended_labels?: boolean;
};

export function PlanStatusComponent(props: PlanStatusComponentProps) {
  const { workflow_uuid } = props;
  const posthog = usePostHog();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState<PlanTypeDetails | null>(null);
  const [navigateTo, setNavigateTo] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const { workflowWasRefreshed } = useWorkflows();

  const handleUpgrade = async () => {
    posthog.capture("UpgradeView: Arriving from", {
      source: "plan status component",
    });
    setNavigateTo("/teams/" + props.team_uuid + "/upgrade");
  };

  useEffect(() => {
    const getDetails = async () => {
      setLoading(true);
      posthog.capture("planStatus: get details", {
        source: "plan status component",
      });
      try {
        const response = (await MarconipyApi.planLimitPerWorkflow(
          workflow_uuid,
        )) as any as {
          plan: Plan;
          organization: Organization;
          details: PlanTypeDetails;
        };
        let fdetails = response.details;
        fdetails.subjective_dry_run_limit_left =
          response.details?.dry_run_limit -
          response.organization.dry_runs_today;
        fdetails.subjective_run_limit_left =
          response.details?.run_limit - response.organization.runs_today;
        setDetails(fdetails);
      } catch (error) {
        console.error(error);
        toast.error("Failed to get details");
      }
      setLoading(false);
    };
    getDetails();
  }, [workflow_uuid, workflowWasRefreshed, posthog]);

  return (
    <div>
      {navigateTo && <Navigate to={navigateTo} />}
      {props.extended_labels && <Label>Available:</Label>}
      <div className="flex items-center space-x-2 text-black dark:text-white">
        {loading && <RiLoader2Fill className="animate-spin mr-2" />}
        {details && (
          <div
            className="flex items-center space-x-2 hover:cursor-pointer"
            onClick={() => setShowModal(true)}
          >
            {!props.hide_dry_runs && (
              <Tooltip
                content={`${details.subjective_dry_run_limit_left} dry runs left`}
              >
                <div className="flex items-center">
                  <RiTestTubeFill
                    className={
                      details.subjective_dry_run_limit_left != 0
                        ? "text-black dark:text-white"
                        : "text-red"
                    }
                  />
                  {props.extended_labels && <span>Dry runs: </span>}
                  <span className="p-1">
                    {details.subjective_dry_run_limit_left}
                  </span>
                </div>
              </Tooltip>
            )}
            {!props.hide_full_runs && (
              <Tooltip
                content={`${details.subjective_run_limit_left} runs left`}
              >
                <div className="flex items-center">
                  <RiRunFill
                    className={
                      details.subjective_run_limit_left &&
                      details.subjective_run_limit_left <= 0
                        ? "text-black dark:text-white"
                        : "text-red"
                    }
                  />
                  {props.extended_labels && <span>Runs: </span>}
                  <span className="p-1">
                    {details.subjective_run_limit_left}
                  </span>
                </div>
              </Tooltip>
            )}
          </div>
        )}
        {props.extended_labels && (
          <div className="">
            <a
              onClick={handleUpgrade}
              href="#"
              className="text-sm hover:underline opacity-50 hover:opacity-100"
            >
              <RiRocket2Fill className="mr-2" />
              Upgrade
            </a>
          </div>
        )}
      </div>
      <Dialog open={showModal} onClose={() => setShowModal(false)}>
        <DialogTitle>
          <span className="capitalize">{details?.id}</span>
          {` `}
          plan details
        </DialogTitle>
        <DialogDescription>
          <div className="flex items-center space-x-2">
            You are on the
            {` `}
            {/* <RiVipCrownFill className="text-black" /> */}
            <span className="text-lg font-semibold capitalize mx-2">
              {details?.id}
            </span>
            plan
          </div>
        </DialogDescription>
        <DialogBody>
          {/* <p className="text-sm mt-2">{details?.description}</p> */}
          <div className="flex items-center space-x-2 mt-4 text-black dark:text-white">
            <RiTestTubeFill
              className={
                details?.subjective_run_limit_left != 0
                  ? "text-black dark:text-white"
                  : "text-red"
              }
            />
            <span>Dry Runs</span>
          </div>
          <div className="flex flex-col ml-4 my-2 text-black dark:text-white">
            <div className="space-x-2 flex mb-2">
              <div className="px-2 bg-gray-100 mr-2 rounded-lg font-bold text-lg text-black">
                {details?.dry_run_limit}
              </div>
              <>{details?.id === "free" ? <> included</> : <> per day</>}</>
            </div>
            <div className="space-x-2 flex">
              <div className="px-2 bg-gray-100 mr-2 rounded-lg font-bold text-lg text-black">
                {details?.subjective_dry_run_limit_left}
              </div>
              <>{details?.id === "free" ? <> left</> : <> per day.</>}</>
            </div>
          </div>
          <br />
          <Divider />
          <br />
          <div className="flex items-center space-x-2 text-black dark:text-white">
            <RiRunFill
              className={
                details?.subjective_run_limit_left != 0
                  ? "text-black dark:text-white"
                  : "text-red"
              }
            />
            <span>Complete Runs</span>
          </div>
          <div className="flex flex-col ml-4 my-2 text-black dark:text-white">
            <div className="space-x-2 flex mb-2">
              <div className="px-2 bg-gray-100 mr-2 rounded-lg font-bold text-lg text-black">
                {details?.run_limit}
              </div>
              <>{details?.id === "free" ? <> included.</> : <> per day.</>}</>
            </div>
            <div className="space-x-2 flex">
              <div className="px-2 bg-gray-100 mr-2 rounded-lg font-bold text-lg text-black">
                {details?.subjective_run_limit_left}
              </div>
              <>{details?.id === "free" ? <> left.</> : <> left per day.</>}</>
            </div>
          </div>
        </DialogBody>
        <DialogActions>
          <Button variant="plain" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              setNavigateTo("/teams/" + props.team_uuid + "/upgrade")
            }
          >
            <RiRocketLine />
            Upgrade
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { OutputChannelEmail } from "@/components/OutputChannelEmail";
import { OutputChannelSlack } from "@/components/OutputChannelSlack";
import { OutputChannelZapier } from "@/components/OutputChannelZapier";
import { Divider } from "@/components/design-system/divider";
import ConfirmationButton from "@/components/styled/ConfirmationButton";
import MarconipyApi from "@/utils/marconipyApi";
import { OutputChannel, Workflow } from "@/utils/types";
import React, { useCallback, useEffect, useState } from "react";

type OutputChannelsProps = {
  workflow: Workflow;
  onWorkflowRefresh?: () => void;
  onOutputConfirmed?: (step: string) => void;
};

const OutputChannels: React.FC<OutputChannelsProps> = ({
  workflow,
  onWorkflowRefresh,
  onOutputConfirmed,
}) => {
  const [activeChannelNumber, setActiveChannelNumber] = useState(0);
  const [emailChannels, setEmailChannels] = useState<OutputChannel[]>([]);
  const [slackChannels, setSlackChannels] = useState<OutputChannel[]>([]);
  const [zapierChannels, setZapierChannels] = useState<OutputChannel[]>([]);

  const loadChannels = useCallback(async () => {
    const foutput_channels = (await MarconipyApi.outputChannels(
      workflow.uuid,
    )) as any as { output_channels: OutputChannel[] };
    setEmailChannels(
      foutput_channels.output_channels.filter(
        (oc) => oc.channel_type === "email",
      ),
    );
    setSlackChannels(
      foutput_channels.output_channels.filter(
        (oc) => oc.channel_type === "slack",
      ),
    );
    setZapierChannels(
      foutput_channels.output_channels.filter(
        (oc) => oc.channel_type === "zapier",
      ),
    );
    const activeChannels = foutput_channels.output_channels.filter(
      (oc) => oc.active,
    );
    setActiveChannelNumber(activeChannels.length);
  }, [workflow.uuid]);

  useEffect(() => {
    loadChannels();
  }, [loadChannels, workflow.uuid]);

  const handleConfirmOutput = async () => {
    onWorkflowRefresh && onWorkflowRefresh();
    onOutputConfirmed && onOutputConfirmed("output");
  };

  return (
    <div className="flex flex-col gap-4 overflow-hidden h-full">
      <div className="overflow-y-scroll">
        {emailChannels.map((channel) => (
          <OutputChannelEmail
            key={channel.uuid}
            workflow={workflow}
            output_channel_uuid={channel.uuid}
            onRefresh={loadChannels}
          />
        ))}
        {emailChannels.length === 0 && (
          <OutputChannelEmail workflow={workflow} onRefresh={loadChannels} />
        )}
        <Divider />
        {slackChannels.map((channel) => (
          <OutputChannelSlack
            key={channel.uuid}
            workflow={workflow}
            output_channel_uuid={channel.uuid}
            onRefresh={loadChannels}
          />
        ))}
        {slackChannels.length === 0 && (
          <OutputChannelSlack workflow={workflow} onRefresh={loadChannels} />
        )}
        <Divider />
        {zapierChannels.map((channel) => (
          <OutputChannelZapier
            key={channel.uuid}
            workflow={workflow}
            output_channel_uuid={channel.uuid}
            onRefresh={loadChannels}
          />
        ))}
        {zapierChannels.length === 0 && (
          <OutputChannelZapier workflow={workflow} onRefresh={loadChannels} />
        )}
      </div>
      <Divider />
      <ConfirmationButton
        workflow={workflow}
        tooltip="Confirm the output format and channel"
        type="output"
        onClick={() => {
          handleConfirmOutput();
        }}
        disabled={activeChannelNumber == 0}
        tooltip_disabled="Add at least one output channel to proceed"
      />
    </div>
  );
};

export default OutputChannels;

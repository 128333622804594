import { Button } from "@/components/design-system/button";
import { Input } from "@/components/design-system/input";
import Label from "@/components/styled/Label";
import { useAuth } from "@/contexts/AuthenticationContext";
import MarconipyApi from "@/utils/marconipyApi";
import { TeamInvite } from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SignInWithGoogle from "../SignInWithGoogle";
import { Link } from "../design-system/link";

type LoginFormProps = {
  onSignupRedirect: () => void;
  onForgottenPasswordRedirect: () => void;
  redirectToAfterLogin?: string;
};
export default function LoginForm({
  onSignupRedirect,
  onForgottenPasswordRedirect,
  redirectToAfterLogin,
}: LoginFormProps) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [justloggedin, setJustLoggedIn] = useState(false);
  const { updateAuth } = useAuth();
  const posthog = usePostHog();

  const [searchParams] = useSearchParams();
  const invite = searchParams.get("invite");

  useEffect(() => {
    const getInvite = async () => {
      if (invite) {
        const inviteObj = (await MarconipyApi.getInvite(
          invite,
        )) as any as TeamInvite;
        if (inviteObj.invited_email) {
          setUsername(inviteObj.invited_email);
        }
      }
    };
    if (searchParams.get("invite")) {
      getInvite();
    }
  }, [searchParams]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await MarconipyApi.login(username, password);
      await MarconipyApi.putProfile({});
      await updateAuth();
      // this capture event needs to happen after we have identified the user in updateAuth
      posthog?.capture("login");
      localStorage.removeItem("additionalMessage");
      window.location.reload();
    } catch (err: any) {
      let errors = err.response.data;
      for (let key of Object.keys(errors)) {
        setError(errors[key][0]);
        break;
      }
      setJustLoggedIn(false);
    }
  };

  return (
    <div className="flex flex-col">
      {!justloggedin && (
        <>
          <form onSubmit={handleSubmit} className="flex flex-col gap-2">
            <div>
              <Label>Email</Label>
              <Input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="youremail@domain.com"
                autoFocus
              />
            </div>
            <div>
              <Label>Password</Label>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Your password"
              />
            </div>
            {error && <small className="text-primary">{error}</small>}
            <Button variant="primary" type="submit">
              Login
            </Button>
          </form>
          <br />
          <SignInWithGoogle redirectUrl={redirectToAfterLogin} />
          <div className="flex flex-col gap-2 mt-4">
            <Button onClick={onSignupRedirect} variant="secondary">
              {"Don't have an account?"}
              <span className="text-primary pl-2">Sign up here.</span>
            </Button>
            <Button onClick={onForgottenPasswordRedirect} variant="secondary">
              Password forgotten?
            </Button>
          </div>
        </>
      )}
      {justloggedin && (
        <Button>
          <Link to="/">Success!</Link>
        </Button>
      )}
    </div>
  );
}

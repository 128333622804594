//visualizes the context of a workflow for the user to see
//it allows the user to open a modal and edit the context
//used inside the workflow view and in other places
import React, { useEffect, useState } from "react";
import ContextEditor from "./ContextEditor";

import { Button } from "@/components/design-system/button";
import { Workflow } from "@/utils/types";
import { RiEditFill, RiQuestionFill } from "react-icons/ri";
import SubchapterTitle from "./SubchapterTitle";
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm,
} from "./design-system/description-list";
import { Dialog, DialogBody, DialogTitle } from "./design-system/dialog";
import Tooltip from "./styled/Tooltip";

type QuestionAnswer = {
  id: string;
  question: string;
  answer: string;
};

type QuestionAnswerEditorProps = {
  workflow: Workflow;
  show_header?: boolean;
};

export const ContextComponent = ({
  workflow,
  show_header,
}: QuestionAnswerEditorProps) => {
  const [contextModal, setContextModal] = useState(false);

  const toggleModal = () => {
    setContextModal(!contextModal);
  };

  useEffect(() => {
    let qapairs = [
      {
        question: "Loading",
        answer: "Loading",
      },
    ];
    if (workflow.context?.qa_pairs) {
      qapairs = workflow.context.qa_pairs;
    }
    let newquestionanswers = [];
    for (let i = 0; i < qapairs.length; i++) {
      newquestionanswers.push({
        id: i.toString(),
        question: qapairs[i].question,
        answer: qapairs[i].answer,
      });
    }
    setQuestionAnswers(newquestionanswers);
  }, [workflow.context]);

  const [questionAnswers, setQuestionAnswers] = useState<QuestionAnswer[]>([
    { id: "1", question: "Loading?", answer: "Loading" },
    { id: "2", question: "Loading?", answer: "Loading" },
  ]);

  return (
    <div className="flex flex-col space-y-2">
      {show_header && (
        <div className="flex items-center justify-between">
          <SubchapterTitle title="Brief" />
          <Tooltip content="The brief helps your agent understand your specific needs and tailor your task to you. Learn more">
            <Button
              variant="secondary"
              onClick={() =>
                window.open(
                  "https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42?v=6053a8590d7a425f8e7ff3096f39e516&pvs=4",
                )
              }
            >
              <RiQuestionFill />
            </Button>
          </Tooltip>
        </div>
      )}
      <DescriptionList>
        {questionAnswers.map((qa, index) => (
          <React.Fragment key={index}>
            <DescriptionTerm>{qa.question}</DescriptionTerm>
            <DescriptionDetails>{qa.answer}</DescriptionDetails>
          </React.Fragment>
        ))}
      </DescriptionList>
      <div className="flex items-center justify-between">
        {!show_header && (
          <Tooltip content="The brief helps your agent understand your specific needs and tailor your task to you. Learn more">
            <Button
              variant="secondary"
              onClick={() =>
                window.open(
                  "https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42?v=6053a8590d7a425f8e7ff3096f39e516&pvs=4",
                )
              }
            >
              <RiQuestionFill />
            </Button>
          </Tooltip>
        )}
        <Tooltip content="Change this brief to improve the workflow">
          <Button variant="secondary" onClick={toggleModal}>
            <RiEditFill />
            Edit
          </Button>
        </Tooltip>
      </div>
      <Dialog open={contextModal} onClose={toggleModal} size="xl">
        <DialogTitle>Edit brief</DialogTitle>
        <DialogBody>
          <ContextEditor workflow={workflow} />
        </DialogBody>
      </Dialog>
    </div>
  );
};

import { Button } from "@/components/design-system/button";
import SignInWithGoogle from "@/components/SignInWithGoogle";
import MarconipyApi from "@/utils/marconipyApi";
import { Spreadsheet, Workflow } from "@/utils/types";
import { useEffect, useState } from "react";
import useDrivePicker from "react-google-drive-picker";
import { RiCloseLine, RiLoader2Line } from "react-icons/ri";

type SourceSetsPartialViewProps = {
  workflow: Workflow;
  tool_key: string;
  onRefresh?: () => void;
};

const SpreadsheetPartialView = ({
  workflow,
  tool_key,
  onRefresh,
}: SourceSetsPartialViewProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [connected, setConnected] = useState<boolean>(false);
  const [selectedSpreadsheet, setSelectedSpreadsheet] = useState<{
    id: string;
    name: string;
  } | null>(null);

  useEffect(() => {
    async function checkConnectionStatus() {
      const status = (await MarconipyApi.googleSheetsAuthStatus()) as any;
      setConnected(status.connected);
      setIsLoading(false);
    }
    checkConnectionStatus();
  }, []);

  useEffect(() => {
    localStorage.setItem("redirectUrl", window.location.href);
  }, []);

  useEffect(() => {
    if (!workflow.artefact || !workflow.artefact.inputs) return;

    const inputs = workflow.artefact.inputs;
    if (
      inputs.spreadsheet &&
      inputs.spreadsheet.length > 0 &&
      inputs.spreadsheet.find((s) => s.tool_key === tool_key)
    ) {
      const spreadsheet = inputs.spreadsheet.find(
        (s) => s.tool_key === tool_key,
      ) as { object: Spreadsheet };
      if (spreadsheet.object.spreadsheet_id) {
        setSelectedSpreadsheet({
          id: spreadsheet.object.spreadsheet_id,
          name: spreadsheet.object.name,
        });
      }
    }
  }, [tool_key, workflow]);

  const updateSelectedSpreadsheet = async (
    spreadsheet: {
      id: string;
      name: string;
    } | null,
  ) => {
    setSelectedSpreadsheet(spreadsheet);
    if (spreadsheet) {
      const response = (await MarconipyApi.addGoogleSpreadsheet(
        spreadsheet.id,
        spreadsheet.name,
      )) as any as Record<string, any>;
      const api_spreadsheet = response.spreadsheet;
      await MarconipyApi.addInput(
        workflow.uuid,
        tool_key,
        "spreadsheet",
        api_spreadsheet.uuid,
        {},
      );
    } else {
      await MarconipyApi.removeInput(workflow.uuid, "all", tool_key);
    }
    onRefresh?.();
  };
  const [openPicker, _] = useDrivePicker();

  const handleOpenPicker = async () => {
    const data = (await MarconipyApi.googleSheetsAuthStatus()) as any;
    openPicker({
      clientId: data.clientId,
      developerKey: "", //https://github.com/Jose-cd/React-google-drive-picker/issues/47
      viewId: "SPREADSHEETS",
      token: data.token, // pass oauth token in case you already have one
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: false,
      appId: data.appId,
      // customViews: customViewsArray, // custom view
      callbackFunction: async (data) => {
        if (data.action === "cancel") {
          console.log("User clicked cancel/close button");
        }
        if (data.action === "picked") {
          const file = data.docs[0]; // Get the first (and only) selected file
          await updateSelectedSpreadsheet({ id: file.id, name: file.name });
        }
      },
    });
  };

  return (
    <div className="flex flex-col gap-2">
      {isLoading && (
        <div className="flex gap-2 items-center w-full">
          <RiLoader2Line className="animate-spin" />
          <p>Loading...</p>
        </div>
      )}
      {!isLoading && (
        <div className="flex flex-row gap-1">
          {!connected ? (
            <SignInWithGoogle provider={"google/drive"} />
          ) : (
            <>
              <Button
                onClick={handleOpenPicker}
                variant={selectedSpreadsheet ? "secondary" : "primary"}
                className="grow"
              >
                {selectedSpreadsheet
                  ? selectedSpreadsheet.name + " (Click to change)"
                  : "Select Spreadsheet"}
              </Button>
            </>
          )}
          {selectedSpreadsheet && (
            <Button
              variant="plain"
              onClick={() => updateSelectedSpreadsheet(null)}
            >
              <RiCloseLine />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default SpreadsheetPartialView;

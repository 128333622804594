import React, { useEffect, useRef, useState } from "react";
import { RiArrowRightSFill } from "react-icons/ri";
import { Button } from "./button";

type FloatingMenuProps = {
  content: React.ReactNode;
  children: React.ReactNode;
};

export function FloatingSubMenu({ content, children }: FloatingMenuProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="relative group flex flex-col"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Button variant="plain">
        <div className="w-full flex flex-row flex-grow-1 items-center justify-between font-normal text-sm">
          <div className="flex gap-2 items-center">{content}</div>
          <RiArrowRightSFill
            className={`transition-transform duration-200 ${isOpen ? "transform rotate-90" : ""}`}
          />
        </div>
      </Button>
      {isOpen && (
        <div className="absolute left-full top-0 min-w-48 rounded-md shadow-lg bg-white dark:bg-black ring-1 ring-black ring-opacity-5">
          {React.Children.map(children, (child) => (
            <div>{child}</div>
          ))}
        </div>
      )}
    </div>
  );
}

type FloatingSubMenuItemProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

export const FloatingSubMenuItem: React.FC<FloatingSubMenuItemProps> = ({
  children,
  onClick,
}) => (
  <Button variant="plain" className="w-full" onClick={onClick}>
    <div className="w-full flex flex-row flex-grow-1 items-center justify-between text-sm font-normal">
      <div className="flex gap-2 items-center">{children}</div>
    </div>
  </Button>
);

export const FloatingMenu = ({
  content,
  children,
}: {
  content: React.ReactNode;
  children: React.ReactNode;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={menuRef}>
      <Button onClick={() => setIsMenuOpen(!isMenuOpen)} variant="plain">
        {content}
      </Button>
      {isMenuOpen && (
        <div className="z-10 absolute top-full left-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-black dark:text-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

import { useState } from "react";
import { RiBrainFill, RiBrainLine } from "react-icons/ri";
import Tooltip from "./Tooltip";

const ThinkingComponent = ({
  thinkingContent,
}: {
  thinkingContent: string[];
}) => {
  const [show, setShow] = useState(false);
  return (
    <div className="thinking-content flex">
      {show && (
        <div className="p-4 bg-primary-dark text-white dark:bg-white dark:text-black rounded">
          {thinkingContent.map((content, index) => (
            <p key={index} className="">
              {content}
            </p>
          ))}
        </div>
      )}
      <Tooltip content="Click to see thinking">
        {!show && (
          <RiBrainLine
            className="opacity-30 hover:cursor-pointer hover:opacity-100"
            onClick={() => setShow(!show)}
          />
        )}
        {show && (
          <RiBrainFill
            className="opacity-100 text-primary hover:cursor-pointer"
            onClick={() => setShow(!show)}
          />
        )}
      </Tooltip>
    </div>
  );
};

export default ThinkingComponent;

import { useState } from "react";
import { RiArrowDownSLine, RiArrowRightSLine } from "react-icons/ri";
import { Button } from "../design-system/button";

type AdvancedSettingsOpenerProps = {
  label: string;
  children: React.ReactNode;
};

export default function AdvancedSettingsOpener({
  label,
  children,
}: AdvancedSettingsOpenerProps) {
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col flex-wrap flex-grow items-start">
      <Button variant="plain" onClick={() => setOpen(!open)}>
        {open ? <RiArrowDownSLine /> : <RiArrowRightSLine />}
        <p>{label}</p>
      </Button>
      {open && children}
    </div>
  );
}

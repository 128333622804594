import SourceItemRowItem from "@/components/SourceItemRowItem";
import MarconipyApi from "@/utils/marconipyApi";
import { SourceItem } from "@/utils/types";
import { useEffect, useState } from "react";

type SourceItemsPartialViewProps = {
  sourceItemsUUIDs: string[];
};

export const SourceItemsPartialView = ({
  sourceItemsUUIDs: sourceitems_uuids,
}: SourceItemsPartialViewProps) => {
  const [sourceitems, setSourceItems] = useState<SourceItem[]>([]);

  useEffect(() => {
    const loadSourceItems = async () => {
      if (sourceitems_uuids.length === 0) {
        setSourceItems([]);
        return;
      }
      let sourceitemsObj = (await MarconipyApi.batchGetSourceItems(
        sourceitems_uuids,
      )) as any as Record<string, SourceItem[]>;
      setSourceItems(
        sourceitemsObj.sourceitems.sort(
          (a, b) =>
            new Date(b.published_at).getTime() -
            new Date(a.published_at).getTime(),
        ),
      );
    };
    loadSourceItems();
  }, [sourceitems_uuids]);

  return (
    <div className="flex flex-col gap-2 my-4">
      <h3 className="text-xl font-bold">References</h3>
      {sourceitems.map((sourceitem, index) => (
        <SourceItemRowItem
          key={sourceitem.uuid}
          sourceitem={sourceitem}
          index={index + 1}
        />
      ))}
    </div>
  );
};

import { TriggerEmail } from "@/components/TriggerEmail";
import { TriggerSchedule } from "@/components/TriggerSchedule";
import { TriggerSlack } from "@/components/TriggerSlack";
import { Button } from "@/components/design-system/button";
import { Divider } from "@/components/design-system/divider";
import ConfirmationButton from "@/components/styled/ConfirmationButton";
import Tooltip from "@/components/styled/Tooltip";
import { useWorkflows } from "@/contexts/WorkflowsContext";
import MarconipyApi from "@/utils/marconipyApi";
import { Trigger, Workflow } from "@/utils/types";
import { useCallback, useEffect, useState } from "react";
import { RiAddBoxFill } from "react-icons/ri";

type WorkflowTriggersProps = {
  workflow: Workflow;
  onWorkflowRefresh?: () => void;
  onTriggersConfirmed?: (step: string) => void;
};

const WorkflowTriggers = ({
  workflow,
  onWorkflowRefresh,
  onTriggersConfirmed,
}: WorkflowTriggersProps) => {
  const [triggers, setTriggers] = useState<Trigger[]>([]);
  const [scheduleTriggers, setScheduleTriggers] = useState<Trigger[]>([]);
  const [slackTriggers, setSlackTriggers] = useState<Trigger[]>([]);
  const [emailTriggers, setEmailTriggers] = useState<Trigger[]>([]);
  const { workflowWasRefreshed } = useWorkflows();

  const loadTriggers = useCallback(async () => {
    const triggers = (await MarconipyApi.workflowTriggers(
      workflow.uuid,
    )) as any as { triggers: Trigger[] };
    setTriggers(triggers.triggers);
    setScheduleTriggers(
      triggers.triggers.filter((trigger) => trigger.trigger_type === "time"),
    );
    setSlackTriggers(
      triggers.triggers.filter((trigger) => trigger.trigger_type === "slack"),
    );
    setEmailTriggers(
      triggers.triggers.filter((trigger) => trigger.trigger_type === "email"),
    );
  }, [workflow.uuid]);

  const newScheduleTrigger = async () => {
    (await MarconipyApi.createWorkflowTrigger(workflow.uuid, {
      trigger_type: "time",
      active: true,
      schedule_type: "daily",
      execution_time: "12:00",
    })) as any as { trigger: Trigger };
  };

  const handleAddScheduleTrigger = async () => {
    await newScheduleTrigger();
    await loadTriggers();
  };

  const handleConfirmTriggers = async () => {
    onWorkflowRefresh && onWorkflowRefresh();
    onTriggersConfirmed && onTriggersConfirmed("triggers");
  };

  useEffect(() => {
    loadTriggers();
  }, [loadTriggers, workflow.uuid, workflowWasRefreshed]);

  const activeTriggers = triggers.filter((trigger) => trigger.active);

  return (
    <div className="flex flex-col gap-4 overflow-hidden h-full">
      <div className="overflow-y-scroll">
        <div className="flex flex-col gap-2">
          {scheduleTriggers.length === 0 && (
            <TriggerSchedule workflow={workflow} onRefresh={loadTriggers} />
          )}
          {scheduleTriggers.map((trigger) => (
            <TriggerSchedule
              key={trigger.uuid}
              workflow={workflow}
              trigger_uuid={trigger.uuid}
              onRefresh={loadTriggers}
            />
          ))}
          <Tooltip content="Add another schedule trigger">
            <Button variant="secondary" onClick={handleAddScheduleTrigger}>
              <RiAddBoxFill />
            </Button>
          </Tooltip>
        </div>
        <Divider />
        {slackTriggers.map((trigger) => (
          <TriggerSlack
            key={trigger.uuid}
            workflow={workflow}
            trigger_uuid={trigger.uuid}
            onRefresh={loadTriggers}
          />
        ))}
        {slackTriggers.length === 0 && (
          <TriggerSlack workflow={workflow} onRefresh={loadTriggers} />
        )}
        <Divider />
        {emailTriggers.map((trigger) => (
          <TriggerEmail
            key={trigger.uuid}
            workflow={workflow}
            trigger_uuid={trigger.uuid}
            onRefresh={loadTriggers}
          />
        ))}
        {emailTriggers.length === 0 && (
          <TriggerEmail workflow={workflow} onRefresh={loadTriggers} />
        )}
      </div>
      <Divider />
      <ConfirmationButton
        workflow={workflow}
        tooltip="Confirm the triggers you have selected to proceed"
        type="triggers"
        onClick={handleConfirmTriggers}
        disabled={activeTriggers.length == 0}
        tooltip_disabled="Add at least one trigger to proceed"
      />
    </div>
  );
};

export default WorkflowTriggers;

import { Button } from "@/components/design-system/button";
import { DialogActions } from "@/components/design-system/dialog";
import SelectSourceSets from "@/components/SelectSourceSets";
import SelectSourceSetsWizard from "@/components/SelectSourceSetsWizard";
import SourceSetMosaic from "@/components/SourceSetMosaic";
import SourceSetsCSVUpload from "@/components/SourceSetsCSVUpload";
import SecondaryPanel from "@/components/styled/panels/SecondaryPanel";
import { NameConventions } from "@/utils/NameConventions";
import { EditMode, Workflow } from "@/utils/types";
import { useCallback, useEffect, useState } from "react";
import { RiLoader2Fill, RiSave2Fill } from "react-icons/ri";

type SourceSetsPartialViewProps = {
  workflow: Workflow;
  tool_key: string;
  onRefresh?: () => void;
};

const SourceSetsPartialView = ({
  workflow,
  tool_key,
  onRefresh,
}: SourceSetsPartialViewProps) => {
  const [showAllSourcesOverlayer, setShowAllSourcesOverlayer] = useState(false);
  const [showAllSourcesOverlayerInside, setShowAllSourcesOverlayerInside] =
    useState(false);
  //edit modes types can be: query; rss; twitter; user_following
  const [editModeType, setEditModeType] = useState<EditMode | null>(null);

  useEffect(() => {
    if (showAllSourcesOverlayer) {
      setTimeout(() => {
        setShowAllSourcesOverlayerInside(true);
      }, 50);
    } else {
      setShowAllSourcesOverlayerInside(false);
    }
  }, [showAllSourcesOverlayer]);

  const refreshWorkFlow = async () => {
    onRefresh && onRefresh();
  };

  const handleButton = useCallback(() => {
    onRefresh?.();
    setEditModeType(null);
  }, [onRefresh]);

  const fromEditoModeTypeToTitle = (type: EditMode | null) => {
    if (type == "social") return "Add social account";
    if (type == "rss") return "Add blog or website";
    if (type == "topic") return "Add keyword to monitor";
    if (type == "upload") return "Upload list of sources";
    if (type == "wizard") return "Suggest sources";
    return "Add source";
  };

  return (
    <div className="flex flex-col gap-2">
      <SourceSetMosaic
        workflow={workflow}
        tool_key={tool_key}
        editModeAction={(type) => setEditModeType(type)}
        onRefresh={refreshWorkFlow}
        seeMoreAction={() => setShowAllSourcesOverlayer(true)}
        showSuggestedSourcesets={true}
      />
      <SecondaryPanel
        open={editModeType != null}
        onClose={handleButton}
        title={fromEditoModeTypeToTitle(editModeType)}
      >
        {(editModeType == "social" ||
          editModeType == "rss" ||
          editModeType == "topic") && (
          <SelectSourceSets
            variant={editModeType}
            workflow={workflow}
            tool_key={tool_key}
            buttonAction={handleButton}
            onRefresh={refreshWorkFlow}
          />
        )}
        {editModeType == "wizard" && (
          <SelectSourceSetsWizard
            workflow={workflow}
            tool_key={tool_key}
            buttonAction={handleButton}
            onRefresh={refreshWorkFlow}
          />
        )}
        {editModeType == "upload" && (
          <SourceSetsCSVUpload
            workflow={workflow}
            tool_key={tool_key}
            onRefresh={refreshWorkFlow}
          />
        )}
        <SourceSetMosaic
          workflow={workflow}
          tool_key={tool_key}
          onRefresh={refreshWorkFlow}
          showSuggestedSourcesets={true}
          showTopics={true}
          maxSourcesets={100}
          maxSourcesetsPerCategory={100}
        />
        <DialogActions>
          <Button onClick={handleButton}>
            <RiSave2Fill />
            Save
          </Button>
        </DialogActions>
      </SecondaryPanel>
      <SecondaryPanel
        open={showAllSourcesOverlayer}
        onClose={() => setShowAllSourcesOverlayer(false)}
        title={`${NameConventions.toHuman(tool_key)} - All Sources`}
      >
        {!showAllSourcesOverlayerInside && (
          <RiLoader2Fill className="animate-spin" />
        )}
        {showAllSourcesOverlayerInside && tool_key && (
          <SourceSetMosaic
            workflow={workflow}
            tool_key={tool_key}
            onRefresh={refreshWorkFlow}
            showSuggestedSourcesets={true}
            showTopics={true}
            maxSourcesets={100}
            maxSourcesetsPerCategory={100}
          />
        )}
      </SecondaryPanel>
    </div>
  );
};

export default SourceSetsPartialView;

// src/contexts/AuthContext.tsx
import MarconipyApi from "@/utils/marconipyApi";
import { User } from "@/utils/types";
import Intercom from "@intercom/messenger-js-sdk";
import { usePostHog } from "posthog-js/react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

// Define the context types
interface AuthContextType {
  isAuth: boolean;
  updateAuth: () => Promise<void>;
  updateRequired: boolean;
  user: User | null;
}

// Create the context
const AuthContext = createContext<AuthContextType>({
  isAuth: false,
  updateAuth: () => Promise.resolve(),
  updateRequired: false,
  user: null,
});

// Custom hook to use the AuthContext
export const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};

const CLIENT_COMPATIBLE_VERSION = "2024-06-09";

// AuthProvider component
interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
}): JSX.Element => {
  const [isAuth, setIsAuth] = useState<boolean>(MarconipyApi.isAuth());
  const [user, setUser] = useState<User | null>(null);
  const [identified, setIdentified] = useState<boolean>(false); //only identify once
  const [updateRequired, setUpdateRequired] = useState<boolean>(false);
  const posthog = usePostHog();

  const identifyUser = useCallback(
    (user: User) => {
      if (user && user.uuid) {
        try {
          if ((user.email && user.email.includes("@test.it") || user.email && user.email.includes("@tailortask.ai") ) ) {
            posthog.opt_out_capturing();
            posthog.stopSessionRecording();
            return;
          }
          posthog.identify(user.uuid, {
            distinct_id: user.uuid,
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
            waitlist: user.waitlist,
          });
          Intercom({
            app_id: "r86yr64f",
            user_id: user.uuid, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
            name: user.first_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
            email: user.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
            // created_at: user.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
          });
          setIdentified(true);
        } catch (e) {
          console.warn("Error identifying user", e);
        }
      }
    },
    [posthog],
  );

  const updateAuth = async () => {
    setIsAuth(MarconipyApi.isAuth());
    if (MarconipyApi.isAuth()) {
      await getUser();
    }
  };

  const getUser = useCallback(async () => {
    const user = await MarconipyApi.getUserStatus();
    if (user) {
      setUser(user as User);
      if (user.client_compatible_version != CLIENT_COMPATIBLE_VERSION) {
        setUpdateRequired(true);
      }
      identifyUser(user as User);
    }
  }, [identifyUser]);

  useEffect(() => {
    if (isAuth && user == null) {
      getUser();
    }
  }, [getUser, isAuth, user]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        getUser();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [getUser]);

  useEffect(() => {
    if (user && posthog && !identified) {
      identifyUser(user);
    }
  }, [user, posthog, identified, identifyUser]);

  return (
    <AuthContext.Provider value={{ isAuth, updateAuth, updateRequired, user }}>
      {children}
    </AuthContext.Provider>
  );
};

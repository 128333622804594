import { AccordionItem } from "@/components/design-system/accordion";

export const UpgradeFAQSection = () => {
  const faqs = [
    {
      question: "What's included in the free plan?",
      answer:
        "The free plan includes the ability to plan and execute tasks, with 5 dry runs and 2 full runs included (lifetime). It's perfect for individuals or small teams just getting started with task automation.",
    },
    {
      question: "What is a dry run?",
      answer:
        "A dry run is a task performed by one of your agent in test mode. It will allow you to check the final result as well as the intermediate steps of the task. It is not counted in the daily limit of executions and it will not be sent to other tools as a result of its completion. For example, it will not send a publication request to Linkedin, or it will not save the document in Google Drive",
    },
    {
      question: "What is a full run?",
      answer:
        "A full run is a complete task performed by one of the agents you set up. It will be counted in the daily limit of executions and it will be sent to other tools as a result of its completion. For example, it will send a publication request to Linkedin, or it will save the document in Google Drive",
    },
    {
      question: "Can I upgrade or downgrade my plan at any time?",
      answer:
        "Yes, you can upgrade or downgrade your plan at any time. When you upgrade, you'll have immediate access to the new features. If you downgrade, the changes will take effect at the start of your next billing cycle.",
    },
    {
      question: "What payment methods do you accept?",
      answer:
        "We accept all major credit cards for payment. For Enterprise plans, we also offer invoicing options.",
    },
    {
      question: "Is there a long-term contract for paid plans?",
      answer:
        "No, there are no long-term contracts. Our Professional plan is billed monthly or annually, and you can cancel at any time.",
    },
    {
      question: "How do I unsubscribe? Will I get reimbursed?",
      answer:
        "You will be able to cancel in two clicks if you are not happy. We will reimburse you the prorated amount i.e. if you have used the Pro plan for 10 out of 30 days, we will reimburse you for the 2/3 you haven't used, or $66",
    },
    {
      question: "What kind of support do you offer?",
      answer:
        "We offer email support for Premium and Enterprise plans. Professional and Enterprise plans also have priority support with faster response times. Enterprise plans include dedicated account management.",
    },
  ];

  return (
    <div className="mt-16 mb-16 px-4 md:px-0">
      <h2 className="text-2xl font-semibold mb-8 text-center">
        Frequently Asked Questions
      </h2>
      <div className="max-w-3xl mx-auto space-y-4">
        {faqs.map((faq, index) => (
          <AccordionItem
            key={index}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </div>
    </div>
  );
};

import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
} from "@/components/design-system/alert";
import { Button } from "@/components/design-system/button";
import { Divider } from "@/components/design-system/divider";
import SkeletonElement from "@/components/SkeletonElement";
import SourceSetImage from "@/components/SourceSetImage";
import SourceSetTypeSelection from "@/components/SourceSetTypeSelection";
import Tooltip from "@/components/styled/Tooltip";
import MarconipyApi from "@/utils/marconipyApi";
import { SourceSet, Topic, Workflow } from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect, useState } from "react";
import { RiCloseFill, RiLoader2Line, RiSaveLine } from "react-icons/ri";

type EditTopicViewProps = {
  topic: Topic;
  workflow: Workflow;
  tool_key: string;
  onRemove: () => Promise<void>;
  onRefresh?: () => void;
  showDeleteButton?: boolean;
  saveButtonAction?: () => void;
};

export default function EditTopicView({
  topic,
  workflow,
  tool_key,
  onRemove,
  onRefresh,
  showDeleteButton,
  saveButtonAction = undefined,
}: EditTopicViewProps) {
  const posthog = usePostHog();
  const [loading, setLoading] = useState(true);
  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [sourcesets, setSourcesets] = useState<SourceSet[]>([]);
  const [sourcesetsLoading, setSourcesetsLoading] = useState(true);
  const [localSuggestedSourcesetsTypes, setLocalSuggestedSourcesetsTypes] =
    useState<string[]>([]);
  const [localTopic, setLocalTopic] = useState<Topic | null>(topic);

  const handleRemove = async () => {
    posthog.capture("workflowEdit: remove topic", {
      source: "select source sets wizard partial view",
      mode_action: "topic",
    });
    await MarconipyApi.removeInput(workflow.uuid, topic.uuid, tool_key);
    onRemove();
    setRemoveConfirmation(false);
  };

  const grabTopicDetails = useCallback(async () => {
    setSourcesetsLoading(true);
    const res = (await MarconipyApi.getTopicSourceSetsByWorkflow(
      topic.uuid,
      workflow?.uuid,
    )) as any as { topic: Topic; sourcesets: SourceSet[] };
    if (res.sourcesets) {
      setSourcesets(res.sourcesets);
    }
    setLocalTopic(res.topic);
    setLoading(false);
    const isReady =
      res.topic.metadata["add_suggested_sourcesets_per_topic_status"] &&
      res.topic.metadata["add_suggested_sourcesets_per_topic_status"][
        "status"
      ] == "completed";
    setSourcesetsLoading(!isReady);
    if (isReady) {
      onRefresh?.();
    }
  }, [onRefresh, topic.uuid, workflow?.uuid]);

  const handleSuggestionTypeChange = async (types: string[], add: boolean) => {
    posthog.capture("workflowEdit: update topic", {
      source: "select source sets wizard partial view",
      mode_action: "topic",
    });
    if (add) {
      await MarconipyApi.addInput(
        workflow.uuid,
        tool_key,
        "topic",
        topic.uuid,
        {
          suggest_sourcesets_types: types,
        },
      );
    } else {
      await MarconipyApi.removeInput(workflow.uuid, topic.uuid, tool_key);
    }
    setLocalSuggestedSourcesetsTypes(types);
    setTimeout(() => {
      grabTopicDetails();
    }, 200);
  };

  useEffect(() => {
    if (
      workflow &&
      workflow.artefact &&
      workflow.artefact.inputs &&
      workflow?.artefact.inputs.topic
    ) {
      const input_topics = workflow?.artefact.inputs.topic;
      const toolInputTopic = input_topics.find(
        (input_topic) => input_topic.object.uuid === topic.uuid,
      );
      if (toolInputTopic) {
        setLocalSuggestedSourcesetsTypes(
          toolInputTopic.metadata.suggest_sourcesets_types ?? [],
        );
      }
    }
  }, [workflow.uuid, topic.uuid, workflow]);

  useEffect(() => {
    grabTopicDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="text-black dark:text-white">
      {workflow && loading && (
        <div className="flex flex-col gap-2 my-4">
          <SkeletonElement width={480} height={50} />
          <div className="flex flex-wrap gap-4">
            <SkeletonElement width={72} height={33} />
            <SkeletonElement width={72} height={33} />
            <SkeletonElement width={72} height={33} />
            <SkeletonElement width={72} height={33} />
          </div>
        </div>
      )}
      {workflow && !loading && (
        <>
          <p className="mb-2 text-sm opacity-70">
            This topic is used to suggest you sources.
          </p>
          <div className="flex flex-col gap-2 my-4">
            <p className="text-xs">
              Select the sources you want to use for this topic.
            </p>
            <SourceSetTypeSelection
              onChange={handleSuggestionTypeChange}
              selected_types={localSuggestedSourcesetsTypes}
            />
          </div>
          {sourcesets.length > 0 && (
            <p className="mb-2 uppercase text-xs font-bold">
              Sources suggested:
            </p>
          )}
          {sourcesetsLoading && <p>Loading...</p>}
          {sourcesets.length === 0 && !sourcesetsLoading && (
            <p className="dark:text-white opacity-80 text-sm">
              No sourcesets found.
            </p>
          )}
          {localTopic && sourcesetsLoading && (
            <div className="flex gap-2 py-4 items-center">
              <RiLoader2Line className="animate-spin" />
              <p className="dark:text-white opacity-80 text-sm">
                Finding {localSuggestedSourcesetsTypes.join(", ")} suggestions
                for {topic.name}...
              </p>
            </div>
          )}
          <div className="flex flex-wrap gap-2 mb-4">
            {sourcesets.map((sourceset) => (
              <div key={sourceset.uuid} className="flex items-center">
                <Tooltip content={sourceset.name}>
                  <SourceSetImage image={sourceset.image} />
                </Tooltip>
              </div>
            ))}
          </div>
        </>
      )}
      {(showDeleteButton || saveButtonAction) && (
        <div className="flex flex-col items-align-end justify-end">
          <br />
          <Divider />
          <br />
          <div className="flex gap-2 flex-grow items-align-end justify-end">
            <Button
              variant="secondary"
              onClick={() => setRemoveConfirmation(true)}
            >
              <RiCloseFill />
              Delete
            </Button>
            {saveButtonAction && (
              <Button onClick={saveButtonAction} variant="secondary">
                <RiSaveLine />
                Save
              </Button>
            )}
          </div>
          {removeConfirmation && (
            <Alert
              open={removeConfirmation}
              onClose={() => setRemoveConfirmation(false)}
            >
              <AlertTitle>Confirm Topic Deletion</AlertTitle>
              <AlertDescription>
                {`Are you sure you want to delete the topic "${topic.name}"? This
                action will also remove its suggested sources.`}
              </AlertDescription>
              <AlertActions>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setRemoveConfirmation(false);
                  }}
                >
                  Cancel
                </Button>
                <Button variant="danger" onClick={handleRemove}>
                  Delete Topic
                </Button>
              </AlertActions>
            </Alert>
          )}
        </div>
      )}
    </div>
  );
}

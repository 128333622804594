import { Button } from "@/components/design-system/button";
import { useTeams } from "@/contexts/TeamContext";
import MarconipyApi from "@/utils/marconipyApi";
import { Workflow, WorkflowRun } from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import { useState } from "react";
import { RiLoader2Fill, RiPlayFill, RiRocketLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { PlanStatusComponent } from "./PlanStatusComponent";
import {
  Dialog,
  DialogActions,
  DialogDescription,
  DialogTitle,
} from "./design-system/dialog";

type ManualTriggerProps = {
  workflow: Workflow;
  onNewWorkflowRun?: (workflowRun: WorkflowRun) => void;
};

export function ManualTriggerComponent(props: ManualTriggerProps) {
  const { workflow } = props;
  const posthog = usePostHog();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [billingLimitReached, setBillingLimitReached] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showUpgradeDialog } = useTeams();

  const startWorkflow = async () => {
    setLoading(true);
    posthog.capture("workflowEdit: start workflow", {
      source: "manual trigger",
      dry_run: false,
    });
    try {
      const workflowRunObj = (await MarconipyApi.newWorkflowRun(
        workflow.uuid,
        false,
      )) as any as {
        workflow_run: WorkflowRun;
      };
      toast.success(`${workflow.name} started`);
      props.onNewWorkflowRun?.(workflowRunObj.workflow_run);
      setTimeout(() => {
        setLoading(false);
        setShowConfirmationModal(false);
      });
    } catch (e: any) {
      if (e.response && e.response.status == 402) {
        setBillingLimitReached(true);
        showUpgradeDialog();
        posthog.capture("workflowEdit: billing limit reached", {
          source: "manual trigger",
        });
      } else {
        toast.error("Error starting workflow");
      }
      setLoading(false);
    }
  };

  const navigateToUpgradePageForTeam = () => {
    posthog.capture("UpgradeView: Arriving from manual trigger depleted");
    window.location.href = `/teams/${workflow.team}/upgrade`;
  };

  return (
    <div className="flex">
      <Button
        variant="secondary"
        onClick={() => setShowConfirmationModal(true)}
      >
        <RiPlayFill />
        Run agent
      </Button>
      <div className="ml-4">
        <PlanStatusComponent
          workflow_uuid={workflow.uuid}
          team_uuid={workflow.team}
          hide_dry_runs={true}
        />
      </div>
      <Dialog
        open={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        className=""
      >
        {loading && (
          <div className="p-8">
            <RiLoader2Fill className="animate-spin h-8 w-8 m-auto text-xxl" />
          </div>
        )}
        {!loading && (
          <>
            <DialogTitle>Are you sure you want to start the agent?</DialogTitle>
            <DialogDescription>
              {!billingLimitReached && (
                <>
                  This will <strong>not</strong> be a dry run. The agent will
                  work and the result sent according to your setup.
                </>
              )}
              {billingLimitReached && (
                <>
                  {`You don't have workflow runs left.`}{" "}
                  <a
                    href="#"
                    className="underline hover:no-decoration"
                    onClick={() => navigateToUpgradePageForTeam()}
                  >
                    Check out the available plans
                  </a>
                  .
                </>
              )}
            </DialogDescription>
            <DialogActions>
              {!billingLimitReached && (
                <>
                  <Button
                    variant="plain"
                    onClick={() => setShowConfirmationModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    disabled={loading}
                    onClick={() => {
                      startWorkflow();
                    }}
                  >
                    <RiPlayFill />
                    Start
                  </Button>
                </>
              )}
              {billingLimitReached && (
                <>
                  <Button
                    variant="secondary"
                    onClick={() => setShowConfirmationModal(false)}
                  >
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => navigateToUpgradePageForTeam()}
                  >
                    <RiRocketLine />
                    Upgrade
                  </Button>
                </>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}

import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
} from "@/components/design-system/alert";
import { Button } from "@/components/design-system/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
} from "@/components/design-system/dialog";
import {
  GridTable,
  GridTableCell,
  GridTableRow,
} from "@/components/design-system/grid";
import { Heading } from "@/components/design-system/heading";
import { toolToIcon } from "@/components/MessageIcon";
import Logo from "@/components/styled/Logo";
import WorkflowIcon from "@/components/styled/WorkflowIcon";
import { useWorkflows } from "@/contexts/WorkflowsContext";
import MarconipyApi from "@/utils/marconipyApi";
import { Workflow, WorkflowTemplate } from "@/utils/types";
import { useEffect, useState } from "react";
import { RiRobot2Line } from "react-icons/ri";
import { redirect, useLocation, useNavigate } from "react-router-dom";

export default function NewAgentView() {
  const [justClicked, setJustClicked] = useState<string|null>(null);
  const { createNewWorkflow, deleteWorkflow, cloneWorkflowFromTemplate } =
    useWorkflows();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [workflowToBeDeleted, setWorkflowToBeDeleted] =
    useState<Workflow | null>(null);
  const [workflowTemplates, setWorkflowTemplates] = useState<
    WorkflowTemplate[]
  >([]);
  const location = useLocation();
  const navigate = useNavigate();

  const handleTemplateClick = (template: WorkflowTemplate) => {
    setJustClicked(template.uuid);
    cloneWorkflowFromTemplate(template);
  };

  useEffect(() => {
    const loadTemplates = async () => {
      const templatesObj =
        (await MarconipyApi.getWorkflowTemplates()) as any as {
          templates: WorkflowTemplate[];
        };
      setWorkflowTemplates(templatesObj.templates);
    };
    loadTemplates();
  }, []);

  const onboardingMode = location.search.includes("welcome=true");

  const handleCloseOnboarding = async () => {
    await MarconipyApi.putProfile({
      onboarded: true,
    });
    navigate(`/agents/new`);
  };
  const localcreateNewWorkflow = async () => {
    setJustClicked("blank");
    createNewWorkflow();
  }

  return (
    <div className="flex flex-col p-8">
      <Heading>Create a new agent</Heading>
      <div>
        {/* TODO: There should be more spacing between rows */}
        <GridTable className="grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-x-4 gap-y-12">
          <div className="w-full max-w-[270px] h-[270px]">
            <GridTableRow
              className="cursor-pointer"
              onClick={localcreateNewWorkflow}
            >
              <GridTableCell>
                <div className={`flex flex-col h-full p-4 rounded-lg border border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-900 ${justClicked == 'blank' ? 'animate-pulse' : ''}`}>
                  <div className="text-base mb-2 flex gap-2">
                    <WorkflowIcon icon={"🤖"} />
                    <span className="opacity-50">New agent</span>
                  </div>
                  <div className="flex flex-col gap-1 mt-2">
                    {Array.from([1, 2, 3]).map((index: number) => {
                      let icon = <RiRobot2Line />;
                      return (
                        <div
                          className="ml-1 flex gap-2 items-center opacity-50"
                          key={index}
                        >
                          <div className="w-6 h-6">{icon}</div>
                          <div className="full bg-gray-200 rounded-lg h-6 w-full" />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="py-2">
                  <h3 className="text-lg font-semibold">Blank agent</h3>
                  <p className="opacity-80">Any use case</p>
                </div>
              </GridTableCell>
            </GridTableRow>
          </div>
          {workflowTemplates.map((template: WorkflowTemplate) => (
            <div className="w-full max-w-[270px] h-[270px]" key={template.uuid}>
              <GridTableRow
                key={template.uuid}
                className="cursor-pointer"
                onClick={() => handleTemplateClick(template)}
              >
                <GridTableCell>
                  <div className={`flex flex-col h-full p-4 rounded-lg border border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-900 group ${justClicked == template.uuid ? 'animate-pulse' : ''}`}>
                    <div className=" text-base mb-2 flex gap-2">
                      <WorkflowIcon
                        icon={
                          template.workflow.icon != ""
                            ? template.workflow.icon
                            : template.workflow.name.split("")[0]
                        }
                      />
                      {/* <span className="opacity-50"> */}
                        {/* {template.workflow.name} */}
                        <div className="full bg-gray-200 rounded-lg h-6 w-full opacity-50 group-hover:hidden" />
                        <div className="hidden opacity-40 group-hover:flex">{template.name}</div>
                      {/* </span> */}
                    </div>
                    <div className="flex flex-col gap-1 mt-2 group-hover:hidden min-h-[90px] max-h-[90px]">
                      {template.workflow.selected_tools_for_run.map(
                        (tool: string, index: number) => {
                          if (index > 2) {
                            return null;
                          }
                          let icon = <RiRobot2Line />;
                          if (tool && Object.keys(toolToIcon).includes(tool)) {
                            icon = toolToIcon[tool];
                          }
                          return (
                            <div
                              className="ml-1 flex gap-2 items-center opacity-50"
                              key={index}
                            >
                              <div className="w-6 h-6">{icon}</div>
                              <div className="full bg-gray-200 rounded-lg h-6 w-full" />
                            </div>
                          );
                        },
                      )}
                    </div>
                    <div className="hidden flex-col gap-1 mt-2 text-black group-hover:flex min-h-[90px] max-h-[90px]">
                          <p>{template.description}</p>
                    </div>
                  </div>
                  <div className={`py-2 ${justClicked == template.uuid ? 'animate-pulse' : ''}`}>
                    <h3 className="text-lg font-semibold">
                      {justClicked == template.uuid ? "Loading..." : template.name}
                    </h3>
                    <p className="opacity-80">{template.category}</p>
                  </div>
                </GridTableCell>
              </GridTableRow>
            </div>
          ))}
        </GridTable>
      </div>
      <Alert
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <AlertTitle>Confirm Agent Deletion</AlertTitle>
        <AlertDescription>
          Are you sure you want to delete this agent? This action cannot be
          undone.
        </AlertDescription>
        <AlertActions>
          <Button
            variant="secondary"
            onClick={() => {
              setWorkflowToBeDeleted(null);
              setIsDeleteModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() =>
              workflowToBeDeleted && deleteWorkflow(workflowToBeDeleted)
            }
          >
            Delete agent
          </Button>
        </AlertActions>
      </Alert>
      <Dialog open={onboardingMode} onClose={handleCloseOnboarding} size="xl">
        <Logo className="h-8 mb-4" />
        <DialogBody className="flex flex-col gap-2">
          <Heading>Welcome to TailorTask: AI Automation Beyond Chat</Heading>
          <p>Automate repetitive tasks with intelligent AI agents:</p>
          <ol className="list-decimal ml-5">
            <li>Brief Your Agent: Describe your task and goals</li>
            <li>Review & Refine: Approve the workflow or provide feedback</li>
            <li>Execute & Control: Run on-demand or schedule</li>
          </ol>
          <Heading className="mt-4">What makes TailorTask unique?</Heading>
          <ul className="list-disc ml-5">
            <li>AI agents that do tasks, not just chat</li>
            <li>
              Performs real actions like web searches and content creation
            </li>
            <li>Learns and adapts to your needs</li>
          </ul>
          <p>
            To create your first agent, start from scratch or use a template
          </p>
        </DialogBody>
        <DialogActions>
          <Button onClick={handleCloseOnboarding}>Start automating</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export async function loader({ params }: { params: any }) {
  try {
    //check if the user is already logged in
    const loggedIn = MarconipyApi.isAuth();
    if (!loggedIn) {
      console.log("User is not logged in");
      return redirect("/login");
    }
    return {};
  } catch (e: any) {
    console.log(e);
    return redirect("/login");
  }
}

import { Button } from "@/components/design-system/button";
import Tooltip from "@/components/styled/Tooltip";
import MarconipyApi from "@/utils/marconipyApi";
import { OutputTemplate, Workflow } from "@/utils/types";
import DOMPurify from "dompurify";
import he from "he";
import { usePostHog } from "posthog-js/react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { RiAddBoxFill } from "react-icons/ri";

type OutputTemplateProps = {
  workflow: Workflow;
  template_uuid: string;
};

const OutputTemplateComponent: React.FC<OutputTemplateProps> = ({
  workflow,
  template_uuid,
}) => {
  const posthog = usePostHog();
  const [text, setText] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const editorRef = useRef<HTMLDivElement>(null);
  const variables = useMemo(
    () => [
      {
        text: "{result}",
        tooltip: "The final result of the workflow",
      },
      {
        text: "{references}",
        tooltip: "References to any source used to generate the result",
      },
    ],
    [],
  );
  const [anychangesMade, setAnyChangesMade] = useState(false);

  const insertVariable = (variable: string) => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const span = document.createElement("span");
      span.className = "bg-blue-200 text-blue-800";
      span.textContent = variable;
      range.deleteContents();
      range.insertNode(span);
      range.setStartAfter(span);
      range.setEndAfter(span);
      selection.removeAllRanges();
      selection.addRange(range);
    } else if (editorRef.current) {
      const span = document.createElement("span");
      span.className = "bg-blue-200 text-blue-800";
      span.textContent = variable;
      editorRef.current.appendChild(span);
    }
    updateText();
  };

  const updateText = () => {
    if (editorRef.current) {
      setText(editorRef.current.innerHTML);
      setAnyChangesMade(true);
    }
  };

  const handleInput = () => {
    updateText();
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const text = e.clipboardData.getData("text/plain");
    document.execCommand("insertText", false, text);
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        e.preventDefault();
        document.execCommand("insertLineBreak");
      }
    };

    const editor = editorRef.current;
    if (editor) {
      editor.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (editor) {
        editor.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  const highlightVariables = useCallback(
    (content: string) => {
      if (!content) return "";
      const regex = new RegExp(
        `(${variables.map((variable) => variable.text).join("|")})`,
        "g",
      );

      if (typeof content !== "string") {
        console.error("Content is not a string");
        console.log(content);
      }
      return content.replace(
        regex,
        '<span class="bg-blue-200 text-blue-800">$1</span>',
      );
    },
    [variables],
  );

  useEffect(() => {
    if (editorRef.current && !isFocused) {
      const newInnerHTML = highlightVariables(text);
      if (newInnerHTML !== editorRef.current.innerHTML) {
        const selection = window.getSelection();
        let cursorPosition = 0;

        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          cursorPosition = range.startOffset;
        }

        editorRef.current.innerHTML = newInnerHTML;

        // Restore cursor position
        if (selection) {
          const newRange = document.createRange();
          let currentPos = 0;
          let targetNode: Node | null = null;
          let targetOffset = 0;

          const traverseNodes = (node: Node) => {
            if (node.nodeType === Node.TEXT_NODE) {
              if (currentPos + node.textContent!.length >= cursorPosition) {
                targetNode = node;
                targetOffset = cursorPosition - currentPos;
                return true;
              }
              currentPos += node.textContent!.length;
            } else {
              for (let i = 0; i < node.childNodes.length; i++) {
                if (traverseNodes(node.childNodes[i])) {
                  return true;
                }
              }
            }
            return false;
          };

          traverseNodes(editorRef.current);

          if (targetNode) {
            newRange.setStart(targetNode, targetOffset);
            newRange.setEnd(targetNode, targetOffset);
            selection.removeAllRanges();
            selection.addRange(newRange);
          }
        }
      }
    }
  }, [text, isFocused, highlightVariables]);

  const handleSave = async () => {
    posthog.capture("workflowEdit: save output template", {
      source: "workflow",
    });
    // Remove all HTML tags and decode HTML entities
    const strippedText = DOMPurify.sanitize(text, { ALLOWED_TAGS: [] });

    // Decode HTML entities (e.g., &amp; to &)
    const cleanedText = he.decode(strippedText);
    console.log(cleanedText);
    await MarconipyApi.editOutputTemplate(workflow.uuid, template_uuid, {
      text: cleanedText,
    });
    setAnyChangesMade(false);
  };

  useEffect(() => {
    const loadTemplate = async () => {
      const templates = (await MarconipyApi.outputTemplates(
        workflow.uuid,
      )) as any as { output_templates: OutputTemplate[] };
      const ftemplate = templates.output_templates.find(
        (template: OutputTemplate) => template.uuid === template_uuid,
      );
      if (ftemplate) {
        if (!ftemplate.template["text"]) {
          ftemplate.template["text"] = "";
        }
        setText(ftemplate.template["text"]);
      }
    };
    loadTemplate();
  }, [workflow.uuid, template_uuid]);

  return (
    <div className="p-4 mb-8">
      <div className="mb-4 flex space-x-2">
        {variables.map((variable) => (
          <Tooltip content={variable.tooltip} key={variable.text}>
            <Button
              variant="secondary"
              onClick={() => insertVariable(variable.text)}
            >
              <RiAddBoxFill className="mr-1" />
              <span className="text-sm">{variable.text}</span>
            </Button>
          </Tooltip>
        ))}
      </div>
      <div
        ref={editorRef}
        contentEditable
        className="min-h-[200px] p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        onInput={handleInput}
        onPaste={handlePaste}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      {anychangesMade && (
        <div className="mt-4">
          <Button onClick={handleSave} variant="secondary">
            Save changes
          </Button>
        </div>
      )}
    </div>
  );
};

export default OutputTemplateComponent;

import MarconipyApi from "@/utils/marconipyApi";
import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { redirect, useNavigate } from "react-router-dom";
// import { Task, Message } from "@/utils/types";
import ChatComponent from "@/components/ChatComponent";
import { ContextComponent } from "@/components/ContextComponent";
import { Button } from "@/components/design-system/button";
import { Loading } from "@/components/Loading";
import SkeletonElementPreset from "@/components/SkeletonElementPreset";
import { WorkflowNavigationBar } from "@/components/WorkflowNavigationBar";
import WorkflowViewSidebar from "@/components/WorkflowViewSidebar";
import { useWorkflows } from "@/contexts/WorkflowsContext";
import { WorkflowNavigationBarOptions } from "@/utils/types";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import {
  RiArticleLine,
  RiCalendarLine,
  RiEditLine,
  RiFileTextLine,
  RiSendPlaneLine,
  RiTestTubeFill,
  RiToolsLine,
} from "react-icons/ri";
import LaunchStep from "./partials/LaunchStep";
import OutputStep from "./partials/OutputStep";
import TestStep from "./partials/TestStep";
import ToolsPartialView from "./partials/ToolsPartialView";
import WorkflowTriggers from "./partials/WorkflowTriggers";

type SettingStep = {
  id: string;
  title: string;
  tooltip: string;
  icon: React.ReactNode;
  isCompleted: boolean;
};

export default function AgentView() {
  const posthog = usePostHog();
  const [activeStep, setActiveStep] = useState<string | null>(null);
  const { workflow, reloadCurrentWorkflow } = useWorkflows();
  const [sidePanelActiveTab, setSidePanelActiveTab] =
    useState<WorkflowNavigationBarOptions>(
      workflow?.active ? "settings" : "none",
    );
  const [showSuccessAnimationToggle, setShowSuccessAnimationToggle] =
    useState(false);
  const { updateCurrentWorkflow } = useWorkflows();
  const navigate = useNavigate();
  const [newAnimatedWorkflowName, setNewAnimatedWorkflowName] = useState<
    string | undefined
  >(undefined);

  const handleActivateWorkflow = useCallback(async () => {
    if (!workflow) {
      return;
    }
    await updateCurrentWorkflow(workflow, {
      active: !workflow.active,
    });
    await reloadCurrentWorkflow();
    posthog.capture("workflowEdit: workflow_activated", {
      active: !workflow.active,
      source: "workflowview",
    });
    if (!workflow.active) {
      showSuccessAnimation();
    }
  }, [posthog, reloadCurrentWorkflow, updateCurrentWorkflow, workflow]);

  const showSuccessAnimation = () => {
    setShowSuccessAnimationToggle(true);
    setTimeout(() => {
      setShowSuccessAnimationToggle(false);
    }, 3000);
  };
  const switchSidePanelTab = useCallback(
    (tab: WorkflowNavigationBarOptions) => {
      posthog.capture("workflowView: switchSidePanelTab", { tab: tab });
      setSidePanelActiveTab(tab);
    },
    [posthog],
  );
  const toggleStep = useCallback(
    (id: string, automatic: boolean = false) => {
      posthog.capture("workflowView: toggleStep", {
        step_id: id,
        automatic: automatic,
      });
      switchSidePanelTab("settings");
      setActiveStep((prev) => (prev === id ? null : id));
    },
    [posthog, switchSidePanelTab],
  );
  const steps: SettingStep[] = useMemo(() => {
    return [
      {
        id: "brief",
        title: "Brief",
        tooltip: "The brief that your agent will follow to complete the task",
        icon: <RiFileTextLine className="w-5 h-5" />,
        isCompleted: workflow
          ? workflow.metadata.steps_status.context == "completed"
          : false,
      },
      {
        id: "tools",
        title: "Actions",
        tooltip:
          "The actions that your agent will have at its disposal to complete your task",
        icon: <RiToolsLine className="w-5 h-5" />,
        isCompleted: workflow
          ? workflow.metadata.steps_status.tools == "completed"
          : false,
      },
      {
        id: "test",
        title: "Dry run",
        tooltip: "Try your agent with a dry run before activating it",
        icon: <RiTestTubeFill className="w-5 h-5" />,
        isCompleted: workflow
          ? workflow.metadata.steps_status.test == "completed"
          : false,
      },
      {
        id: "triggers",
        title: "Triggers",
        tooltip: "When your agent will start working",
        icon: <RiCalendarLine className="w-5 h-5" />,
        isCompleted: workflow
          ? workflow.metadata.steps_status.triggers == "completed"
          : false,
      },
      {
        id: "output",
        title: "Results",
        tooltip: "How you will receive the results of your task",
        icon: <RiArticleLine className="w-5 h-5" />,
        isCompleted: workflow
          ? workflow.metadata.steps_status.output == "completed"
          : false,
      },
      {
        id: "confirm",
        title: "Activate your Agent",
        tooltip: "Activate your Agent to have it run according to the triggers",
        icon: <RiSendPlaneLine className="w-5 h-5" />,
        isCompleted: workflow ? workflow.active : false,
      },
    ];
  }, [workflow]);

  const switchToNextPendingStep = useCallback(
    async (justConfirmed: string) => {
      setTimeout(() => {
        const nextStep = steps.find(
          (step) => !step.isCompleted && step.id != justConfirmed,
        );
        if (nextStep) {
          // console.log("Switching to next step", nextStep.id);
          toggleStep(nextStep.id);
        }
      }, 400);
    },
    [steps, toggleStep],
  );
  const handleConfirmTest = useCallback(() => {
    reloadCurrentWorkflow();
    switchToNextPendingStep("test");
  }, [reloadCurrentWorkflow, switchToNextPendingStep]);

  const stepsToContent: Record<string, React.ReactNode | null> = useMemo(() => {
    return {
      brief: workflow && <ContextComponent workflow={workflow} />,
      tools: workflow && (
        <ToolsPartialView
          workflow={workflow}
          isOnboarding={false}
          onWorkflowRefresh={reloadCurrentWorkflow}
          onToolsConfirmed={switchToNextPendingStep}
        />
      ),
      test: workflow && (
        <TestStep
          workflow={workflow}
          onTestConfirmed={handleConfirmTest}
          onWorkflowReload={reloadCurrentWorkflow}
        />
      ),
      triggers: workflow && (
        <WorkflowTriggers
          workflow={workflow}
          onWorkflowRefresh={reloadCurrentWorkflow}
          onTriggersConfirmed={switchToNextPendingStep}
        />
      ),
      output: workflow && (
        <OutputStep
          workflow={workflow}
          switchToNextPendingStep={switchToNextPendingStep}
        />
      ),
      confirm: workflow && (
        <LaunchStep
          workflow={workflow}
          showSuccessAnimationToggle={showSuccessAnimationToggle}
          onWorkflowActivate={handleActivateWorkflow}
        />
      ),
    };
  }, [
    handleActivateWorkflow,
    handleConfirmTest,
    reloadCurrentWorkflow,
    showSuccessAnimationToggle,
    switchToNextPendingStep,
    workflow,
  ]);

  const handleImportantMessage = useCallback(
    (message: any) => {
      if (message.type == "workflow_name_update") {
        setNewAnimatedWorkflowName(message.message);
      }
      if (message.type == "open_setting_step") {
        const step_id = message.message;
        const step = steps.find((step) => step.id == step_id);
        if (step) {
          toggleStep(step.id, true);
        }
      }
    },
    [steps, toggleStep],
  );

  useEffect(() => {
    setNewAnimatedWorkflowName(workflow?.name);
  }, [workflow?.name]);

  const handleQuickReplyAction = async (action: string) => {
    switch (action) {
      case "open_tool_settings":
        setSidePanelActiveTab("settings");
        setActiveStep("tools");
        break;
      case "open_brief_settings":
        setSidePanelActiveTab("settings");
        setActiveStep("brief");
        break;
      case "confirm_test_result":
        setSidePanelActiveTab("settings");
        setActiveStep("test");
        if (!workflow) {
          return;
        }
        await updateCurrentWorkflow(workflow, {
          metadata: { test: true },
        });
        handleConfirmTest();
        break;
      case "open_output_settings":
        setSidePanelActiveTab("settings");
        setActiveStep("output");
        break;
      case "open_triggers_settings":
        setSidePanelActiveTab("settings");
        setActiveStep("triggers");
        break;
      case "confirm_workflow":
        setSidePanelActiveTab("settings");
        setActiveStep("confirm");
        handleActivateWorkflow();
        break;
      case "payment_required": {
        location.href = "/upgrade";
        break;
      }
      default:
        console.log(`Unhandled action: ${action}`);
    }
  };

  const editMode = location.search.includes("edit=true");

  if (!workflow) {
    return <Loading />;
  }

  return (
    <div className="h-full">
      <Helmet>
        <title>{workflow.name} - TailorTask</title>
      </Helmet>
      <div className="flex h-full w-full">
        <div
          className={classNames("flex", {
            "w-full": sidePanelActiveTab === "none" || !editMode,
            "w-1/2 md:w-2/3 lg:3/5": sidePanelActiveTab !== "none" && editMode,
          })}
        >
          <WorkflowNavigationBar
            workflow={workflow}
            activeSidePanelTab={sidePanelActiveTab}
            switchSidePanelTab={switchSidePanelTab}
            newAnimatedName={newAnimatedWorkflowName}
          >
            {(workflow.active || editMode) && (
              <div
                className={classNames("", {
                  "md:w-2/3 md:max-w-2/3 mx-auto grow":
                    sidePanelActiveTab === "none" || !editMode,
                  "w-1/2 md:w-full": sidePanelActiveTab !== "none" && editMode,
                })}
              >
                <ChatComponent
                  workflow_uuid={workflow.uuid}
                  initialState={workflow.state}
                  workflowIcon={
                    workflow.icon != ""
                      ? workflow.icon
                      : workflow.name.split("")[0]
                  }
                  onWorkflowRefresh={reloadCurrentWorkflow}
                  onImportantMessage={handleImportantMessage}
                  onQuickReplyAction={handleQuickReplyAction}
                  shiftToBottom={true}
                  showQuickReplyBubbles={!workflow.metadata.from_template}
                />
              </div>
            )}
            {!workflow.active && !editMode && (
              <div className="relative flex flex-col items-center justify-center h-full w-full">
                <div className="absolute inset-0 flex flex-col gap-8 p-8 opacity-20 pointer-events-none">
                  <div className="w-full overflow-hidden">
                    <SkeletonElementPreset variant="chat-messages" />
                    <SkeletonElementPreset variant="chat-messages" />
                  </div>
                </div>
                <div className="z-10 flex flex-col items-center gap-8 text-center">
                  <p className="text-lg font-medium">
                    To start using this agent, edit it to complete the setup.
                  </p>
                  <Button
                    onClick={() =>
                      navigate(`/agents/${workflow.uuid}?edit=true`)
                    }
                    size="lg"
                  >
                    <RiEditLine className="w-5 h-5 mr-2" />
                    Edit Agent
                  </Button>
                </div>
              </div>
            )}
          </WorkflowNavigationBar>
        </div>
        {editMode && (
          <WorkflowViewSidebar
            workflow_uuid={workflow.uuid}
            sidePanelActiveTab={sidePanelActiveTab}
            activeStep={activeStep}
            toggleStep={toggleStep}
            steps={steps.map((step: SettingStep) => {
              return {
                ...step,
                content: stepsToContent[step.id],
              };
            })}
            switchSidePanelTab={switchSidePanelTab}
          />
        )}
      </div>
    </div>
  );
}

export async function loader({ params }: { params: any }) {
  try {
    //check if the user is already logged in
    const loggedIn = MarconipyApi.isAuth();
    if (!loggedIn) {
      console.log("User is not logged in");
      return redirect("/login");
    }
    return {};
  } catch (e: any) {
    console.log("error, check if waitlist");
    console.log(e);
    if (e.json && e.json.detail && e.json.detail.includes("waitlist")) {
      return redirect("/waitlist");
    }
    if (e.response && e.response.data && e.response.data.includes("Banned")) {
      return redirect("/banned");
    }
    throw e;
  }
}

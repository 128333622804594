import { Button } from "@/components/design-system/button";
import MarconipyApi from "@/utils/marconipyApi";
import { OutputChannel, Workflow } from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import { RiCheckFill, RiDeleteBinFill, RiLoader2Fill } from "react-icons/ri";
import { Label } from "./design-system/fieldset";
import { Switch, SwitchField } from "./design-system/switch";
import Tooltip from "./styled/Tooltip";

type OutputChannelEmailProps = {
  workflow: Workflow;
  output_channel_uuid?: string;
  onRefresh?: () => void;
};

export function OutputChannelEmail({
  workflow,
  output_channel_uuid,
  onRefresh,
}: OutputChannelEmailProps) {
  const posthog = usePostHog();
  const [isActive, setIsActive] = useState(false);
  const [channel_uuid, setChannelUuid] = useState(output_channel_uuid);

  const handleToggle = () => {
    setIsActive(!isActive);
    handleSave(!isActive);
  };

  const loadOutputChannel = async () => {
    if (output_channel_uuid) {
      const fchannels = (await MarconipyApi.outputChannels(
        workflow.uuid,
      )) as any as { output_channels: OutputChannel[] };
      const fchannel = fchannels.output_channels.find(
        (c) => c.uuid === output_channel_uuid,
      );
      if (!fchannel) return;
      setIsActive(fchannel.active);
    }
  };

  const deleteOutputChannel = async () => {
    if (!output_channel_uuid) return;
    posthog.capture("workflowEdit: delete output channel", {
      source: "workflow output channels",
      type: "email",
    });
    await MarconipyApi.deleteOutputChannel(workflow.uuid, output_channel_uuid);
    onRefresh && onRefresh();
  };

  const handleSave = async (force_active?: boolean) => {
    let active = isActive;
    if (force_active !== undefined) {
      active = force_active;
    }
    if (active) {
      posthog.capture("workflowEdit: activate output channel", {
        source: "workflow output channels",
        type: "email",
      });
    } else {
      posthog.capture("workflowEdit: deactivate output channel", {
        source: "workflow output channels",
        type: "email",
      });
    }
    if (!output_channel_uuid) {
      const res = (await MarconipyApi.createOutputChannel(
        workflow.uuid,
      )) as any as { output_channel: OutputChannel };
      output_channel_uuid = res.output_channel.uuid;
      setChannelUuid(output_channel_uuid);
    }
    await MarconipyApi.editOutputChannel(workflow.uuid, output_channel_uuid, {
      active: active,
      channel_type: "email",
    });
    onRefresh && onRefresh();
  };

  useEffect(() => {
    loadOutputChannel();
  }, [workflow.uuid, output_channel_uuid]);

  return (
    <div className="">
      <div className="flex gap-4 my-4 items-start">
        <SwitchField>
          <Switch onChange={() => handleToggle()} checked={isActive} />
          <Label className="font-semibold">📩 Get an email</Label>
          {/* <Description>
            <span className="text-sm mb-2">
              You will get your result via email. Run this task to try it out.
            </span>
          </Description> */}
        </SwitchField>
        {/* <label htmlFor="triggerToggle" className="ml-4 font-bold"></label> */}
        {channel_uuid && (
          <div className="ml-4">
            <Tooltip content="Delete email result setting">
              <Button variant="secondary" onClick={deleteOutputChannel}>
                <RiDeleteBinFill />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="mt-4 flex items-center text-sm  mb-2">
        {isActive ? (
          <>
            <RiCheckFill className="text-green-500 mr-1" />
            Channel is active
          </>
        ) : (
          <>
            <RiLoader2Fill className="text-gray-400 mr-1" />
            Channel is inactive
          </>
        )}
      </div>
      {/* {isActive && (
        <div className="mt-4">
          <label htmlFor="triggerPhrase" className="block mb-2">
            Define Trigger Phrase:
          </label>
          <div className="flex items-center">
            <Input
              type="text"
              id="triggerPhrase"
              value={triggerPhrase}
              onChange={handleTriggerPhraseChange}
              placeholder="Enter trigger phrase"
            />
            <button onClick={handleSetTriggerPhrase}>
              <RiMessage2Fill />
              Set Phrase
            </button>
          </div>
        </div>
      )} */}
    </div>
  );
}

import clsx from "clsx";

export default function WorkflowIcon({
  icon,
  variant = "primary",
  showActiveIndicator = false,
  isActive = false,
}: {
  icon: string;
  variant?: string;
  showActiveIndicator?: boolean;
  isActive?: boolean;
}) {
  return (
    <div className="relative">
      <div
        className={clsx(
          "flex rounded-lg w-6 h-6 items-center justify-center ring-1 ring-zinc-950/5 dark:ring-white/10",
          {
            "bg-primary/15": variant == "primary",
            "bg-secondary/10": variant == "secondary",
            "bg-tertiary/15": variant == "tertiary",
          },
        )}
      >
        {icon}
        {showActiveIndicator && (
          <div
            className={clsx(
              "absolute bottom-0 right-0 w-2 h-2 rounded-full border",
              {
                "bg-green border-white": isActive,
                "bg-white border-black dark:bg-black dark:border-white":
                  !isActive,
              },
            )}
          />
        )}
      </div>
    </div>
  );
}

import { Button } from "@/components/design-system/button";
import { useWorkflows } from "@/contexts/WorkflowsContext";
import { NameConventions } from "@/utils/NameConventions";
import { Workflow } from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import { RiCheckLine } from "react-icons/ri";
import Tooltip from "./Tooltip";

type ConfirmationButtonProps = {
  workflow: Workflow;
  tooltip: string;
  type: string;
  onClick?: () => void;
  disabled?: boolean;
  tooltip_disabled?: string;
  errorValidationMessage?: string;
};

const ConfirmationButton: React.FC<ConfirmationButtonProps> = ({
  workflow,
  tooltip,
  type,
  onClick,
  disabled,
  tooltip_disabled,
  errorValidationMessage,
}) => {
  const posthog = usePostHog();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [variant, setVariant] = useState<"primary" | "secondary">("primary");
  const [customLabel, setCustomLabel] = useState<string | null>(null);
  const { updateCurrentWorkflow } = useWorkflows();

  const stepStatus =
    workflow.metadata?.steps_status?.[
      type as keyof typeof workflow.metadata.steps_status
    ];
  const isStepCompleted = stepStatus === "completed";
  useEffect(() => {
    if (isStepCompleted) {
      setCustomLabel(
        capitalize(NameConventions.replaceKnownConventionsFromString(type)) +
          " confirmed",
      );
      setVariant("secondary");
    } else {
      setVariant("primary");
      setCustomLabel(
        "Confirm " + NameConventions.replaceKnownConventionsFromString(type),
      );
    }
  }, [isStepCompleted, type]);

  const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const handleClick = async () => {
    setLoading(true);
    posthog.capture(`workflowEdit: confirmation ${type} clicked`, {
      source: "workflow run view",
    });
    setError(null);
    try {
      setLoading(true);
      await updateCurrentWorkflow(workflow, {
        metadata: { [type]: true },
      });
      if (onClick) {
        await onClick();
      }
      setVariant("secondary");
      setLoading(false);
    } catch (err) {
      setError(errorValidationMessage || "An error occurred");
      console.error("Error updating workflow:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex">
      <Tooltip
        content={
          disabled
            ? tooltip_disabled
              ? tooltip_disabled
              : "Complete to continue"
            : tooltip
        }
      >
        <Button
          variant={variant}
          onClick={handleClick}
          disabled={loading || disabled || isStepCompleted}
        >
          <RiCheckLine />
          {loading ? "Processing..." : customLabel}
        </Button>
        {error && <div className="text-red-500 mt-2">{error}</div>}
      </Tooltip>
    </div>
  );
};

export default ConfirmationButton;

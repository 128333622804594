import MarconipyApi from "@/utils/marconipyApi";
import { Workflow, WorkflowTemplate } from "@/utils/types";
import { usePostHog } from "posthog-js/react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "./AuthenticationContext";
import { useTeams } from "./TeamContext";

interface WorkflowContextType {
  loading: boolean;
  workflows: Workflow[];
  workflow: Workflow | null;
  setSelectedWorkflow: (workflow: Workflow) => void;
  createNewWorkflow: () => Promise<void>;
  deleteWorkflow: (workflow: Workflow) => Promise<void>;
  updateCurrentWorkflow: (workflow: Workflow, params: any) => Promise<void>;
  reloadCurrentWorkflow: () => Promise<void>;
  cloneWorkflow(from_workflow: Workflow): Promise<void>;
  cloneWorkflowFromTemplate: (template: WorkflowTemplate) => Promise<void>;
  workflowWasRefreshed: boolean;
}

const WorkflowContext = createContext<WorkflowContextType | null>(null);

export const useWorkflows = () => {
  const context = useContext(WorkflowContext);
  if (!context) {
    throw new Error("useTeams must be used within a TeamProvider");
  }
  return context;
};

export const WorkflowProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { selectedTeam, teamHasChanged, switchToTeamUUID } = useTeams();
  const [loading, setLoading] = useState(true);
  const [workflows, setWorkflows] = useState([] as Workflow[]);
  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(
    null,
  );
  const [workflowWasRefreshed, setWorkflowWasRefreshed] = useState(false);
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const posthog = usePostHog();

  const handleSelectWorkflow = useCallback((workflow: Workflow) => {
    setSelectedWorkflow(workflow);
    // navigate("/agents/" + workflow.uuid);
    window.location.href = "/agents/" + workflow.uuid;
  }, []);
  useEffect(() => {
    if (location.pathname.includes("/agents/")) {
      const workflowUUID = location.pathname.split("?")[0].split("/")[2];
      if (workflowUUID == "new") {
        return;
      }
      const workflow = workflows.find((w) => w.uuid === workflowUUID);
      if (workflow && workflow.uuid !== selectedWorkflow?.uuid) {
        setSelectedWorkflow(workflow);
      }
      if (!workflow && !selectedWorkflow) {
        // if the workflow is not found, just refresh it
        loadWorkflow(workflowUUID);
      }
    }
  }, [location.pathname, workflows, selectedWorkflow, handleSelectWorkflow]);

  const loadWorkflow = async (uuid: string) => {
    const response = (await MarconipyApi.getWorkflow(uuid)) as any as {
      workflow: Workflow;
    };
    setSelectedWorkflow(response.workflow);
  };

  const loadWorkflows = useCallback(async () => {
    if (!selectedTeam) {
      return;
    }
    const workflowsObj = (await MarconipyApi.getWorkflowsByTeam({
      teamUUID: selectedTeam?.uuid,
    })) as any as {
      workflows: Workflow[];
    };
    let fetchedWorkflows = workflowsObj.workflows as any as Workflow[];
    fetchedWorkflows = fetchedWorkflows.sort(
      (a, b) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
    );
    setWorkflows(fetchedWorkflows);
    setLoading(false);
  }, [selectedTeam]);

  const deleteWorkflow = useCallback(
    async (workflow: Workflow) => {
      posthog.capture("workflowEdit: delete workflow");
      await MarconipyApi.deleteWorkflow(workflow.uuid);
      setWorkflows(workflows.filter((w) => workflow.uuid != w.uuid));
      navigate("/agents");
    },
    [navigate, posthog, workflows],
  );

  const createNewWorkflow = useCallback(async () => {
    posthog.capture("workflowEdit: create workflow");
    const response = (await MarconipyApi.createWorkflow()) as any as {
      workflow: Workflow;
    };
    setWorkflows([response.workflow, ...workflows]);
    // navigate("/agents/" + response.workflow.uuid + "?edit=true");
    window.location.href = "/agents/" + response.workflow.uuid + "?edit=true";
  }, [posthog, workflows]);

  const reloadCurrentWorkflow = useCallback(async () => {
    if (!selectedWorkflow) {
      return;
    }
    posthog.capture("workflowEdit: reload workflow");
    const response = (await MarconipyApi.getWorkflow(
      selectedWorkflow?.uuid,
    )) as any as {
      workflow: Workflow;
    };
    setSelectedWorkflow(response.workflow);
    setWorkflowWasRefreshed(true);
  }, [posthog, selectedWorkflow]);

  const updateCurrentWorkflow = useCallback(
    async (workflow: Workflow, params: any) => {
      posthog.capture("workflowEdit: update workflow");
      setWorkflowWasRefreshed(true);
      const workflowObj = (await MarconipyApi.editWorkflow(
        workflow.uuid,
        params,
      )) as any as {
        workflow: Workflow;
      };
      let newWorkflow = workflowObj.workflow;
      setSelectedWorkflow(newWorkflow);
      setWorkflows(
        workflows.map((w) => {
          if (w.uuid === workflow.uuid) {
            return newWorkflow;
          }
          return w;
        }),
      );
    },
    [posthog, workflows],
  );

  const cloneWorkflowFromTemplate = useCallback(
    async (template: WorkflowTemplate) => {
      posthog.capture("workflowEdit: clone workflow from template");
      try {
        const response = (await MarconipyApi.cloneWorkflow(
          template.workflow.uuid,
          template.metadata,
        )) as any as {
          workflow: Workflow;
        };
        setWorkflows([response.workflow, ...workflows]);
        // navigate("/agents/" + response.workflow.uuid + "?edit=true");
        window.location.href =
          "/agents/" + response.workflow.uuid + "?edit=true";
      } catch (e) {
        console.error(e);
        toast.error(
          "There was an error creating your agent. Try again or reach out to info@tailortask.ai",
        );
      }
    },
    [posthog, workflows],
  );

  const cloneWorkflow = useCallback(
    async (from_workflow: Workflow) => {
      posthog.capture("workflowEdit: clone workflow");
      try {
        const response = (await MarconipyApi.cloneWorkflow(
          from_workflow.uuid,
          {},
        )) as any as {
          workflow: Workflow;
        };
        setWorkflows([response.workflow, ...workflows]);
        // navigate("/agents/" + response.workflow.uuid + "?edit=true");
        window.location.href =
          "/agents/" + response.workflow.uuid + "?edit=true";
      } catch (e) {
        console.error(e);
        toast.error(
          "There was an error cloning your agent. Try again or reach out to info@tailortask.ai",
        );
      }
    },
    [posthog, workflows],
  );

  useEffect(() => {
    if (workflowWasRefreshed) {
      setWorkflowWasRefreshed(false);
    }
  }, [workflowWasRefreshed]);

  useEffect(() => {
    if (isAuth && selectedTeam) {
      if (workflows.length === 0 || teamHasChanged) {
        loadWorkflows();
      }
    } else {
      setWorkflows([]);
      setSelectedWorkflow(null);
    }
  }, [isAuth, loadWorkflows, selectedTeam, teamHasChanged, workflows.length]);

  useEffect(() => {
    if (
      selectedTeam &&
      selectedWorkflow &&
      selectedWorkflow.team != selectedTeam.uuid
    ) {
      switchToTeamUUID(selectedTeam.uuid);
    }
  });

  const contextValue: WorkflowContextType = {
    loading,
    workflows,
    workflow: selectedWorkflow,
    setSelectedWorkflow: handleSelectWorkflow,
    createNewWorkflow,
    deleteWorkflow,
    updateCurrentWorkflow,
    reloadCurrentWorkflow,
    cloneWorkflowFromTemplate,
    cloneWorkflow,
    workflowWasRefreshed,
  };

  return (
    <WorkflowContext.Provider value={contextValue}>
      {children}
    </WorkflowContext.Provider>
  );
};

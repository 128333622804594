import { Loading } from "@/components/Loading";
import MarconipyApi from "@/utils/marconipyApi";
import { Workflow } from "@/utils/types";
import { Helmet } from "react-helmet";
import { redirect } from "react-router-dom";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>TailorTask - Automate your tasks</title>
        <meta
          name="description"
          content="Repetitive tasks, automated. TailorTask uses A.I. to automate your most boring tasks."
        />
        <meta
          property="og:title"
          content="TailorTask - Repetitive tasks, automated"
        />
        <meta
          property="og:description"
          content="Repetitive tasks, automated. TailorTask uses A.I. to automate your most boring tasks."
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://app.tailortask.ai/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
      </Helmet>
      <Loading />
    </>
  );
}

export async function loader({ params }: { params: any }) {
  const loggedIn = MarconipyApi.isAuth();
  if (loggedIn) {
    const user = await MarconipyApi.getUserStatus();
    if (!user?.onboarded) {
      return redirect("/agents/new?welcome=true");
    } else {
      const loggedIn = MarconipyApi.isAuth();
      if (!loggedIn) {
        console.log("User is not logged in");
        return redirect("/login");
      }
      const workflowUUID = params.workflow_uuid;
      if (workflowUUID) {
        console.log("redirecting");
        return redirect(`/agents/${workflowUUID}`);
      }
      const teamObj = await MarconipyApi.getDefaultTeam();
      const team = teamObj.team;
      const workflowsObj = (await MarconipyApi.getWorkflowsByTeam({
        teamUUID: team?.uuid,
      })) as any as {
        workflows: Workflow[];
      };
      const workflows = workflowsObj.workflows.sort((a, b) => {
        return (
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
        );
      });
      if (workflows.length > 0) {
        if (
          location.pathname.includes("workflows") ||
          location.pathname == "/" ||
          location.pathname == undefined ||
          location.pathname == "/agents"
        ) {
          return redirect(`/agents/${workflows[0].uuid}`);
        }
      }
      return redirect("/agents/new");
    }
  } else {
    return redirect("/login");
  }
}

import { Button } from "@/components/design-system/button";
import Logo from "@/components/styled/Logo";

const MobileLandingPage = () => {
  return (
    <div className="w-screen h-screen flex flex-col gap-4 justify-center items-center p-12">
      <Logo className="h-12" />
      <p className="text-black dark:text-white">
        TailorTask works best on larger screens. To continue, open this page on
        your desktop
      </p>
      <Button
        variant="secondary"
        onClick={() => window.open("https://app.tailortask.com", "_blank")}
      >
        {`Visit TailorTask's homepage`}
      </Button>
    </div>
  );
};

export default MobileLandingPage;

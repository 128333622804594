import NavigationBarUnlogged from "@/components/NavigationBarPublic";
import MarkdownRenderer from "@/components/styled/MarkdownRenderer";
import useBodyClasses from "@/components/useBodyClasses";
import MarconipyApi from "@/utils/marconipyApi";
import { ArtefactDocument } from "@/utils/types";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLoaderData } from "react-router-dom";

type ShareableArtefact = {
  uuid: string;
  content: ArtefactDocument[];
  title: string;
  team_name: string;
  workflow_name: string;
};

const ShareView: React.FC = () => {
  const data = useLoaderData() as { result: ShareableArtefact };
  const shareableResult = data.result;
  const [result, setResult] = useState<ArtefactDocument>("");
  useBodyClasses(["overflow-hidden", "h-full"]);

  useEffect(() => {
    for (const key of Object.keys(shareableResult.content)) {
      if (key === "result") {
        const content =
          shareableResult.content[key as keyof typeof shareableResult.content];
        setResult(content as ArtefactDocument);
        return;
      }
    }
  }, [shareableResult]);
  return (
    <>
      <Helmet>
        <title>{shareableResult.title} - TailorTask</title>
        <meta
          name="description"
          content={
            typeof result == "string"
              ? result.substring(0, 100)
              : result.document.substring(0, 100)
          }
        />
        <meta
          property="og:title"
          content={`${shareableResult.title} - TailorTask`}
        />
        <meta
          property="og:description"
          content={
            typeof result == "string"
              ? result.substring(0, 100)
              : result.document.substring(0, 100)
          }
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content="https://app.tailortask.ai/og_image.png"
        />
        <meta property="og:locale" content="en-US" />
      </Helmet>
      <div className="h-full w-full">
        <NavigationBarUnlogged variant="made-with" />
        <div className="flex justify-center">
          <div className="m-16 max-w-[800px]">
            <div className="flex flex-col mb-8">
              <h1 className="text-3xl font-semibold py-4">
                {shareableResult.title}
              </h1>
              <p className="text-gray-500">
                Created by {shareableResult.workflow_name} for{" "}
                {shareableResult.team_name}
              </p>
            </div>
            {typeof result == "string" ? (
              <MarkdownRenderer text={result} />
            ) : (
              <MarkdownRenderer text={result.document} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareView;

export async function loader({ params }: { params: any }) {
  const uuid = params.workflowrun_uuid.toString();
  if (!uuid) {
    throw new Error("No workflowrun_uuid provided");
  }
  //grab the workflow uuid from the URL: it formatted like /workflow/{workflow_uuid}
  const result = (await MarconipyApi.getShareableResult(uuid)) as any as {
    result: ShareableArtefact;
  };
  return result;
}

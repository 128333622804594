import { Button } from "@/components/design-system/button";
import { Input } from "@/components/design-system/input";
import Tooltip from "@/components/styled/Tooltip";
import MarconipyApi from "@/utils/marconipyApi";
import { usePostHog } from "posthog-js/react";
import { useRef, useState } from "react";
import { RiQuestionFill, RiUpload2Line } from "react-icons/ri";
import { Workflow } from "utils/types";

type SourceSetsCSVUploadProps = {
  workflow: Workflow;
  tool_key: string;
  onRefresh?: () => void;
};

const SourceSetsCSVUpload = ({
  workflow,
  tool_key,
  onRefresh,
}: SourceSetsCSVUploadProps) => {
  const posthog = usePostHog();
  useState<boolean>(false);
  const [showMosaicView, setShowMosaicView] = useState<boolean>(false);
  useState<boolean>(false);
  // const { showHost } = useOnboardingContext();

  const mosaicRefresh = async function () {
    setShowMosaicView(false);
    onRefresh && onRefresh();
    //wait for the sourcesets to be loaded
    await new Promise((resolve) => setTimeout(resolve, 100));
    setShowMosaicView(true);
  };
  const [uploadingCSV, setUploadingCSV] = useState(false);
  const [uploadedCSV, setUploadedCSV] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleCSVUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setUploadingCSV(true);
    const formData = new FormData();
    formData.append("csv_file", file);
    formData.append("workflow_uuid", workflow.uuid);

    try {
      await MarconipyApi.uploadSourcesetsCSV(workflow.uuid, tool_key, formData);
      posthog.capture("sourceset mosaic: CSV upload successful");
      onRefresh && onRefresh();
    } catch (error) {
      console.error("Error uploading CSV:", error);
      posthog.capture("sourceset mosaic: CSV upload failed", { error });
    } finally {
      setUploadingCSV(false);
      setUploadedCSV(true);
      mosaicRefresh();
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className="mt-2">
      <div className="flex items-center justify-between mb-2">
        <p className="opacity-100">
          Upload a CSV containing a list of URLs. Just put a URL for each line.
        </p>
        <Tooltip content="Go to the documentation about CSV uploading">
          <Button
            variant="secondary"
            onClick={() =>
              window.open(
                "https://tailortask.notion.site/dd761937d0aa4d68817f34acbe283f42?v=6053a8590d7a425f8e7ff3096f39e516&pvs=4",
              )
            }
          >
            <RiQuestionFill />
          </Button>
        </Tooltip>
      </div>
      <Button
        variant="primary"
        onClick={triggerFileInput}
        disabled={uploadingCSV || uploadedCSV}
      >
        <RiUpload2Line />
        {uploadedCSV
          ? "CSV Uploaded"
          : uploadingCSV
            ? "Uploading..."
            : "Upload CSV"}
      </Button>
      <Input
        type="file"
        ref={fileInputRef}
        onChange={handleCSVUpload}
        accept=".csv"
        style={{ display: "none" }}
      />
      {/* {showMosaicView && (
        <>
          <p className="uppercase text-xs mt-6 mb-4">Added so far:</p>
          <SourceSetMosaic
            workflow={workflow}
            onRefresh={mosaicRefresh}
            refreshWhen={showMosaicView}
            tool_key={tool_key}
          />
        </>
      )} */}
    </div>
  );
};

export default SourceSetsCSVUpload;

import React, { createContext, ReactNode, useContext, useState } from 'react';
import MainPanel from './MainPanel';
import SecondaryPanel from './SecondaryPanel';

interface PanelContextType {
  openMainPanel: (content: ReactNode, title?: string) => void;
  closeMainPanel: () => void;
  openSecondaryPanel: (content: ReactNode, title: string) => void;
  closeSecondaryPanel: () => void;
}

const PanelContext = createContext<PanelContextType | undefined>(undefined);

export const PanelProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [mainPanelState, setMainPanelState] = useState({
    isOpen: false,
    content: null as ReactNode | null,
    title: undefined as string | undefined,
  });
  const [secondaryPanelState, setSecondaryPanelState] = useState({
    isOpen: false,
    content: null as ReactNode | null,
    title: '',
  });

  const openMainPanel = (newContent: ReactNode, newTitle?: string) => {
    setMainPanelState({ isOpen: true, content: newContent, title: newTitle });
  };

  const closeMainPanel = () => {
    setMainPanelState(prev => ({ ...prev, isOpen: false }));
    setSecondaryPanelState(prev => ({ ...prev, isOpen: false })); // Also close secondary panel
  };

  const openSecondaryPanel = (newContent: ReactNode, newTitle: string) => {
    setSecondaryPanelState({ isOpen: true, content: newContent, title: newTitle });
  };

  const closeSecondaryPanel = () => {
    setSecondaryPanelState(prev => ({ ...prev, isOpen: false }));
  };

  return (
    <PanelContext.Provider value={{ openMainPanel, closeMainPanel, openSecondaryPanel, closeSecondaryPanel }}>
      {children}
      <MainPanel 
        open={mainPanelState.isOpen} 
        onClose={closeMainPanel} 
        title={mainPanelState.title}
      >
        {mainPanelState.content}
      </MainPanel>
      <SecondaryPanel
        open={secondaryPanelState.isOpen}
        onClose={closeSecondaryPanel}
        title={secondaryPanelState.title}
      >
        {secondaryPanelState.content}
      </SecondaryPanel>
    </PanelContext.Provider>
  );
};

export const usePanel = () => {
  const context = useContext(PanelContext);
  if (context === undefined) {
    throw new Error('usePanel must be used within a PanelProvider');
  }
  return context;
};
import ConfirmationButton from "@/components/styled/ConfirmationButton";
import MarconipyApi from "@/utils/marconipyApi";
import { Tool, Workflow } from "@/utils/types";
import React, { useCallback, useEffect, useState } from "react";
import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiCheckboxBlankLine,
  RiCheckboxLine,
  RiCheckFill,
  RiSettings2Fill,
  RiToolsLine,
} from "react-icons/ri";
import SourceSetsPartialView from "./SourceSetsPartialView";

import { Button } from "@/components/design-system/button";
import { Divider } from "@/components/design-system/divider";
import { Heading } from "@/components/design-system/heading";
import MarkdownRenderer from "@/components/styled/MarkdownRenderer";
import MainPanel from "@/components/styled/panels/MainPanel";
import Tooltip from "@/components/styled/Tooltip";
import { useWorkflows } from "@/contexts/WorkflowsContext";
import { NameConventions } from "@/utils/NameConventions";
import { isEqual } from "lodash";
import SpreadsheetPartialView from "./SpreadSheetPartialView";

type ToolsPartialViewProps = {
  isOnboarding: boolean;
  workflow: Workflow;
  onWorkflowRefresh?: () => void;
  onToolsConfirmed?: (step: string) => void;
};

const ToolsPartialView = ({
  workflow,
  onWorkflowRefresh,
  onToolsConfirmed,
}: ToolsPartialViewProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [tools, setTools] = useState<Tool[]>([]);
  const [selectedTools, setSelectedTools] = useState<string[]>(
    workflow.selected_tools_for_run || [],
  );
  const [selectedToolDialogOpenKey, setSelectedToolDialogOpenKey] = useState<
    string | null
  >(null);
  const [toolSetupDialogOpen, setToolSetupDialogOpen] =
    useState<boolean>(false);
  const [toolsThatNeedSetup, setToolsThatNeedSetup] = useState<string[]>([]);
  const [allToolsRequiringUserInputs, setAllToolsRequiringUserInputs] =
    useState<string[]>([]);
  const [currentToolIndex, setCurrentToolIndex] = useState<number>(0);
  const { updateCurrentWorkflow } = useWorkflows();
  const selectedToolsRef = React.useRef<string[]>();
  const artefactInputsRef = React.useRef<any>({});

  const handleConfirmTools = async () => {
    onToolsConfirmed && onToolsConfirmed("tools");
  };

  useEffect(() => {
    const loadTools = async () => {
      const ftools = (await MarconipyApi.getTools(workflow.uuid)) as any as {
        tools: Tool[];
      };
      setTools(ftools.tools);
      setIsLoading(false);
      //validate tools
      let localInvalidTools: string[] = [];
      let allToolsRequiringUserInputs: string[] = [];
      ftools.tools.forEach((tool) => {
        if (workflow.selected_tools_for_run.includes(tool.key)) {
          if (!tool.inputs_are_valid) {
            localInvalidTools.push(tool.key);
          }
          if (Object.entries(tool.user_inputs).length > 0) {
            allToolsRequiringUserInputs.push(tool.key);
          }
        }
      });
      setAllToolsRequiringUserInputs(allToolsRequiringUserInputs);
      setToolsThatNeedSetup(localInvalidTools);
      setSelectedToolDialogOpenKey((prev) =>
        ftools.tools.filter((t) => t.key === prev).length > 0
          ? ftools.tools.filter((t) => t.key === prev)[0].key
          : null,
      );
    };
    if (
      tools.length == 0 ||
      !isEqual(selectedToolsRef.current, workflow.selected_tools_for_run) ||
      (workflow.artefact &&
        workflow.artefact.inputs &&
        !isEqual(artefactInputsRef.current, workflow.artefact.inputs))
    ) {
      loadTools();
      selectedToolsRef.current = workflow.selected_tools_for_run;
      if (workflow.artefact && workflow.artefact.inputs) {
        artefactInputsRef.current = workflow.artefact.inputs;
      }
    }
  }, [
    workflow.uuid,
    workflow.selected_tools_for_run,
    tools,
    workflow.artefact,
  ]);

  const handleRefresh = useCallback(async () => {
    await onWorkflowRefresh?.();
  }, [onWorkflowRefresh]);

  const handleToolSelection = async (toolKey: string) => {
    const updatedSelectedTools = selectedTools.includes(toolKey)
      ? selectedTools.filter((key) => key !== toolKey)
      : [...selectedTools, toolKey];

    setSelectedTools(updatedSelectedTools);

    try {
      await updateCurrentWorkflow(workflow, {
        selected_tools_for_run: updatedSelectedTools,
      });
    } catch (error) {
      console.error("Failed to update selected tools:", error);
      // Revert the selection if the API call fails
      setSelectedTools(selectedTools);
    }
  };

  const openSetUpModalAtTool = (tool: Tool | undefined = undefined) => {
    if (tool) {
      setSelectedToolDialogOpenKey(tool.key);
    } else {
      let firstTool = tools.find((tool) => !tool.inputs_are_valid);
      if (!firstTool) {
        console.error("No tools that need setup found");
        return;
      }
      setSelectedToolDialogOpenKey(firstTool.key);
    }
    setToolSetupDialogOpen(true);
  };
  const navigateTools = (direction: "next" | "prev") => {
    let newIndex = 0;
    if (direction === "next") {
      if (currentToolIndex < allToolsRequiringUserInputs.length - 1) {
        newIndex = currentToolIndex + 1;
      }
    } else {
      if (currentToolIndex > 0) {
        newIndex = currentToolIndex - 1;
      }
    }
    setCurrentToolIndex(newIndex);
    setSelectedToolDialogOpenKey(allToolsRequiringUserInputs[newIndex]);
  };

  const currentTool = tools.find((t) => t.key === selectedToolDialogOpenKey);

  return (
    <div className="h-full">
      {isLoading ? (
        <p>Loading actions...</p>
      ) : (
        <div className="flex flex-col gap-4 overflow-y-scroll max-h-72">
          {tools.map((tool) => (
            <div
              key={tool.key}
              className={`p-4 border rounded-lg transition-colors ${
                selectedTools.includes(tool.key)
                  ? "border-gray-300"
                  : "bg-white dark:bg-black-dark border-gray-300 hover:bg-white hover:dark:bg-black"
              }`}
            >
              <div className="flex items-start gap-4">
                <div
                  className="flex-shrink-0 mt-0.5 hover:cursor-pointer hover:opacity-70"
                  onClick={() => handleToolSelection(tool.key)}
                >
                  {selectedTools.includes(tool.key) ? (
                    <RiCheckboxLine className="w-5 h-5 text-blue" />
                  ) : (
                    <RiCheckboxBlankLine className="w-5 h-5 text-black dark:text-white" />
                  )}
                </div>
                <div className="flex flex-col gap-2">
                  <div
                    className="flex hover:cursor-pointer"
                    onClick={() => handleToolSelection(tool.key)}
                  >
                    <h3 className="font-semibold">{tool.name}</h3>
                  </div>
                  <p className="text-sm text-gray-600 dark:text-gray-100 mt-1 mb-2">
                    {tool.public_description}
                  </p>
                  {Object.entries(tool.user_inputs).length > 0 &&
                    selectedTools.includes(tool.key) && (
                      <div className="flex gap-2">
                        <Button
                          variant="secondary"
                          onClick={() => openSetUpModalAtTool(tool)}
                          size="sm"
                          disabled={!selectedTools.includes(tool.key)}
                        >
                          {tool.inputs_are_valid ? (
                            <RiCheckFill />
                          ) : (
                            <RiSettings2Fill />
                          )}
                          {tool.inputs_are_valid
                            ? "Set up completed"
                            : "Set up"}
                        </Button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <br />
      <Divider />
      <MainPanel
        open={!isLoading && toolSetupDialogOpen}
        onClose={() => setToolSetupDialogOpen(false)}
      >
        {currentTool && (
          <div className="flex flex-col gap-4 p-4">
            <Heading>
              Actions setup - {currentTool ? currentTool.name : ""}
            </Heading>
            <Divider />
            <div className="list-disc list-inside text-xs dark:text-gray-100 flex flex-col gap-4">
              {Object.entries(currentTool.user_inputs).map(
                ([inputName, inputSchema]) => (
                  <React.Fragment key={inputName}>
                    <div>
                      {workflow.metadata.template_instructions &&
                      Object.keys(
                        workflow.metadata.template_instructions,
                      ).includes(currentTool.key) ? (
                        <>
                          {/* <Divider className="my-4" /> */}
                          <MarkdownRenderer
                            text={
                              workflow.metadata.template_instructions[
                                currentTool.key
                              ]
                            }
                          />
                        </>
                      ) : (
                        <>
                          {NameConventions.toHuman(inputName)}:{" "}
                          {inputSchema.description}
                          {inputSchema.required && (
                            <span className="text-red-500 ml-1">*</span>
                          )}
                        </>
                      )}
                    </div>
                    {inputName === "sourceset" && (
                      <>
                        <SourceSetsPartialView
                          key={currentTool.key}
                          workflow={workflow}
                          tool_key={currentTool.key}
                          onRefresh={handleRefresh}
                        />
                      </>
                    )}
                    {inputName === "spreadsheet" && (
                      <>
                        <SpreadsheetPartialView
                          key={currentTool.key}
                          workflow={workflow}
                          tool_key={currentTool.key}
                          onRefresh={handleRefresh}
                        />
                      </>
                    )}
                  </React.Fragment>
                ),
              )}
            </div>
            <Divider className="mt-4" />
            <div className="flex justify-between mt-4">
              {currentToolIndex > 0 && (
                <Button
                  variant="secondary"
                  onClick={() => navigateTools("prev")}
                >
                  <RiArrowLeftLine /> Previous
                </Button>
              )}
              {allToolsRequiringUserInputs.length > 1 && (
                <div className="w-full">
                  <Tooltip
                    content={`There are ${toolsThatNeedSetup.length} actions that need setup`}
                  >
                    <p className="text-xs mt-2 text-gray-500 text-center">
                      {currentToolIndex + 1} /{" "}
                      {allToolsRequiringUserInputs.length}
                    </p>
                  </Tooltip>
                </div>
              )}
              {currentToolIndex < allToolsRequiringUserInputs.length - 1 ? (
                <Button
                  variant="secondary"
                  onClick={() => navigateTools("next")}
                  className="ml-auto"
                  disabled={!currentTool.inputs_are_valid}
                >
                  Next <RiArrowRightLine />
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={() => setToolSetupDialogOpen(false)}
                  className="ml-auto"
                  disabled={!currentTool.inputs_are_valid}
                >
                  <RiCheckFill />
                  Save
                </Button>
              )}
            </div>
          </div>
        )}
      </MainPanel>
      <br />
      {toolsThatNeedSetup.length == 0 && (
        <ConfirmationButton
          workflow={workflow}
          tooltip={
            toolsThatNeedSetup.length == 0
              ? "Confirm the actions you have selected to proceed"
              : "Some of the actions have not been configured correctly."
          }
          tooltip_disabled="Complete the actions setup to continue"
          type="tools"
          onClick={handleConfirmTools}
          disabled={isLoading}
        />
      )}
      {toolsThatNeedSetup.length > 0 && (
        <Button
          variant="primary"
          onClick={() => openSetUpModalAtTool()}
          disabled={isLoading}
        >
          <RiToolsLine />
          Set up Actions
        </Button>
      )}
    </div>
  );
};

export default ToolsPartialView;

import { Button } from "@/components/design-system/button";
import { useEventContext } from "@/contexts/EventsContext";
import { useTeams } from "@/contexts/TeamContext";
import { useWorkflows } from "@/contexts/WorkflowsContext";
import MarconipyApi from "@/utils/marconipyApi";
import {
  SelectOption,
  Team,
  Workflow,
  WorkflowNavigationBarOptions,
} from "@/utils/types";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import Cookies from "js-cookie";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import {
  RiAddLargeLine,
  RiArrowLeftFill,
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleLine,
  RiDeleteBin2Line,
  RiEditLine,
  RiEyeFill,
  RiFileCopyLine,
  RiFileFill,
  RiFolderTransferFill,
  RiMenuFill,
} from "react-icons/ri";
import { toast } from "react-toastify";
import {
  Alert,
  AlertActions,
  AlertDescription,
  AlertTitle,
} from "./design-system/alert";
import { Badge } from "./design-system/badge";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "./design-system/dialog";
import { Divider } from "./design-system/divider";
import {
  Dropdown,
  DropdownButton,
  DropdownMenu,
} from "./design-system/dropdown";
import {
  FloatingMenu,
  FloatingSubMenu,
  FloatingSubMenuItem,
} from "./design-system/floating-menu";
import { Input } from "./design-system/input";
import { Navbar, NavbarDivider, NavbarSpacer } from "./design-system/navbar";
import { Select } from "./design-system/select";
import { StackedLayout } from "./design-system/stacked-layout";
import { Text } from "./design-system/text";
import AnimatedText from "./styled/AnimatedText";
import Logo from "./styled/Logo";
import Tooltip from "./styled/Tooltip";
import WorkflowIcon from "./styled/WorkflowIcon";

const ADVANCED_MODE_COOKIE = "tailortask_advanced_mode";
type WorkflowNavigationBarProps = {
  children: React.ReactNode;
  workflow: Workflow;
  activeSidePanelTab: WorkflowNavigationBarOptions;
  switchSidePanelTab: (panel: WorkflowNavigationBarOptions) => void;
  newAnimatedName?: string;
};
export function WorkflowNavigationBar({
  children,
  workflow,
  activeSidePanelTab: activeSidePanelTab,
  switchSidePanelTab: switchSidePanel,
  newAnimatedName,
}: WorkflowNavigationBarProps) {
  const { selectedTeam } = useTeams();
  const [isEditing, setIsEditing] = useState(false);
  const [workflowName, setWorkflowName] = useState(workflow.name);
  const { emit } = useEventContext(); // Use the event context
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  const posthog = usePostHog();
  //move workflow variables
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [teamOptions, setTeamOptions] = useState<SelectOption[]>([]);
  const [selectedTeamUUID, setSelectedTeamUUID] = useState<string>("default");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const navigate = useNavigate();
  const { updateCurrentWorkflow, deleteWorkflow, cloneWorkflow } =
    useWorkflows();

  const editMode = location.search.includes("edit=true");
  useEffect(() => {
    const savedMode = Cookies.get(ADVANCED_MODE_COOKIE);
    if (savedMode !== undefined) {
      const isAdvanced = savedMode == "advanced";
      setIsAdvancedMode(isAdvanced);
    }
    const savedTab = Cookies.get("tt_tab_" + workflow.uuid);
    if (savedTab !== undefined && editMode) {
      switchSidePanel(savedTab as WorkflowNavigationBarOptions);
    }
  }, [editMode, switchSidePanel, workflow.uuid]);

  useEffect(() => {
    Cookies.set("tt_tab_" + workflow.uuid, activeSidePanelTab);
  }, [activeSidePanelTab, workflow.uuid]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWorkflowName(e.target.value);
  };

  const handleNameSubmit = async () => {
    posthog.capture("workflowEdit: edit workflow name", {
      source: "workflow navigation bar",
    });
    setIsEditing(false);
    await updateCurrentWorkflow(workflow, { name: workflowName });
  };

  const handleNewWorkflow = async () => {
    // navigate("/agents/new");
    window.location.href = "/agents/new";
  };
  const handleModeToggle = (newMode: boolean) => {
    posthog.capture("view mode toggle", { to: newMode ? "advanced" : "basic" });
    setIsAdvancedMode(newMode);
    Cookies.set(ADVANCED_MODE_COOKIE, newMode ? "advanced" : "basic");
    emit({ type: "VIEW_MODE_CHANGED", payload: { advanced: newMode } });
    window.location.reload();
  };

  const handleMoveWorkflow = async () => {
    posthog.capture("workflowEdit: move workflow", {
      source: "workflow navigation bar",
    });
    const fteams = (await MarconipyApi.getTeams()) as any as { teams: Team[] };
    const filteredTeams = fteams.teams.map((team) => ({
      label:
        team.uuid == selectedTeam?.uuid ? team.name + " (current)" : team.name,
      value: team.uuid,
    }));
    setTeamOptions(filteredTeams);
    setSelectedTeamUUID(filteredTeams[0].value);
    setShowMoveModal(true);
  };
  const handleMoveSave = async () => {
    posthog.capture("workflowEdit: save move workflow", {
      source: "workflow navigation bar",
    });
    const new_team_uuid = selectedTeamUUID;
    if (new_team_uuid == "default") {
      toast.error("Please select a team");
      return;
    }
    const newteam = teamOptions.find((team) => team.value == new_team_uuid);
    if (!newteam) {
      toast.error("Invalid team");
      return;
    }
    toast.success("Moved to " + newteam.label + ", reloading...");
    setShowMoveModal(false);
    await updateCurrentWorkflow(workflow, {
      team_uuid: new_team_uuid,
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };
  const handleDeleteWorkflow = async () => {
    deleteWorkflow(workflow);
  };
  const handleCloneWorkflow = async () => {
    cloneWorkflow(workflow);
  };

  const handleEmojiClick = (e: any) => {
    const emoji = e.native;
    posthog.capture("workflowEdit: edit workflow icon", {
      source: "workflow navigation bar",
    });
    updateCurrentWorkflow(workflow, { icon: emoji });
  };

  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (newAnimatedName) {
      setIsAnimating(true);
    }
  }, [newAnimatedName]);

  const handleAnimationComplete = () => {
    setIsAnimating(false);
  };

  return (
    <StackedLayout
      navbar={
        <Navbar className="px-2">
          {editMode && (
            <Logo
              variant="svg"
              onClick={() => (window.location.href = "/")}
              className="ml-4 lg:m-0 w-6 h-6 cursor-pointer hover:animate-spin"
            />
          )}
          {editMode && (
            <FloatingMenu content={<RiMenuFill />}>
              <FloatingSubMenu
                content={
                  <>
                    <RiFileFill />
                    File
                  </>
                }
              >
                <FloatingSubMenuItem onClick={handleNewWorkflow}>
                  <RiAddLargeLine />
                  New Agent
                </FloatingSubMenuItem>
                <Divider />
                <FloatingSubMenuItem onClick={handleCloneWorkflow}>
                  <RiFileCopyLine />
                  Clone Agent
                </FloatingSubMenuItem>
                <FloatingSubMenuItem onClick={handleMoveWorkflow}>
                  <RiFolderTransferFill />
                  Move to Team
                </FloatingSubMenuItem>
                <FloatingSubMenuItem onClick={() => setIsDeleteModalOpen(true)}>
                  <RiDeleteBin2Line />
                  Delete Agent
                </FloatingSubMenuItem>
              </FloatingSubMenu>
              <FloatingSubMenu
                content={
                  <>
                    <RiEyeFill />
                    View
                  </>
                }
              >
                <FloatingSubMenuItem onClick={() => handleModeToggle(false)}>
                  {isAdvancedMode ? (
                    <RiCheckboxBlankCircleLine className="menuicon" />
                  ) : (
                    <RiCheckboxCircleLine className="menuicon" />
                  )}
                  Basic View
                </FloatingSubMenuItem>
                <FloatingSubMenuItem onClick={() => handleModeToggle(true)}>
                  {isAdvancedMode ? (
                    <RiCheckboxCircleLine className="menuicon" />
                  ) : (
                    <RiCheckboxBlankCircleLine className="menuicon" />
                  )}
                  Advanced View
                </FloatingSubMenuItem>
              </FloatingSubMenu>
              <FloatingSubMenuItem
                onClick={() => {
                  // navigate(`/agents/${workflow.uuid}`);
                  window.location.href = `/agents/${workflow.uuid}`;
                }}
              >
                <RiArrowLeftFill />
                Back to Agent list
              </FloatingSubMenuItem>
            </FloatingMenu>
          )}
          {editMode && <NavbarDivider className="max-lg:hidden" />}
          {isEditing ? (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleNameSubmit();
              }}
              className="flex items-center"
            >
              <Input
                type="text"
                value={workflowName}
                onChange={handleNameChange}
                autoFocus
              />
              <Button variant="secondary" type="submit" className="ml-2">
                Save
              </Button>
            </form>
          ) : (
            <>
              {editMode && (
                <Dropdown>
                  <DropdownButton variant="basic">
                    <WorkflowIcon
                      icon={
                        workflow.icon != ""
                          ? workflow.icon
                          : workflow.name.split("")[0]
                      }
                    />
                  </DropdownButton>
                  <DropdownMenu grid={false} padding={false}>
                    <Picker
                      data={data}
                      onEmojiSelect={handleEmojiClick}
                      className=""
                    />
                  </DropdownMenu>
                </Dropdown>
              )}
              {!editMode && (
                <WorkflowIcon
                  icon={
                    workflow.icon != ""
                      ? workflow.icon
                      : workflow.name.split("")[0]
                  }
                />
              )}
              {editMode && (
                <Button
                  variant="basic"
                  className="group"
                  onClick={() => setIsEditing(true)}
                >
                  {isAnimating ? (
                    <AnimatedText
                      text={newAnimatedName || workflowName}
                      onAnimationComplete={handleAnimationComplete}
                    />
                  ) : (
                    <span>{newAnimatedName || workflowName}</span>
                  )}
                  {` `}
                  {workflow.deleted && (
                    <span className="text-red-500">[Deleted]</span>
                  )}
                  {editMode && (
                    <RiEditLine className="opacity-0 group-hover:opacity-100" />
                  )}
                </Button>
              )}
              {!editMode && (
                <Text className="ml-2 font-semibold text-zinc-900 dark:text-white">
                  {isAnimating ? (
                    <AnimatedText
                      text={newAnimatedName || workflowName}
                      onAnimationComplete={handleAnimationComplete}
                    />
                  ) : (
                    <span>{newAnimatedName || workflowName}</span>
                  )}
                  {` `}
                  {workflow.deleted && (
                    <span className="text-red-500">[Deleted]</span>
                  )}
                </Text>
              )}
            </>
          )}
          {editMode && (
            <Badge className="hover:cursor-default">
              <Tooltip content="Saved automatically">Editing</Tooltip>
            </Badge>
          )}
          <NavbarSpacer />
          {!editMode && (
            <Button
              variant="secondary"
              onClick={() => {
                // navigate(`/agents/${workflow.uuid}?edit=true`);
                window.location.href = `/agents/${workflow.uuid}?edit=true`;
              }}
            >
              <RiEditLine />
              Edit agent
            </Button>
          )}
        </Navbar>
      }
      sidebar={null}
    >
      {children}
      <Dialog open={showMoveModal} onClose={() => setShowMoveModal(false)}>
        <DialogTitle>Move Workflow</DialogTitle>
        <DialogDescription>Move workflow to another team</DialogDescription>
        <DialogBody>
          <Select
            name="type"
            onChange={(e) => setSelectedTeamUUID(e.target.value)}
          >
            {teamOptions.map((option) => (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </DialogBody>
        <DialogActions>
          <Button variant="secondary" onClick={() => setShowMoveModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleMoveSave}
            disabled={!selectedTeamUUID || selectedTeamUUID == "default"}
          >
            <RiFolderTransferFill />
            Move
          </Button>
        </DialogActions>
      </Dialog>
      <Alert
        open={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <AlertTitle>Confirm Agent Deletion</AlertTitle>
        <AlertDescription>
          Are you sure you want to delete this agent? This action cannot be
          undone.
        </AlertDescription>
        <AlertActions>
          <Button
            variant="secondary"
            onClick={() => {
              setIsDeleteModalOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteWorkflow}>
            Delete Agent
          </Button>
        </AlertActions>
      </Alert>
    </StackedLayout>
  );
}

import { useAuth } from "@/contexts/AuthenticationContext";
import MarconipyApi from "@/utils/marconipyApi";
import { useEffect } from "react";
import { redirect } from "react-router";

export default function Logout() {
  const { updateAuth } = useAuth();

  useEffect(() => {
    const logout = async () => {
      await MarconipyApi.logout();
      await updateAuth();
      localStorage.removeItem("u_uuid");
      localStorage.removeItem("redirectUrl");
      window.location.reload();
    };
    logout();
  }, [updateAuth]);

  return (
    <div className="col-span-12 flex w-full justify-center my-8">
      <h2 className="mx-auto">Logging you out...</h2>
    </div>
  );
}

export async function loader({ params }: { params: any }) {
  //check if the user is already logged in
  const loggedIn = MarconipyApi.isAuth();
  if (!loggedIn) {
    console.log("User is already logged out");
    return redirect("/");
  } else {
    return false;
  }
}

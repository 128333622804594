// import { Task, Message } from "@/utils/types";
import Tooltip from "@/components/styled/Tooltip";
import HelpPanel from "@/screens/partials/HelpPanel";
import { WorkflowNavigationBarOptions } from "@/utils/types";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import React from "react";
import {
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiCheckLine,
  RiCloseLine,
  RiEqualizerFill,
  RiPlayLine,
  RiQuestionLine,
  RiSettingsLine,
} from "react-icons/ri";
import { Navbar, NavbarItem, NavbarSpacer } from "./design-system/navbar";
import { StackedLayout } from "./design-system/stacked-layout";
import WorkflowRunsPartialView from "./WorkflowRunsPartialView";

type Props = {
  workflow_uuid: string;
  sidePanelActiveTab: string;
  activeStep: string | null;
  toggleStep: (id: string, automatic?: boolean) => void;
  steps: any[];
  switchSidePanelTab: (tab: WorkflowNavigationBarOptions) => void;
};

const WorkflowViewSidebar = ({
  workflow_uuid,
  sidePanelActiveTab,
  activeStep,
  toggleStep,
  steps,
  switchSidePanelTab,
}: Props) => {
  return (
    <React.Fragment>
      <div
        className={`h-full bg-white mr-2 flex inset-y-0 right-0 transform transition-all duration-300 ease-in-out overflow-hidden ring-1 ring-zinc-950/5 dark:bg-zinc-900 dark:ring-white/10 ${
          sidePanelActiveTab === "none"
            ? "translate-x-full w-0 hidden"
            : "translate-x-0 w-1/2 lg:w-1/3"
        }`}
      >
        <StackedLayout
          navbar={
            <Navbar>
              <NavbarItem
                aria-label="Set up"
                onClick={() => switchSidePanelTab("settings")}
                current={sidePanelActiveTab == "settings"}
              >
                <RiEqualizerFill />
                Set up
              </NavbarItem>
              <Tooltip content="Runs">
                <NavbarItem
                  aria-label="Runs"
                  onClick={() => switchSidePanelTab("runs")}
                  current={sidePanelActiveTab == "runs"}
                >
                  <RiPlayLine />
                  Runs
                </NavbarItem>
              </Tooltip>
              <Tooltip content="Help">
                <NavbarItem
                  aria-label="Help"
                  onClick={() => switchSidePanelTab("help")}
                  current={sidePanelActiveTab == "help"}
                >
                  <RiQuestionLine />
                  Help
                </NavbarItem>
              </Tooltip>
              <NavbarSpacer />
              <Tooltip content="Close sidebar">
                <NavbarItem
                  aria-label="Close sidebar"
                  onClick={() => switchSidePanelTab("none")}
                >
                  <RiCloseLine />
                </NavbarItem>
              </Tooltip>
            </Navbar>
          }
          sidebar={null}
        >
          <div className="overflow-y-hidden h-screen w-full">
            <Transition
              show={sidePanelActiveTab == "settings"}
              enter="transition-all duration-100 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-all duration-100 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="divide-y divide-gray-100 color dark:divide-black-light h-screen overflow-hidden flex flex-col">
                {steps.map((step, index) => (
                  <div
                    key={step.id}
                    className="ring-1 ring-zinc-950/5 dark:ring-white/10 flex flex-col"
                  >
                    <Tooltip
                      content={step.tooltip}
                      key={step.id}
                      placement="left"
                    >
                      <button
                        className={clsx(
                          "max-h-14",
                          activeStep == step.id
                            ? "bg-white dark:bg-black-dark w-full px-4 py-3 flex items-center justify-between text-left focus:outline-none hover:bg-gray-100 hover:dark:bg-black-light"
                            : "w-full px-4 py-3 flex items-center justify-between text-left focus:outline-none hover:bg-gray-100 hover:dark:bg-black-light",
                        )}
                        onClick={() => toggleStep(step.id)}
                      >
                        <div className="flex items-center">
                          <div
                            className={`w-8 h-8 rounded-full flex shrink-0 items-center justify-center mr-3 ${
                              step.isCompleted
                                ? "bg-green text-white"
                                : "bg-gray-100 text-black dark:bg-black-dark dark:text-white"
                            }`}
                          >
                            {step.isCompleted ? (
                              <RiCheckLine className="w-5 h-5" />
                            ) : (
                              step.icon
                            )}
                          </div>
                          <span className="text-sm font-medium text-black dark:text-white">{`${index + 1}. ${step.title}`}</span>
                        </div>
                        {activeStep === step.id ? (
                          <RiArrowUpSLine className="w-5 h-5 text-black" />
                        ) : (
                          <RiArrowDownSLine className="w-5 h-5 text-black" />
                        )}
                      </button>
                    </Tooltip>
                    <Transition
                      show={activeStep === step.id}
                      enter="transition-all duration-300 ease-out"
                      enterFrom="opacity-0 max-h-0"
                      enterTo="opacity-100 max-h-screen"
                      leave="transition-all duration-200 ease-in"
                      leaveFrom="opacity-100 max-h-screen"
                      leaveTo="opacity-0 max-h-0"
                    >
                      <div
                        className="px-4 py-3 overflow-y-auto"
                        style={{
                          maxHeight: `calc(100vh - ${steps.length * 72}px)`,
                          height: "100%",
                        }}
                      >
                        {step.content}
                      </div>
                    </Transition>
                  </div>
                ))}
              </div>
            </Transition>
            <Transition
              show={sidePanelActiveTab == "runs"}
              enter="transition-all duration-100 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-all duration-100 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="divide-y divide-gray-100 dark:divide-black-light">
                <WorkflowRunsPartialView workflow_uuid={workflow_uuid} />
              </div>
            </Transition>
            <Transition
              show={sidePanelActiveTab == "help"}
              enter="transition-all duration-100 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-all duration-100 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="p-4">
                <HelpPanel />
              </div>
            </Transition>
          </div>
        </StackedLayout>
      </div>
      {sidePanelActiveTab === "none" && (
        <div className="absolute right-0 top-0">
          <button
            onClick={() => switchSidePanelTab("settings")}
            className="h-14 px-5 border"
          >
            <Tooltip content="Open Sidebar">
              <RiSettingsLine />
            </Tooltip>
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default WorkflowViewSidebar;

import { Button } from "@/components/design-system/button";
import { Divider } from "@/components/design-system/divider";
import { Input } from "@/components/design-system/input";
import { Switch } from "@/components/design-system/switch";
import Card from "@/components/styled/Card";
import Label from "@/components/styled/Label";
import SubchapterTitle from "@/components/SubchapterTitle";
import MarconipyApi from "@/utils/marconipyApi";
import { useState } from "react";
import { RiUser3Line } from "react-icons/ri";
import { redirect, useLoaderData } from "react-router-dom";
import tw from "tailwind-styled-components";
import { NotificationSettings, User } from "utils/types";

type userDataResponse = {
  notificationSettings?: NotificationSettings;
  profile?: User;
};

const SettingsRow = tw.div`flex justify-between items-center h-8`;
const SettingsLabel = tw.p`font-sans text-sm`;

export default function AccountView() {
  const usr = useLoaderData() as userDataResponse;

  const [notifyOnMarketing, setNotifyOnMarketing] = useState(
    usr.notificationSettings?.notify_on_marketing || false,
  );
  const [emailNotification, setEmailNotification] = useState(
    usr.notificationSettings?.email_notification || false,
  );
  const handleChangeNotifyOnMarketing = async (value: boolean) => {
    setNotifyOnMarketing(value);
    await MarconipyApi.changeNotificationSettings({
      notify_on_marketing: value,
    });
  };
  const handleEmailNotification = async (value: boolean) => {
    setEmailNotification(value);
    await MarconipyApi.changeNotificationSettings({
      email_notification: value,
    });
  };

  return (
    <div className="overflow-y-auto col-span-12 grid grid-cols-12 px-4 gap-4">
      <div className="col-span-12 sm:col-span-12 md:col-start-3 md:col-span-8 lg:col-start-4 lg:col-span-6 mb-80 mt-4">
        <Card className="flex flex-col gap-4">
          <h1>Settings</h1>
          <SubchapterTitle title="Login details" />
          <div className="flex flex-col gap-2">
            <div>
              <Label>Email address</Label>
              <Input
                readOnly
                value={usr.profile?.email ?? ""}
                type="Email"
                placeholder="name@example.com"
              />
            </div>
            <div>
              <Label>Name</Label>
              <Input
                readOnly
                value={usr.profile?.first_name ?? ""}
                type="text"
                placeholder="First name"
              />
            </div>
          </div>
          <Divider />
          <SubchapterTitle title="Notifications" />
          <div className="flex flex-col gap-4">
            <SettingsRow>
              <SettingsLabel>Send me email notifications</SettingsLabel>
              <Switch
                checked={emailNotification}
                onChange={handleEmailNotification}
              />
            </SettingsRow>
            <SettingsRow>
              <SettingsLabel>
                {`Keep me subscribed to the TailorTask's newsletter`}
              </SettingsLabel>
              <Switch
                checked={notifyOnMarketing}
                onChange={handleChangeNotifyOnMarketing}
              />
            </SettingsRow>
            <Divider />
          </div>
          {/* <SubchapterTitle title="Advanced" /> */}
          <div className="flex flex-col gap-2">
            {/* <Button variant="secondary" href="/onboarding">
              <RiArticleFill />
              Reset onboarding
            </Button> */}
            <Button variant="secondary" href="/logout">
              <RiUser3Line />
              Log out
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
}

export async function loader({ params }: { params: any }) {
  try {
    await MarconipyApi.loadToken();
    let notificationSettings = await MarconipyApi.getNotificationSettings();
    let profile = await MarconipyApi.getUserStatus();
    return {
      notificationSettings: notificationSettings,
      profile: profile,
    };
  } catch (e: any) {
    console.log(e);
    return redirect("/login");
  }
}

import OutputTemplateComponent from "@/components/OutputTemplateComponent";
import MarconipyApi from "@/utils/marconipyApi";
import { OutputTemplate, Workflow } from "@/utils/types";
import { useEffect, useState } from "react";

type OutputTemplatesProps = {
  workflow: Workflow;
};

const OutputTemplates = ({ workflow }: OutputTemplatesProps) => {
  const [templates, setTemplates] = useState<OutputTemplate[]>([]);

  useEffect(() => {
    const loadTemplates = async () => {
      const ntemplates = (await MarconipyApi.outputTemplates(
        workflow.uuid,
      )) as any as { output_templates: OutputTemplate[] };
      console.log(ntemplates);
      setTemplates(ntemplates.output_templates);
    };

    loadTemplates();
  }, [workflow.uuid]);

  return (
    <div className="">
      {templates.map((template) => (
        <OutputTemplateComponent
          key={template.uuid}
          workflow={workflow}
          template_uuid={template.uuid}
        />
      ))}
    </div>
  );
};

export default OutputTemplates;
